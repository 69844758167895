<form class="resetFormForm">
    <div class="formGroupMain">
        <div class="sectionHead">
            <p>Create a new password to keep your account secure. Ensure it meets the requirements below for added protection.</p>
            <!-- <img src="/assets/images/edit-icon.svg" alt=""> -->
        </div>
        <div class="errorCont" *ngIf="resetForm.get('confirmPassword')?.dirty&&resetForm?.errors?.['passwordsMismatch']">
            <img  src="/assets/images/failed.svg" alt="">
            <p> {{'Passwords don’t match. Please ensure New Password and Confirm New Password are same'}}</p>
        </div>
        <div class="errorCont" *ngIf="resetForm.get('oldPassword')?.dirty&&resetForm?.errors?.['passwordsMatches']">
            <img  src="/assets/images/failed.svg" alt="">
            <p> {{'New Password should not be the same as Old Password'}}</p>
        </div>
        <form class="form" [formGroup]="resetForm">
            <div class="formfields">
                <div class="formControl">
                    <label class="formLabel required" for="newPass">
                        Old Password
                    </label>
                    <input id="oldPass" autocomplete="false" class="inputField" [type]="isOldPasswordHidden ? 'password' : 'text'" required formControlName="oldPassword" [class.invalid]="isSubmitted && resetForm.get('oldPassword')?.invalid">
                    <img *ngIf="isOldPasswordHidden" (click)="showHideOldPass()" src="/assets/images/password-show.svg" alt="show-password-icon" title="Show Password" class="passwordIcon">
                    <img *ngIf="!isOldPasswordHidden" (click)="showHideOldPass()" src="/assets/images/password-hide.svg" alt="hide-password-icon" title="Hide Password" class="passwordIcon">
                </div>
                <div class="formControl">
                    <label class="formLabel required" for="newPass">
                        New Password
                    </label>
                    <input id="newPass" autocomplete="false" class="inputField" [type]="isNewPasswordHidden ? 'password' : 'text'" required formControlName="newPassword" [class.invalid]="isSubmitted && resetForm.get('newPassword')?.invalid">
                    <img *ngIf="isNewPasswordHidden" (click)="showHideNewPass()" src="/assets/images/password-show.svg" alt="show-password-icon" title="Show Password" class="passwordIcon">
                    <img *ngIf="!isNewPasswordHidden" (click)="showHideNewPass()" src="/assets/images/password-hide.svg" alt="hide-password-icon" title="Hide Password" class="passwordIcon">
                </div>
                <div class="formControl">
                    <label class="formLabel required" for="conPass">
                        Confirm Password
                    </label>
                    <input id="conPass" autocomplete="false" class="inputField" [type]="isConfirmPasswordHidden ? 'password' : 'text'" required formControlName="confirmPassword" [class.invalid]="isSubmitted && resetForm.get('confirmPassword')?.invalid">
                    <img *ngIf="isConfirmPasswordHidden" (click)="showHideConfirmPass()" src="/assets/images/password-show.svg" alt="show-password-icon" title="Show Password" class="passwordIcon">
                    <img *ngIf="!isConfirmPasswordHidden" (click)="showHideConfirmPass()" src="/assets/images/password-hide.svg" alt="hide-password-icon" title="Hide Password" class="passwordIcon">
                </div>
                <div class="passRequirements">
                    <p>Please make sure that the password contains:</p>
                    <ul>
                        <li>
                            <img *ngIf="!isPasswordValid().hasSpecialCharacter" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasSpecialCharacter" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one special character (e.g., !, &#64;, #, $, %, ^, &, *, etc.).
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().hasCapitalLetter" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasCapitalLetter" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one capital case character and one lower case character
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().hasNumber" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasNumber" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one number
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().isLongEnough" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().isLongEnough" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            should have minimum length of 8 characters
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
    <app-success-toast *ngIf="successMsg&&showSuccessToast" [message]="successMsg" ></app-success-toast>
    <app-error-toast *ngIf="error && showErrorToast" [message]="error" ></app-error-toast>

    <div class="ctaCont">
        <button class="secondary" type="reset" (click)="resetFormData()" [disabled]="isCancelDisabled">
            Cancel
        </button>
        <button class="primary" type="submit" (click)="createPassword()" [disabled]="isResetFormValid()">
            Reset Password
        </button>
    </div>
</form>