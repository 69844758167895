<div class="bg">
    <img src="/assets/images/nodes.png" class="nodes" alt="">
    <div class="ball one" id="ball1"></div>
    <div class="ball two"></div>
    <div class="ball three"></div>
    <div class="ball four"></div>
    <div class="ball five"></div>
    <div class="ball six"></div>
    <img src="/assets/images/nodes.png" class="nodes2" alt="">
</div>
<ng-container >
<div class="wrpr">
    <div class="loginCont">
        <div class="animationCont">
            <img src="/assets/images/dmint-old-logo.svg" class="dmintLogo" alt="">
            <h1 class="displayHeading">
                <span>
                    Welcome to <br> Dmint Platform
                </span>
            </h1>
        </div>
        <div class="contentCard" >
            <div class="pageHead">
                <h1>
                    {{ isForgetPassword ? 'FORGOT YOUR PASSWORD?':'RESET YOUR PASSWORD' }}
                </h1>
                <p>
                    {{ isForgetPassword ? 'Please provide your registered email to receive the verification code for password recovery.': 
                    'Please provide the verification code sent to your registered email.' }}
                </p>
                <p>Fields marked with <span>*</span> are mandatory.</p>
            </div>
            <form class="form" *ngIf="isForgetPassword" (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                <div class="errorCont" *ngIf="loginForm.get('email').hasError('emailExists') && loginForm.get('email').dirty">
                    <img src="/assets/images/failed.svg" alt="">
                    <p > {{ loginForm.get('email').getError('errorMessage') }}</p>
                </div>
                 <!-- Display the custom error message from the validator (blur)="checkExistingUserStatus()"-->
               <div class="formControl" *ngIf="isForgetPassword">
                     <label class="formLabel required" for="emailToVerify">
                        Email Address
                    </label>
                    <input id="emailToVerify" autocomplete="false" type="email"  class="inputField" required formControlName="email" [class.invalid]="loginForm.get('email')?.invalid && loginForm.get('email').touched">
                    <img src="/assets/images/email_icon.svg" alt="email-icon" class="emailIcon">
                </div>
             
                <button class="primary" type="submit" [disabled]="isLoginFormValid()">
                    {{ 'Send Code' }}
                </button>
                <a (click)="backToLogin()" class="dmintLink">
                    {{ 'Back to Login' }}
                </a>
            </form>
          
            <form class="form" *ngIf="!isForgetPassword"  [formGroup]="resetForm">
                <div class="errorCont" *ngIf="resetForm.get('confirmPassword')?.dirty&&resetForm?.errors?.['passwordsMismatch']">
                    <img  src="/assets/images/failed.svg" alt="">
                    <p> {{'Passwords do not match.'}}</p>
                </div>
                <div class="errorCont" *ngIf="resetForm.get('verificationCode')?.dirty && resetForm.get('verificationCode').hasError('expiredCode')">
                    <img  src="/assets/images/failed.svg" alt="">
                    <p>{{ resetForm.get('verificationCode').getError('errorMessage') }}</p>
                </div>
                 <div class="formControl" >
                    <label class="formLabel required" for="verificationCode">
                        Verification Code
                    </label>
                    <input id="verificationCode" autocomplete="false" type="text" class="inputField" required formControlName="verificationCode" [class.invalid]="isSubmitted && resetForm.get('verificationCode')?.invalid">
                    <p style="display:flex; align-items: center;" class="resendLink">{{codeExpText}}  
                        <span  style="margin-left: 0.25rem;" *ngIf="isShowTimer" class="resendLink" >{{timer}}</span>
                        <button style="margin-left: 0.25rem;" *ngIf="!isShowTimer" class="dmintLink" (click)="resendCode()">Resend Code</button>
                    </p>
                </div>
                <div class="formControl">
                    <label class="formLabel required" for="newPass">
                        New Password
                    </label>
                    <input id="newPass" autocomplete="false" [type]="isPasswordHidden ? 'password' : 'text'"  class="inputField" required formControlName="newPassword" [class.invalid]="isSubmitted && resetForm.get('newPassword')?.invalid">
                    <img *ngIf="isPasswordHidden" (click)="showHidePass()" src="/assets/images/password-show.svg" alt="show-password-icon" title="Show Password" class="passwordIcon">
                    <img *ngIf="!isPasswordHidden" (click)="showHidePass()" src="/assets/images/password-hide.svg" alt="hide-password-icon" title="Hide Password" class="passwordIcon">
                </div>
                <div class="formControl">
                    <label class="formLabel required" for="conPass">
                        Confirm Password
                    </label>
                    <input id="conPass" autocomplete="false" [type]="isConfirmPasswordHidden ? 'password' : 'text'"  class="inputField" required formControlName="confirmPassword" [class.invalid]="isSubmitted && resetForm.get('confirmPassword')?.invalid">
                    <img *ngIf="isConfirmPasswordHidden" (click)="showHideConfirmPass()" src="/assets/images/password-show.svg" alt="show-password-icon" title="Show Password" class="passwordIcon">
                    <img *ngIf="!isConfirmPasswordHidden" (click)="showHideConfirmPass()" src="/assets/images/password-hide.svg" alt="hide-password-icon" title="Hide Password" class="passwordIcon">
                </div>
                <div class="passRequirements">
                    <p>Please make sure that the password contains:</p>
                    <ul>
                        <li>
                            <img *ngIf="!isPasswordValid().hasSpecialCharacter" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasSpecialCharacter" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one special character (e.g., !, &#64;, #, $, %, ^, &, *, etc.).
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().hasCapitalLetter" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasCapitalLetter" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one capital case character and one lower case character
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().hasNumber" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().hasNumber" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            atleast one number
                        </li>
                        <li>
                            <img *ngIf="!isPasswordValid().isLongEnough" src="/assets/images/validateMe.svg" alt="before-valid-icon" title="validateMe" class="validateMe">
                            <img *ngIf="isPasswordValid().isLongEnough" src="/assets/images/validatedMe.svg" alt="after-valid-icon" title="validated" class="validated">
                            should have minimum length of 8 characters
                        </li>
                    </ul>
                </div>
             
           
                <button class="primary" type="submit" (click)="onSubmit()" [disabled]="isResetFormValid()">
                    {{ 'Reset Password' }}
                </button>
                <a (click)="backToLogin()" class="dmintLink">
                    {{ 'Back to Login' }}
                </a>
            </form>

            <app-success-toast *ngIf="successMsg&&showSuccessToast" [message]="successMsg" ></app-success-toast>
            <app-error-toast *ngIf="error && showErrorToast" [message]="error" ></app-error-toast>
        
        </div>
    </div>
</div>
</ng-container>