<div class="card">
  <div class="pageNameCont">
    <img
      src="../../../../assets/images/capabilities.svg"
      alt="capabilities-icon"
    />
    <h2>Capabilities</h2>
  </div>
  <div class="tabs col-12">
    <div
      class="tab-item activeTab"
      [class.activeTab]="viewMode == 'tab1'"
      rel="tab1"
      (click)="viewMode = 'tab1'"
    >
      All Capabilities
    </div>
    <div
      class="tab-item"
      [class.activeTab]="viewMode == 'tab2'"
      rel="tab2"
      (click)="viewMode = 'tab2'"
      *ngIf="userId"
    >
      Recently Opened
    </div>
    <div
      class="tab-item"
      [class.activeTab]="viewMode == 'tab3'"
      rel="tab3"
      (click)="viewMode = 'tab3'"
      *ngIf="userId"
    >
      My Favourites
    </div>
  </div>
  <ng-container class="tab-slider--container" [ngSwitch]="viewMode">
    <ng-container id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">
      <div class="productContainer col-12">
        <div class="productsGrid">
          <div
            class="productCard"
            *ngFor="let moduleItem of getCapabilitiesModules"
            [ngClass]="!moduleItem.moduleLink ? 'comingSoon' : ''"
          >
            <div class="likeBtn" *ngIf="userId">
              <img
                class="default"
                src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                alt="like-product-icon-default"
              />
              <img
                class="active"
                src="../../../../assets/images/favourite_Btn_Icon_active.svg"
                alt="like-product-icon-active"
              />
            </div>
            <!-- Favourite product button ends -->
            <!-- src="../../../../assets/images/pairing_AAP.png" -->
            <div class="card-main-content">
              <img
                class="productBanner cstm-height"
                [src]="(moduleByteMap[moduleItem.moduleName] 
                || moduleByteMap['Tyre Pairing']) | base64: 'image/png'"
                alt="img"
              />
              <div class="productDetails">
                <h3 title="{{ moduleItem.moduleName }}">
                  {{ moduleItem.moduleName }}
                </h3>
                <p class="product-desc-wrapper">
                  {{ moduleItem.moduleLongDescription }}
                </p>
                <span
                  ><a
                    class="tertiary_green_link_btn"
                    (click)="viewMore(moduleItem.id)"
                    >View More</a
                  ></span
                >
              </div>
            </div>
            <div>
              <div *ngIf="!userId">
                <a class="btnText" (click)="onSignIn()">
                  Sign In
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="visit-site"
                  /></span>
                </a>
              </div>
              <div *ngIf="moduleItem.subscription && userId">
                <a
                  class="btnText"
                  (click)="onPairingApicall(moduleItem.moduleLink)"
                >
                  Visit Site
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="visit-site"
                  /></span>
                </a>
              </div>
              <div *ngIf="moduleItem.subscription == false && userId">
                <a class="btnText" (click)="onSubscribe()">
                  Subscribe
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="visit-site"
                  /></span>
                </a>
              </div>
              <p
                class="coming_soon"
                [ngClass]="moduleItem.moduleLink ? 'display_none' : ''"
              >
                Coming Soon
              </p>
            </div>
          </div>
          <!-- Product card ends -->
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class="productContainer col-12">
        <div class="productsGrid">
          <!-- Product card ends -->
        </div>
        <div
          class="modal-popup-container container-fluid"
          *ngIf="isViewMoreVisible"
        >
          <ng-container>
            <div class="modal-card col-6">
              <div class="likeBtn true" *ngIf="userId">
                <img
                  class="default"
                  src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                  alt="like-product-icon-default"
                />
                <img
                  class="active"
                  src="../../../../assets/images/favourite_Btn_Icon_active.svg"
                  alt="like-product-icon-active"
                />
              </div>
              <img
                class="productBanner"
                [src]="(moduleByteMap[getModuleDatabyId.moduleName] 
                  || moduleByteMap['Tyre Pairing']) | base64: 'image/png'"
                alt="img"
              />
              <div class="productDetails">
                <h3>{{ getModuleDatabyId.moduleName }}</h3>
                <p class="product-desc-wrapper">
                  {{ getModuleDatabyId.moduleLongDescription }}
                </p>
              </div>
              <div class="ctaCont col-12">
                <button
                  class="tertiary_green_link_btn"
                  (click)="onViewMoreClose()"
                >
                  Close
                </button>
                <div *ngIf="!userId">
                  <a class="btnText" (click)="onSignIn()">
                    Sign In
                    <span
                      ><img
                        class="smallerIcons"
                        src="../../../../assets/images/btn_icon.svg"
                        alt="signin-site"
                    /></span>
                  </a>
                </div>
                <div *ngIf="getModuleDatabyId.subscription && userId">
                  <a
                    class="btnText primary_green_btn"
                    (click)="onPairingApicall(getModuleDatabyId.moduleLink)"
                  >
                    Visit Site
                    <span
                      ><img
                        class="smallerIcons"
                        src="../../../../assets/images/btn_icon.svg"
                        alt="visit-site"
                    /></span>
                  </a>
                </div>
                <div *ngIf="getModuleDatabyId.subscription == false && userId">
                  <a class="btnText" (click)="onSubscribe()">
                    Subscribe
                    <span
                      ><img
                        class="smallerIcons"
                        src="../../../../assets/images/btn_icon.svg"
                        alt="subscribe-site"
                    /></span>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Modal window container ends -->
      </div>
    </ng-container>

    <ng-container id="tab2" class="tab-slider--body" *ngSwitchCase="'tab2'">
      <div class="messageContainer col-12">
        <img
          src="../../../../assets/images/noRecents.svg"
          alt="no-recently-opened-products"
        />
        <div class="message">
          <h1>You have not opened any capabilities so far.</h1>
          <p>Visit the products to view recently opened products here.</p>
        </div>
        <div class="primary_green_btn">
          <a
            class="btnText"
            style="padding: 0 !important"
            (click)="backToAllCapabilities()"
            >BACK TO ALL CAPABILITIES</a
          >
        </div>
      </div>
    </ng-container>
    <ng-container id="tab3" class="tab-slider--body" *ngSwitchCase="'tab3'">
      <div class="messageContainer col-12">
        <img
          src="../../../../assets/images/noFavs.svg"
          alt="no-favourites-products"
        />
        <div class="message">
          <h1>You do not have any favourites.</h1>
          <p>
            Click on
            <span
              ><img
                src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                alt=""
            /></span>
            this icon to add products to the favourites.
          </p>
        </div>
        <div class="primary_green_btn">
          <a
            class="btnText"
            style="padding: 0 !important"
            (click)="backToAllCapabilities()"
            >BACK TO ALL CAPABILITIES</a
          >
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-alert
  [message]="errMsg"
  *ngIf="showErrorMessageWithPopup"
  (close)="onCloseHandler()"
></app-alert>
