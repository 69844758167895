import { AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, map, catchError, take } from 'rxjs/operators';
import { ConfirmEmailService } from '../components/confirm-email/confirm-email.service';


export function emailCheckAsyncValidator(confirmEmail: ConfirmEmailService) {
    return (control: AbstractControl): Observable<any> => {
        if (!control.value) {
            return of(null);  // Return null if no value in the control
        }

        return control.valueChanges.pipe(
            debounceTime(500),  // Debounce for 500ms
            switchMap(() =>
                confirmEmail.checkExistingUser(control.value).pipe(
                    map((response) => {
                        // Check if email exists and handle different statuses
                        const existingUserStatus = response.result.statusName;
                        let erroMsgText = '';

                        if (response.result?.emailExists) {
                          
                            if (existingUserStatus === 'ACTIVE') {
                                return null;  // Email exists but is active, no error
                            } else if (existingUserStatus === 'DELETED' || existingUserStatus === 'INACTIVE') {
                                erroMsgText = 'User is not registered on the platform.';
                            } else if (existingUserStatus === 'UNVALIDATED') {
                                erroMsgText = 'User not validated and yet to complete the onboarding.';
                            }

                            // Return the error object with the custom message
                            return { emailExists: true, errorMessage: erroMsgText };
                        }else{
                            erroMsgText = 'Please enter registered email address'
                            return { emailExists: true, errorMessage: erroMsgText };
                        }
                        // return null;  // No error if email does not exist
                    }),
                    catchError(() => {
                        // If the service fails (e.g., network error), return a generic error message
                        return of({ serverError: 'There was a problem. Please try again later.' });
                    })
                )
            ),
            take(1)  
        );
    };
}
