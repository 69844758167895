<div class="card">
  <div class="pageNameCont">
    <img
      src="../../../../assets/images/insights_icon.svg"
      alt="Insights-icon"
    />
    <h2>Insights</h2>
  </div>
  <div class="tabs col-12">
    <div
      class="tab-item activeTab"
      [class.activeTab]="viewMode == 'tab1'"
      rel="tab1"
      (click)="viewMode = 'tab1'"
      onKeyPress=""
    >
      All Insights
    </div>
    <div
      *ngIf="id"
      class="tab-item"
      [class.activeTab]="viewMode == 'tab2'"
      rel="tab2"
      (click)="viewMode = 'tab2'"
      onKeyPress=""
    >
      Recently Opened
    </div>
    <div
      *ngIf="id"
      class="tab-item"
      [class.activeTab]="viewMode == 'tab3'"
      rel="tab3"
      (click)="viewMode = 'tab3'"
      onKeyPress=""
    >
      My Favourites
    </div>
  </div>

  <ng-container class="tab-slider--container" [ngSwitch]="viewMode">
    <ng-container id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">
      <div class="productContainer col-12">
        <div class="productsGrid">
          <div
            class="productCard"
            *ngFor="let moduleItem of getinsightsModules"
            [ngClass]="!moduleItem.moduleLink ? 'comingSoon' : ''"
          >
            <div class="likeBtn" *ngIf="id">
              <img
                class="default"
                src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                alt="like-product-icon-default"
              />
              <img
                class="active"
                src="../../../../assets/images/favourite_Btn_Icon_active.svg"
                alt="like-product-icon-active"
              />
            </div>
            <!-- Favourite product button ends -->
            <!-- src="../../../../assets/images/pairing_AAP.png" -->
            <div class="card-main-content">
              <img
                class="productBanner cstm-height"
                [src]="
                  moduleByteMap[moduleItem.moduleName] ||
                    moduleByteMap['Tyre Pairing'] | base64 : 'image/png'
                "
                alt="product-banner"
              />
              <div class="productDetails">
                <h3 title="{{ moduleItem.moduleName }}">
                  {{ moduleItem.moduleName }}
                </h3>
                <p class="product-desc-wrapper">
                  {{ moduleItem.moduleLongDescription }}
                </p>
                <span
                  ><a
                    class="tertiary_green_link_btn"
                    (click)="viewMore(moduleItem.id)"
                    >View More</a
                  ></span
                >
              </div>
            </div>

            <div class="primary_green_btn" *ngIf="!userId">
              <a class="btnText custom-padding1" (click)="onSignIn()">
                Sign In
                <span
                  ><img
                    class="smallerIcons"
                    src="../../../../assets/images/btn_icon.svg"
                    alt="signin-site"
                /></span>
              </a>
            </div>
            <div
              class="primary_green_btn"
              *ngIf="moduleItem.subscription && userId"
            >
              <a
                class="btnText"
                (click)="
                  onInsightsApicall(moduleItem.id, moduleItem.moduleLink)
                "
              >
                Visit Site
                <span
                  ><img
                    class="smallerIcons"
                    src="../../../../assets/images/btn_icon.svg"
                    alt="visit-site"
                /></span>
              </a>
            </div>
            <div
              class="primary_green_btn"
              *ngIf="moduleItem.subscription == false && userId"
            >
              <a class="btnText custom-padding1" (click)="onSubscribe()">
                Subscribe
                <span
                  ><img
                    class="smallerIcons"
                    src="../../../../assets/images/btn_icon.svg"
                    alt="subscribe-site"
                /></span>
              </a>
            </div>
            <p
              class="coming_soon"
              [ngClass]="moduleItem.moduleLink ? 'display_none' : ''"
            >
              Coming Soon
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="productContainer col-12">
        <div class="productsGrid"></div>
        <div
          class="modal-popup-container container-fluid"
          *ngIf="isViewMoreVisible"
        >
          <div class="modal-card col-6">
            <div class="likeBtn true" *ngIf="id">
              <img
                class="default"
                src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                alt="like-product-icon-default"
              />
              <img
                class="active"
                src="../../../../assets/images/favourite_Btn_Icon_active.svg"
                alt="like-product-icon-active"
              />
            </div>
            <!-- src="../../../../assets/images/featuredImage-ProductAAP.png" -->
            <img
              class="productBanner"
              [src]="
                moduleByteMap[getModuleDatabyId.moduleName] ||
                  moduleByteMap['Tyre Pairing'] | base64 : 'image/png'
              "
              alt="product-banner"
            />
            <div class="productDetails">
              <h3>{{ getModuleDatabyId.moduleName }}</h3>
              <p class="product-desc-wrapper">
                {{ getModuleDatabyId.moduleLongDescription }}
              </p>
            </div>
            <div class="ctaCont col-12">
              <button
                class="tertiary_green_link_btn"
                (click)="onViewMoreClose()"
              >
                Close
              </button>
              <div class="primary_green_btn" *ngIf="!userId">
                <a class="btnText" (click)="onSignIn()">
                  Sign In
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="signin-site"
                  /></span>
                </a>
              </div>
              <div
                class="primary_green_btn"
                *ngIf="getModuleDatabyId.subscription && userId"
              >
                <div
                  class="btnText"
                  onKeyPress=""
                  (click)="
                    onInsightsApicall(
                      getModuleDatabyId.id,
                      getModuleDatabyId.moduleLink
                    )
                  "
                >
                  Visit Site
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="visit-site"
                  /></span>
                </div>
              </div>
              <div
                class="primary_green_btn"
                *ngIf="getModuleDatabyId.subscription == false && userId"
              >
                <a class="btnText" (click)="onSubscribe()">
                  Subscribe
                  <span
                    ><img
                      class="smallerIcons"
                      src="../../../../assets/images/btn_icon.svg"
                      alt="subscribe-site"
                  /></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal window container ends -->
      </div>
    </ng-container>
    <ng-container *ngIf="id">
      <ng-container id="tab2" class="tab-slider--body" *ngSwitchCase="'tab2'">
        <div class="messageContainer col-12">
          <img
            src="../../../../assets/images/noRecents.svg"
            alt="no-recently-opened-products"
          />
          <div class="message">
            <h1>You have not opened any Insights so far.</h1>
            <p>Visit the products to view recently opened products here.</p>
          </div>
          <div class="primary_green_btn">
            <a class="btnText" (click)="backToAllInsights()"
              >BACK TO ALL INSIGHTS</a
            >
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="id">
      <ng-container id="tab3" class="tab-slider--body" *ngSwitchCase="'tab3'">
        <div class="messageContainer col-12">
          <img
            src="../../../../assets/images/noFavs.svg"
            alt="no-favourites-products"
          />
          <div class="message">
            <h1>You do not have any favourites.</h1>
            <p>
              Click on
              <span
                ><img
                  src="../../../../assets/images/favourite_Btn_Icon_default.svg"
                  alt=""
              /></span>
              this icon to add products to the favourites.
            </p>
          </div>
          <div class="primary_green_btn">
            <a class="btnText" (click)="backToAllInsights()"
              >BACK TO ALL INSIGHTS</a
            >
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<app-alert
  [message]="errMsgPopup"
  *ngIf="showErrorMessageWithPopup"
  (close)="onCloseHandler()"
></app-alert>

<app-alert
  [message]="errMsg"
  *ngIf="showErrorMessageWithPopup"
  (close)="onCloseHandlerSub()"
></app-alert>
