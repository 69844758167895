import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/shared/helper/util.service';
import { HomePageService } from 'src/app/shared/services/homepage.service';

@Component({
  selector: 'app-app',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  applicationsModule: any;
  public applicationsData: any;
  applicationsModuleRecords: any = [];
  token: any;
  getApplicationsModules: any = [];
  isViewMoreVisible: boolean = false;
  getModuleDatabyId: any;

  viewMode = 'tab1';
  userId: string;
  errMsgPopup: any;
  showErrorMessageWithPopup: any = false;
  clearSubscription: Subscription;
  moduleByteMap: any;

  subscriptionMsg =
    'Please contact DMINT Administrator to get subscription of the same';
  errMsg: any;
  closeSign: string;

  constructor(
    private homePageService: HomePageService,
    private spinnerService: NgxSpinnerService,
    private utilService: UtilService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.utilService.goToTop();
    this.utilService.moduleBytes.subscribe((mod) => {
      this.moduleByteMap = mod as Object;
    });

    this.utilService.getUserId.subscribe((userId: any) => {
      this.userId = userId;
      console.log(userId + 'in Application');

      // form header api call
      this.homePageService.onHeader(this.userId).subscribe((res) => {
        this.spinnerService.hide();
        this.applicationsModule = res;
        console.log(this.applicationsModule);
        if (this.applicationsModule.message === 'OK') {
          this.applicationsData = this.applicationsModule.result;
          this.applicationsModuleRecords = [...this.applicationsData];
          for (let modulerecord of this.applicationsModuleRecords) {
            if (modulerecord.headerName == 'Applications') {
              this.getApplicationsModules = modulerecord.modules;
              console.log(this.getApplicationsModules);
            }
          }
        }
      });
    });

    //getting errors block
    this.clearSubscription = this.utilService.SharingDataPopUpMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errMsgPopup = res;
      }
    );

    this.clearSubscription = this.utilService.getPopupStatus.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.showErrorMessageWithPopup = res;
      }
    );
  }

  onSignIn() {
    this.utilService.onSignIn();
  }

  onSubscribe() {
    this.errMsg = this.subscriptionMsg;
    this.closeSign = 'OK';
    this.showErrorMessageWithPopup = true;
  }

  viewMore(moduleId: any) {
    for (let module of this.getApplicationsModules) {
      if (moduleId === module.id) {
        this.getModuleDatabyId = module;
        this.isViewMoreVisible = true;
      }
    }
  }

  onViewMoreClose() {
    this.isViewMoreVisible = false;
  }

  backToAllApplication() {
    this.viewMode = 'tab1';
  }

  onApplicationsApicall(moduleLink: any) {
    this.spinnerService.hide();
    window.open(`${moduleLink}`, '_blank');
  }

  onCloseHandler() {
    if (
      this.errMsgPopup != undefined &&
      this.errMsgPopup.includes('Session Expired')
    ) {
      localStorage.clear();
      this.auth.logout();
    }
    this.errMsg = null;
    this.showErrorMessageWithPopup = false;
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }
}
