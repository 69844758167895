<form class="profileForm">
    <div class="formGroupMain">
        <div class="sectionHead">
            <p>Here you can edit public information about yourself.</p>
            <img style="cursor: pointer" src="/assets/images/edit-icon.svg" *ngIf="!isProfileEditable" (click)="editMyProfile()" alt="">
        </div>
        <div class="errorCont">
            <img src="/assets/images/failed.svg" alt="">
            <p>Invalid credentials. Please try with correct credentials.</p>
        </div>
        <form class="form" [formGroup]="userForm">
        <div class="formfields">
            <div class="row1">
                <div class="formControl">
                    <label class="formLabel" for="email">
                        First Name
                    </label>
                    <input type="text" id="email" formControlName="firstname" autocomplete="false" [disabled]="!isProfileEditable" class="inputField" required>
                    <div *ngIf="firstname?.hasError('required') && firstname?.touched" class="errorText">First name is required
                    </div>
                    <div *ngIf="firstname?.hasError('minlength') && firstname?.touched" class="errorText">First name should be
                      atleast 2
                      characters long</div>
                    <div *ngIf="firstname?.hasError('maxlength') && firstname?.touched" class="errorText">First name should not
                      be more
                      than
                      50 characters</div>
                    <div *ngIf="firstname?.hasError('pattern') && firstname?.touched" class="errorText">First name should only
                      contain
                      alphabetic characters</div>
                </div>
                <div class="formControl">
                    <label class="formLabel" for="email">
                        Last Name
                    </label>
                    <input type="text" id="email" formControlName="lastname" autocomplete="false" [disabled]="!isProfileEditable" class="inputField" required>
                    <div *ngIf="lastname?.hasError('required') && lastname?.touched" class="errorText">Last name is required
                    </div>
                    <div *ngIf="lastname?.hasError('minlength') && lastname?.touched" class="errorText">Last name should be
                      atleast 2
                      characters long</div>
                    <div *ngIf="lastname?.hasError('maxlength') && lastname?.touched" class="errorText">Last name should not be
                      more than
                      50 characters</div>
                    <div *ngIf="lastname?.hasError('pattern') && lastname?.touched" class="errorText">Last name should only
                      contain
                      alphabetic characters</div>
                </div>
                <div class="formControl">
                    <label class="formLabel required" for="email">
                        Email Address
                    </label>
                    <input [title]="emailId" type="email" id="email" autocomplete="false" [value]="emailId" disabled class="inputField" required>
                    <img src="/assets/images/email_icon.svg" alt="email-icon" class="emailIcon">
                </div>
            </div>
            <div class="row1">
                <div class="formControl">
                    <label class="formLabel" for="email">
                        User Type
                    </label>
                    <p>{{userDetails?.userType}}</p>
                </div>
                <div class="formControl">
                    <label class="formLabel" for="email">
                        Customer Name
                    </label>
                    <p>{{userDetails?.companyName}}</p>
                </div>
                <div class="formControl">
                    <label class="formLabel" for="email">
                        Date of enrollment
                    </label>
                    <p>{{userDetails?.enrollmentDate | date:'d MMM yyyy'}}</p>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div class="ctaCont" *ngIf="isProfileEditable">
        <button class="secondary" type="submit" (click)="onCancel()">
            Cancel
        </button>
        <button class="primary" type="submit" (click)="onSubmit()" [disabled]="userForm.invalid">
            Save Changes
        </button>
    </div>
</form>
<app-success-toast *ngIf="successMsg&&showSuccessToast" [message]="successMsg" ></app-success-toast>
<app-error-toast *ngIf="error && showErrorToast" [message]="error" ></app-error-toast>
