import { AbstractControl } from "@angular/forms";

export const checkUserEmail = (control: AbstractControl) => {
    let users = control.value.users
    let emails: any = []
    if (users.length > 0) {
        // console.log(users.map((x: any) => x.email))
        emails = users.map((x: any) => x.email)
        const uniq = emails.map((email: any) => ({ count: 1, email: email }))
            .reduce((result: any, b: any) => {
                result[b.email] = (result[b.email] || 0) + b.count;
                return result;
            }, {});
        const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
        // console.log(duplicates);
        // let filteredUsers = users.filter((el: any) => el.userModules?.length == 0)
        if (duplicates.length > 0) {
            // console.log({ userModuleRequired: true, users: filteredUsers })
            // users: users.filter((x:any)=>x.
            return { checkUserEmail: true }
        }
    }
    return {}
}