import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, computed, Inject, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-accout-settings',
  templateUrl: './accout-settings.component.html',
  styleUrl: './accout-settings.component.scss'
})
export class AccoutSettingsComponent {

  activeTab = 'profile';

  constructor(@Inject(PLATFORM_ID) private platformId: object, private router: Router, private route: ActivatedRoute) {}

  ngonInit() {
    let savedTab: string | null = null;

    if (isPlatformBrowser(this.platformId)) {
      savedTab = localStorage.getItem('selectedTab');
    }

    this.route.url.subscribe(() => {
      const activeChild = this.route.firstChild?.snapshot.url[0]?.path;
      this.activeTab = activeChild || savedTab || 'profile';

      if (!activeChild) {
        this.router.navigate([this.activeTab], { relativeTo: this.route });
      }
    });
  }
    

  setActiveTab(tab: string) {
    this.activeTab = tab;
    localStorage.setItem('selectedTab', tab);
  }
}
