
<div class="wrpr">
    <div class="contentCard">
        <div class="pageHead">
            <img src="/assets/images/settings-black.svg" alt="capabilities-icon">
            <h1>Account Settings</h1>
        </div>
        <div class="settingsCont">
            <div class="settingsMenu">
                <div class="menuItem"  [class.active]="activeTab === 'profile'" (click)="setActiveTab('profile')">
                    <img *ngIf="activeTab === 'profile'" src="/assets/images/user-icon-blue.svg" alt="user-icon">
                    <img *ngIf="activeTab !== 'profile'" src="/assets/images/user-icon.svg" alt="user-icon">
                    <p>My Profile</p>
                    <img class="tabChevron" *ngIf="activeTab === 'profile'" src="/assets/images/chevron-south.svg" alt="user-icon">
                </div>
                <div class="menuItem"  [class.active]="activeTab === 'change-password'" (click)="setActiveTab('change-password')">
                    <img *ngIf="activeTab === 'change-password'" src="/assets/images/protection-blue.svg" alt="user-icon">
                    <img *ngIf="activeTab !== 'change-password'" src="/assets/images/protection.svg" alt="user-icon">
                    <p>Change Password</p>
                    <img class="tabChevron" *ngIf="activeTab === 'change-password'" src="/assets/images/chevron-south.svg" alt="user-icon">
                </div>
            </div>
            <div class="tabConent">
                <!-- <router-outlet/> -->
                 <app-edit-profile *ngIf="activeTab === 'profile'"></app-edit-profile>
                 <app-change-password *ngIf="activeTab === 'change-password'"></app-change-password>
            </div>
        </div>
    </div>
</div>