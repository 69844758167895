import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilService } from './helper/util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    }),
  };
  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService
  ) {}

  /* onLogin(token: any) {
    return this.http.post(`${environment.apiUrl}/v1/login`, { token });
  } */

  /* logOut() {
    this.utilService.openPopup(false);
    this.utilService.openErrorTextBlock(false);
    this.utilService.showMenus(false);
    localStorage.clear();
    this.router.navigate(['/login']);
  } */
}
