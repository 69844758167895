import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/shared/helper/util.service';
import { HomePageService } from 'src/app/shared/services/homepage.service';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit, OnDestroy {
  insightsModule: any;
  public insightsData: any;
  insightsModuleRecords: any = [];
  getinsightsModules: any = [];
  viewMode = 'tab1';
  isViewMoreVisible: boolean = false;
  getModuleDatabyId: any;
  userId: string;
  errMsgPopup: any;
  showErrorMessageWithPopup: any = false;
  clearSubscription: Subscription;
  id: string = '';
  subscriptionMsg =
    'Please contact DMINT Administrator to get subscription of the same';
  errMsg: any;
  closeSign: string;
  moduleByteMap: any;

  constructor(
    private spinnerService: NgxSpinnerService,
    private homePageService: HomePageService,
    private utilService: UtilService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.utilService.goToTop();
    this.utilService.moduleBytes.subscribe((mod) => {
      this.moduleByteMap = mod as Object;
    });

    this.utilService.getUserId.subscribe((userId: any) => {
      this.userId = userId;

      // form header api call
      this.homePageService.onHeader(this.userId).subscribe((res) => {
        this.spinnerService.hide();
        this.insightsModule = res;
        if (this.insightsModule.message === 'OK') {
          this.insightsData = this.insightsModule.result;
          this.insightsModuleRecords = [...this.insightsData];
          for (let modulerecord of this.insightsModuleRecords) {
            if (modulerecord.id == '4') {
              this.getinsightsModules = modulerecord.modules;
            }
          }
        }
      });
    });

    if (localStorage.getItem('userId')) {
      this.id = localStorage.getItem('userId');
    }

    //getting errors block
    this.clearSubscription = this.utilService.SharingDataPopUpMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errMsgPopup = res;
      }
    );

    this.clearSubscription = this.utilService.getPopupStatus.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.showErrorMessageWithPopup = res;
      }
    );

    //getting errors block
    this.utilService.SharingDataPopUpMessage.subscribe((res: any) => {
      this.spinnerService.hide();
      this.errMsg = res;
    });
    this.utilService.getPopupStatus.subscribe((res: any) => {
      this.spinnerService.hide();

      if (this.errMsg !== null) {
        this.showErrorMessageWithPopup = res;
      }
    });
  }

  backToAllInsights() {
    this.viewMode = 'tab1';
  }

  viewMore(moduleId: any) {
    for (let module of this.getinsightsModules) {
      if (moduleId === module.id) {
        this.getModuleDatabyId = module;
        this.isViewMoreVisible = true;
      }
    }
  }

  onViewMoreClose() {
    this.isViewMoreVisible = false;
  }

  onInsightsApicall(id: any, moduleLink: any) {
    this.spinnerService.hide();
    window.open(`${moduleLink}`, '_blank');
  }

  onCloseHandler() {
    if (this.errMsgPopup.includes('Session Expired')) {
      localStorage.clear();
      this.auth.logout();
    }
    this.errMsgPopup = null;
  }

  onSignIn() {
    this.utilService.onSignIn();
  }

  onSubscribe() {
    this.errMsg = this.subscriptionMsg;
    console.log(this.errMsg);
    this.closeSign = 'OK';
    this.showErrorMessageWithPopup = true;
  }

  onCloseHandlerSub() {
    this.errMsg = null;
    this.showErrorMessageWithPopup = false;
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }
}
