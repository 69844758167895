import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  private lastVisitedUrl: string = '';

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {       
        if (event.url !== '/' && !event.url.startsWith('/?code')) {          
          this.lastVisitedUrl = event.url;          
          localStorage.setItem('lastVisitedUrl', this.lastVisitedUrl);
        }
      }
    });
  }

  getLastVisitedUrl(): string {
    return this.lastVisitedUrl;
  }
}
