import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordValidator } from '../../Validators/checkPassword.validator';
import { matchPasswordValidator } from '../../Validators/checkBothPasswords.validator';
import { ConfirmEmailService } from '../confirm-email/confirm-email.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrl: './create-password.component.scss'
})
export class CreatePasswordComponent {
  passwordForm: FormGroup;
  showSuccessToast = false;
  capitalLetterFlag = false;
  uniqueCharacterFlag = false;
  stringLengthGt12Flag = false;
  stringNumFlag = false;
  email = ''
  showErrorToast = false;
  error = ''
  constructor(private fb: FormBuilder, private confirmEmail: ConfirmEmailService, private router: Router) { }
  ngOnInit() {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: matchPasswordValidator('password', 'confirmPassword') })
    this.confirmEmail.email$.subscribe((res: any) => {
      this.email = res;
    })
  }
  submitForm() {
    let payload = {
      "emailId": this.email,
      "password": this.passwordForm.get('password').value
    }
    this.confirmEmail.createPassword(payload).subscribe((res: any) => {      
      if (res.code == 200) {
        this.showSuccessToast = true;
        setTimeout(() => {
          this.router.navigate(['/home'])
          this.showSuccessToast = false;
        }, 3000);
      } else if (res.code == 500) {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
    })
  }
  checkPasswordRules() {
    this.uniqueCharacterFlag = this.containsSpecialCharacter(this.passwordForm.get('password').value)
    this.capitalLetterFlag = this.containsCapitalLetter(this.passwordForm.get('password').value)
    this.stringLengthGt12Flag = this.passwordForm.get('password').value.trim().length >= 8
    this.stringNumFlag = this.containsLowercaseAndNumber(this.passwordForm.get('password').value)
  }
  containsCapitalLetter(input: string): boolean {
    const capitalLetterRegex = /[A-Z]/;
    return capitalLetterRegex.test(input);
  }
  containsLowercaseAndNumber(input: string): boolean {
    const lowercasePattern = /[a-z]/;
    const numberPattern = /[0-9]/;
    return lowercasePattern.test(input) && numberPattern.test(input);
  }
  containsSpecialCharacter(input: string): boolean {
    const specialCharacterRegex = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/;
    // const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(input);
  }
}
