import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-toast',
  templateUrl: './success-toast.component.html',
  styleUrls: ['./success-toast.component.scss'],
})
export class SuccessToastComponent implements OnInit {
  @Input() message: string | undefined;

  ngOnInit(): void {}
}
