import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DataRequestManagerService } from './data-request-manager.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/helper/util.service';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { formatDate } from '@angular/common';
import * as FileSaver from 'file-saver';
import { DummyDataRequestService } from './json-request.service';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-data-request-manager',
  templateUrl: './data-request-manager.component.html',
  styleUrls: ['./data-request-manager.component.scss'],
})
export class DataRequestManagerComponent implements OnInit, OnDestroy {
  dataRequestForm!: FormGroup;
  errMsgPopup: any;
  errMsgText: string = null;
  errorFlag = false;
  element: any;
  files: any;
  searchTypeRes: any;
  statusTiles: any;
  statusFilterRes: any;
  public fileData: any;
  public fileData2: any;
  public statusTileData: any;
  public searchTypeData: any;
  public statusData: any;
  filterData: any = [];
  statusTilesData: any = [];
  totalItems: any;
  dataRequestRecords: any = [];

  isLoadData = false;
  searchValue: any = '';
  p: number = 1;
  currentPage: any;
  itemsPerPage = 10;
  isDownloadPopupVisibale: boolean = false;
  displayStyle = 'none';
  getRequestId: any;
  downloadFileLabel: any;
  downloadFileName: any;
  moduleIds: any = [];
  statusIds: any = [];
  typeFilterDropdownSettings: IDropdownSettings = {};
  periodFilterDropdownSettings: IDropdownSettings = {};
  selectedFilterType: any = [];
  selectedFilterPeriod: any = [];
  types: any = [];
  periods: any = [
    { period_name: 'current_week', period_text: 'Current Week' },
    { period_name: 'current_month', period_text: 'Current Month' },
    { period_name: 'last_month', period_text: 'Last Month' },
    { period_name: 'last_2_months', period_text: 'Last 2 Months' },
    { period_name: 'last_3_months', period_text: 'Last 3 Months' },
    { period_name: 'last_6_months', period_text: 'Last 6 Months' },
    { period_name: 'last_year', period_text: 'Last Year' },
    { period_name: '2022', period_text: '2022' },
    { period_name: '2021', period_text: '2021' },
    { period_name: '2020', period_text: '2020' },
    { period_name: '2019', period_text: '2019' },
    { period_name: '2018', period_text: '2018' },
    { period_name: '2017', period_text: '2017' },
  ];
  statusFilterDropdownSettings: IDropdownSettings = {};
  selectedFilters: any = [];
  statuses: any = [];
  getModuleIdForDownload: any;
  createdFromStart: any;
  createdToEnd: any;
  lastRunFromStart: any;
  lastRunToEnd: any;
  isShowAdvanceFilter = false;
  isEllipsisShow: boolean = false;
  isShowTooltip: boolean = false;
  getTooltipDatabyId: any;
  contentDispositionHeader: any;
  toolTipCheck = 0;
  isFilterRecords: boolean = false;
  isAdvancedFilter: boolean = false;
  bgColorStatus: boolean = false;
  isResetButtonVisible: boolean = true;
  showErrorMessage: any = false;
  showErrorMessageWithPopup: any = false;
  fillErrorText: any;
  id: string = '';
  isOpenOverlayScreen: boolean = false;
  isNotificationTextVisible: boolean = false;
  loginAlertMessage: string =
    'To unlock this Features, please login with your credentials';
  showCounting: boolean = false;
  clearSubscription: Subscription;
  isShowNewRequest: boolean = false;
  modulesResult: any;
  showOrNo: string;
  requestCompletedDate: any;
  isMenuOpen: boolean = false;
  dateFilterOptions: any = ['Created on', 'Modified on'];
  selectedDateButtonIndex: number = null;
  periodForValue: string = '';
  period: string = '';
  isPeriodDisabled: boolean = true;
  selectedRowId: any;
  tileNameValue: any;
  sortByValue: string = '';
  sortTypeValue: string = 'desc';
  sortColumn: string = '';
  isAscendingCreated: boolean = true;
  isAscendingCompleted: boolean = true;
  isDownloadPopupVisible: boolean = false;
  storeRequestId: string = '';
  responseResult: string = null;
  errorMessage: string = null;
  selectedModuleName: string[] = [];
  getAuthStatus: boolean = false;
  capabilitiesModule: any;
  public capabilitiesData: any;
  capabilitiesModuleRecords: any = [];
  getCapabilitiesModulesForLink: any;
  selectedTileIndex: number | null = null;
  flagError = false;
  moduleByteMap: any;
  getDownloadProductType: string;
  downloadUrl: string;
  noSubscriptionsFlag=false;
  // currentDate = moment(new Date()).format('MM/DD/YYYY');
  // _moment=moment;
  readonly fixedDate='09/15/2024'
  constructor(
    private spinnerService: NgxSpinnerService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private http: HttpClient,
    private dataRequestService: DataRequestManagerService,
    private dummyDataRequestService: DummyDataRequestService,
    public auth: AuthService,
    private homePageService: HomePageService
  ) {
    this.utilService.goToTop();
  }
  ngOnInit(): void {
    this.spinnerService.show();

    this.utilService.moduleBytes.subscribe((mod) => {
      this.moduleByteMap = mod as Object;
    });

    //----Once userId is available then refresh data---
    this.utilService.getUserId.subscribe((id: any) => {
      if(id != 'undefined' && id != ''){
        this.id = id as string;
        this.onInitialDataLoad();
        this.loadAssignCapabilities();
      }else{
        this.tilesCalledBeforeLogin();
        this.onInitialDataLoadBeforeLogin();
      }
    });

    if (localStorage != null && localStorage.getItem('userId') != 'undefined') {
      this.id = localStorage.getItem('userId');
    }
    this.buildForm();
    this.getStatus();
    // Type filetr API call
    if (localStorage.getItem('userId')) {
      this.loadAssignCapabilities();
    } else {
      this.modulesResult = [
        { moduleId: 1, moduleName: 'Tyre Pairing' },
        { moduleId: 2, moduleName: 'Data Quest' },
      ];
    }

    // Status filetr API call
    // Type filetr API call
    if (localStorage.getItem('userId')) {
      this.dataRequestService.onStatusFilter(this.id).subscribe((res) => {
        this.statusFilterRes = res;
        if (this.statusFilterRes.message === 'OK') {
          this.statusData = this.statusFilterRes.result;
          this.statuses = [...this.statusData];
        }
      });
    } else {
      this.statuses = [
        { id: 1, statusName: 'NOT STARTED' },
        { id: 2, statusName: 'IN PROGRESS' },
        { id: 3, statusName: 'COMPLETED' },
        { id: 4, statusName: 'FAILED' },
      ];
    }
    if (!localStorage.getItem('token')) {
      this.isNotificationTextVisible = true;
      this.showCounting = false;
    } else {
      this.showCounting = true;
      this.isNotificationTextVisible = false;
    }
    // Type Search settings
    this.typeFilterDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'moduleName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };

    this.periodFilterDropdownSettings = {
      singleSelection: true,
      idField: 'period_name',
      textField: 'period_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    // status filter Dropdown settings
    this.statusFilterDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'statusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };
    //getting errors block
    this.clearSubscription = this.utilService.SharingDataPopUpMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();        
        this.errMsgPopup = res;
      }
    );
    this.clearSubscription = this.utilService.getPopupStatus.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.showErrorMessageWithPopup = res;
      }
    );
    this.clearSubscription = this.utilService.SharingDataTextMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errMsgText = res;
        if (this.flagError) {
          this.flagError = true;
        } else {
          this.flagError = false;
        }
        setTimeout(() => {
          this.errMsgText = null;
          this.flagError = false;
        }, 3000);
      }
    );
    this.clearSubscription = this.utilService.errorTextBlock.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.showErrorMessage = res;
      }
    );

    // get module link
    this.utilService.getUserId.subscribe((userId: any) => {
      this.id = userId;

      // form header api call
      this.homePageService.onHeader(this.id).subscribe((res) => {
        this.spinnerService.hide();
        this.capabilitiesModule = res;
        if (this.capabilitiesModule.message === 'OK') {
          this.capabilitiesData = this.capabilitiesModule.result;
          this.capabilitiesModuleRecords = [...this.capabilitiesData];
          for (let modulerecord of this.capabilitiesModuleRecords) {
            if (modulerecord.headerName == 'Capabilities') {
              this.getCapabilitiesModulesForLink = modulerecord.modules;
            }
          }
        }
      });
    });
  }

  getStatus() {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('id') !== undefined
    ) {
      this.getAuthStatus = true;
    }
  }

    tilesCalledBeforeLogin() {
    this.dummyDataRequestService
      .getDummyDataRequestTilesRecords()
      .subscribe((res: any) => {
        this.statusTiles = res;        
        this.statusTileData = this.statusTiles[0].statusSummery;
        this.statusTilesData = [...this.statusTileData];
      });
  }
  onInitialDataLoadBeforeLogin() {
    // load dummy table records
    if(localStorage.getItem('userId')=='undefined'){
      this.dummyDataRequestService
      .getDummyDataTableRecords()
      .subscribe((res: any) => {
        this.files = res;
        this.fileData = this.files[0].results;
        this.filterData = [...this.fileData];
        this.totalItems = this.files[0].totalRows;
        this.isLoadData = true;
      });
    }
  }
  onInitialDataLoad() {
    this.spinnerService.show();
    const userId: any = this.id.toString();
    let headers = new HttpHeaders().append('userId', userId);
    const requestOptions = { headers: headers };
    this.http
      .post(
        `${environment.apiUrl}/v1/requests?limit=${this.itemsPerPage}&page=${this.p}`,
        null,
        requestOptions
      )
      .subscribe((res: any) => {
        this.files = res.result;
        this.fileData = this.files[0].results;
        this.filterData = [...this.fileData];
        this.totalItems = this.files[0].totalRows;        
        this.isLoadData = true;

        // load tiles data
        this.statusTiles = res.result;
        this.statusTileData = this.statusTiles[0].statusSummery;
        this.statusTilesData = [...this.statusTileData];
        this.isLoadData = true;
        this.spinnerService.hide();
      });
  }

  getPage(event: any) {
    this.spinnerService.show();
    this.p = event;
    this.currentPage = this.p - 1;
    const userId: any = this.id.toString();
    let headers = new HttpHeaders().append('userId', userId);
    const requestOptions = { headers: headers };
    if (!this.isFilterRecords && !this.isAdvancedFilter) {
      this.http
        .post(
          `${environment.apiUrl}/v1/requests?limit=${this.itemsPerPage}&page=${this.p}`,
          null,
          requestOptions
        )
        .subscribe((res: any) => {
          this.spinnerService.hide();
          this.files = res.result;
          this.fileData = this.files[0].results;
          this.filterData = [...this.fileData];
          this.totalItems = this.files[0].totalRows;
          this.isLoadData = true;
        });
    } else if (this.isFilterRecords && this.isAdvancedFilter == false) {
      const userIdValue: any = this.id.toString();
      let headersValue = new HttpHeaders().append('userId', userIdValue);
      const requestOptionsValue = { headers: headersValue };
      this.http
        .post(
          `${environment.apiUrl}/v1/requestDetailsBySearch?limit=${this.itemsPerPage}&page=${this.p}&searchText=${this.searchValue}`,
          null,
          requestOptionsValue
        )
        .subscribe({
          next: (res: any) => {
            this.spinnerService.hide();
            this.files = res.result;
            this.fileData = this.files[0].results;
            this.filterData = [...this.fileData];
            this.totalItems = this.files[0].totalRows;
            this.isLoadData = true;
            this.errorFlag = false;
          },
          error: (err: any) => {
            this.spinnerService.hide();
            this.errorFlag = true;
            this.showErrorMessage = true;
            this.fillErrorText = this.errMsgText;
          },
        });
    } else if (this.isAdvancedFilter && this.isFilterRecords == false) {
      const filterValues = {
        platformUserId: this.id.toString(),
        moduleId: this.moduleIds,
        statusId: this.statusIds,
        periodFor: this.periodForValue,
        period: this.period,
        searchText: this.searchValue,
        sortBy: this.sortByValue,
        sortType: this.sortTypeValue,
      };
      this.http
        .post(
          `${environment.apiUrl}/v1/requestDetailsFilter?limit=${this.itemsPerPage}&page=${this.p}`,
          filterValues
        )
        .subscribe({
          next: (res: any) => {
            this.spinnerService.hide();
            this.files = res.result;
            this.fileData = this.files[0].results;
            this.filterData = [...this.fileData];
            this.totalItems = this.files[0].totalRows;
            this.isLoadData = true;
            this.errorFlag = false;
          },
          error: (err: any) => {
            this.spinnerService.hide();
            this.errorFlag = true;
            this.showErrorMessage = true;
            this.fillErrorText = this.errMsgText;
          },
        });
    }
  }

  onDateOptionSelect(index: number) {
    this.selectedDateButtonIndex = index;
    if (index === 0) {
      this.periodForValue = 'createdOn';
      this.period = '';
      this.selectedFilterPeriod = null;
    } else {
      this.period = '';
      this.periodForValue = 'modifiedOn';
      this.selectedFilterPeriod = null;
    }
    this.isPeriodDisabled = false;
  }

  buildForm() {
    this.dataRequestForm = this.fb.group({
      searchText: new FormControl(this.searchValue),
      type: new FormArray([]),
      periodFor: new FormControl(this.periodForValue),
      period: new FormControl(this.period),
      status: new FormArray([]),
    });
  }

  getSearchText(e: any) {
    this.searchValue = e.target.value;
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('id') !== undefined
    ) {
      this.onAdvancedFilter();
    } else {
      this.filterTable();
      this.getUpdatedTiles();
    }
  }

  filterTable() {
    this.filterData = this.filterData.filter(
      (item: any) =>
        item.requestId.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.productType
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        item.labelName.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  onSort(column: string) {
    this.sortColumn = column;
    if (this.sortColumn === 'request_created_date') {
      this.isAscendingCreated = !this.isAscendingCreated;
      this.sortByValue = 'request_created_date';
      if (this.isAscendingCreated === true) {
        this.sortTypeValue = 'desc';
      } else {
        this.sortTypeValue = 'asc';
      }
    } else {
      this.isAscendingCompleted = !this.isAscendingCompleted;
      this.sortByValue = 'request_completed_date';
      if (this.isAscendingCompleted === true) {
        this.sortTypeValue = 'desc';
      } else {
        this.sortTypeValue = 'asc';
      }
    }
    this.onAdvancedFilter();
  }

  onAdvancedFilter() {
    this.isAdvancedFilter = true;
    this.isFilterRecords = false;
    this.spinnerService.show();
    if (this.searchValue === null) {
      this.searchValue = '';
    }
    const filterValues = {
      platformUserId: this.id.toString(),
      moduleId: this.moduleIds,
      statusId: this.statusIds,
      periodFor: this.periodForValue,
      period: this.period,
      searchText: this.searchValue,
      sortBy: this.sortByValue,
      sortType: this.sortTypeValue,
    };
    this.p = 1;
    this.dataRequestService.onRequestDetailsFilter(filterValues).subscribe({
      next: (res: any) => {
        // load tiles data
        this.statusTiles = res.result;
        this.statusTileData = this.statusTiles[0].statusSummery;
        this.statusTilesData = [...this.statusTileData];    
        // filter data load
        this.spinnerService.hide();
        this.files = res.result;
        this.fileData = this.files[0].results;
        this.filterData = [...this.fileData];
        this.totalItems = this.files[0].totalRows;
        this.isLoadData = true;
        this.errorFlag = false;
      },
      error: (err: any) => {
        this.spinnerService.hide();
        this.errorFlag = true;
        this.showErrorMessage = true;
        this.fillErrorText = this.errMsgText;
      },
    });
  }
  onTypeSelect(item: any) {
    if (this.getAuthStatus) {
      const control = new FormControl(item.id);
      (<FormArray>this.dataRequestForm.get('type')).push(control);
      this.dataRequestForm.patchValue({
        type: this.selectedFilterType.id,
      });
      let data = this.selectedFilterType;
      this.moduleIds = data.map((item: any) => {
        return item.id;
      });
      this.onAdvancedFilter();
    } else {
      this.selectedModuleName.push(item.moduleName);
      this.filterTableOnTypes();
    }
  }

  filterTableOnTypes() {
    this.filterData = this.fileData.filter((record: any) =>
      this.selectedModuleName.includes(record.productType)
    );
    this.getUpdatedTiles();
  }

  getUpdatedTiles() {
    for (let data of this.statusTileData) {
      data.statusId = this.getCount(data.statusName);
    }    
  }

  getCount(character: any) {
    return this.filterData.filter((obj: any) => obj.statusName === character)
      .length;
  }

  onTypeDeselect(item: any) {
    if (this.getAuthStatus) {
      let i = 0;
      (<FormArray>this.dataRequestForm.get('type')).controls.forEach(
        (l: any) => {
          if (l.value == item.id) {
            (<FormArray>this.dataRequestForm.get('type')).removeAt(i);
            this.moduleIds.splice(i, 1);
            return;
          }
          i++;
        }
      );
      this.onAdvancedFilter();
    } else {
      this.selectedModuleName = this.selectedModuleName.filter(
        (typeName) => typeName !== item.moduleName
      );
      if (this.selectedModuleName.length === 0) {
        this.tilesCalledBeforeLogin();
        this.onInitialDataLoadBeforeLogin();
        this.dataRequestForm.get('searchText')?.reset();
        this.searchValue = '';
        this.dataRequestForm.patchValue({
          searchText: '',
        });
      } else {
        this.filterTableOnTypes();
      }
    }
  }
  onSelectAllTypes(items: any) {
    if (this.getAuthStatus) {
      (<FormArray>this.dataRequestForm.get('type')).clear();
      this.moduleIds = [];
      for (let key of items) {
        const controls = new FormControl(key.id);
        (<FormArray>this.dataRequestForm.get('type')).push(controls);
        this.moduleIds.push(key.id);
      }
      this.onAdvancedFilter();
    } else {
      this.selectedModuleName = [];
      for (let key of items) {
        const selectedModuleNameKey = key.moduleName;
        this.selectedModuleName.push(selectedModuleNameKey);
      }
      this.filterData = this.fileData.filter((record: any) =>
        this.selectedModuleName.includes(record.productType)
      );
      this.getUpdatedTiles();
    }
  }
  onDeSelectAllTypes() {
    if (this.getAuthStatus) {
      (<FormArray>this.dataRequestForm.get('type')).clear();
      this.moduleIds = [];
      this.onAdvancedFilter();
    } else {
      this.selectedModuleName = [];
      if (this.selectedModuleName.length === 0) {
        this.tilesCalledBeforeLogin();
        this.onInitialDataLoadBeforeLogin();
      }
    }
  }

  onPeriodSelect(item: any) {
    this.period = item.period_name;
    this.onAdvancedFilter();
  }

  onPeriodDeselect(item: any) {
    this.period = '';
    this.onAdvancedFilter();
  }

  onTileSelect(index: any, tName: any, indexTile: number) {
    this.tileNameValue = tName;
    this.selectedTileIndex = indexTile;
    this.statusIds = [];
    this.statusIds.push(index);
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('id') !== undefined
    ) {
      this.selectedTileIndex=0;
      this.onAdvancedFilter();
    } else {
      this.filterTableOnTilesClickBeforeLogin();
      this.getUpdatedTiles();
    }
  }

  filterTableOnTilesClickBeforeLogin() {
    this.filterData = this.fileData.filter((item: any) =>
      item.statusName.toLowerCase().includes(this.tileNameValue.toLowerCase())
    );
  }

  onReset() {
    this.isAdvancedFilter = false;
    this.isFilterRecords = false;
    this.dataRequestForm.get('searchText')?.reset();
    this.searchValue = '';
    this.dataRequestForm.patchValue({
      searchText: '',
    });
    this.dataRequestForm.get('periodFor')?.reset();
    this.dataRequestForm.get('period')?.reset();
    (<FormArray>this.dataRequestForm.get('type')).clear();
    (<FormArray>this.dataRequestForm.get('status')).clear();
    this.selectedFilterPeriod = [];
    this.selectedFilterType = [];
    this.selectedFilters = [];
    this.moduleIds = [];
    this.statusIds = [];
    this.isPeriodDisabled = true;
    this.periodForValue = '';
    this.selectedDateButtonIndex = null;
    this.errorFlag = false;
    this.isAscendingCreated = true;
    this.isAscendingCompleted = true;
    this.sortByValue = '';
    this.sortTypeValue = '';
    this.selectedModuleName = [];
    this.selectedTileIndex = null;
    if (
      !localStorage.getItem('token') &&
      localStorage.getItem('id') !== undefined
    ) {
      this.tilesCalledBeforeLogin();
      this.onInitialDataLoadBeforeLogin();
    } else {
      this.p = 1;
      this.onInitialDataLoad();
    }
  }

  onShowHideFilterBox() {
    if (!localStorage.getItem('token')) {
      this.isOpenOverlayScreen = true;
    }
    this.spinnerService.hide();
    this.isAdvancedFilter = false;
    this.isFilterRecords = false;
    this.bgColorStatus = true;
    this.isResetButtonVisible = !this.isResetButtonVisible;
    this.isShowAdvanceFilter = !this.isShowAdvanceFilter;
    if (this.isShowAdvanceFilter == true) {
      this.spinnerService.hide();
      this.dataRequestForm.get('search')?.reset();
      this.dataRequestForm.get('search')?.disable();
      this.bgColorStatus = true;
      this.errorFlag = false;
      this.p = 1;
      if (localStorage.getItem('token')) {
        this.onInitialDataLoad();
      }
    } else {
      this.spinnerService.hide();
      this.dataRequestForm.get('search')?.enable();
      this.bgColorStatus = false;
      this.errorFlag = false;
      this.isOpenOverlayScreen = false;
      this.p = 1;
      if (localStorage.getItem('token')) {
        this.onInitialDataLoad();
      }
    }
  }
  openPopup(
    status: any,
    requestId: any,
    itemLabel: any,
    itemFileName: any,
    moduleId: any,
    requestCompletedDate: any
  ) {
    this.getModuleIdForDownload = moduleId;
    if (status == true) {
      this.getRequestId = requestId;
      this.downloadFileLabel = itemLabel;
      this.downloadFileName = itemFileName;
      this.requestCompletedDate = requestCompletedDate;
      this.displayStyle = 'block';
      this.isDownloadPopupVisibale = true;
    } else if (status == false) {
      this.displayStyle = 'none';
      this.isDownloadPopupVisibale = false;
    }
  }
  closePopup() {
    this.displayStyle = 'none';
    this.isDownloadPopupVisibale = false;
    this.getDownloadProductType = '';
  }
  closeOverlayPopup() {
    this.isShowAdvanceFilter = false;
    this.isOpenOverlayScreen = false;
    this.dataRequestForm.get('search')?.enable();
    this.bgColorStatus = false;
    this.isResetButtonVisible = true;
  }

  onDownload(downloadFileName: any) {
    if (!localStorage.getItem('token')) {
      this.isOpenOverlayScreen = true;
      return;
    }
    this.spinnerService.show();

    const downloadBody = {
      userId: this.id.toString(),
      fileName: downloadFileName,
      capability: this.getDownloadProductType,
      cluster: 'Dmint-Platform',
    };

    this.dataRequestService.onDownloadCall(downloadBody, this.id).subscribe({
      next: (response: any) => {
        this.spinnerService.hide();
        const result = response.result;
        const trimResult = result.slice(0, -4);
        this.downloadUrl = atob(trimResult);
        this.sendFileToDownload(this.downloadUrl);
      },
      error: (err) => {
        this.spinnerService.hide();
      },
    });
  }

  sendFileToDownload(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url.split('/').pop() || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onCloseHandler() {
    if (this.errMsgPopup.includes('Session Expired')) {
      localStorage.clear();
      this.auth.logout();
    }
    this.errMsgPopup = null;
    this.showErrorMessageWithPopup = false;
  }
  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }
  onCreateNewRequest() {
    this.isShowNewRequest = true;
    this.loadAssignCapabilities();
  }

  loadAssignCapabilities() {
    this.homePageService.onHeader(this.id).subscribe((res) => {
      const capabilitiesModules = this.getCapabilitiesModules(res.result);
      const subsModules = this.getSubscribedModules(capabilitiesModules);
      this.modulesResult = subsModules;
      if(this.modulesResult.length==0){
        this.noSubscriptionsFlag=true;
      }else{
        this.noSubscriptionsFlag=false;
      }
    });
  }

  private getCapabilitiesModules(results: any[]): any[] {
    for (const result of results) {
      if (result.headerName === 'Capabilities') {
        return result.modules;
      }
    }
    return [];
  }

  private getSubscribedModules(modules: any[]): any[] {
    return modules.filter((module) => module.subscription === true);
  }

  goToLink(moduleLink: string) {
    const token = localStorage.getItem('token');
    /* let url;
    if (moduleLink.includes('pairing')) url = 'http://localhost:60522';
    else if (moduleLink.includes('quest')) url = 'http://localhost:60523';

    window.open(`${url}?token=${token}`, '_blank'); */
    window.open(`${moduleLink}?token=${token}`, '_blank');
  }
  newRequestClose() {
    this.isShowNewRequest = false;
  }

  onKebabMenuClick(index: any, productType: string) {
    this.getDownloadProductType = productType;
    if (this.selectedRowId === index) {
      this.selectedRowId = null;
    } else {
      this.selectedRowId = index;
    }
  }

  onDeletePopUpCall(requestId: string) {
    this.storeRequestId = requestId;
    this.isDownloadPopupVisible = true;
  }

  onDeleteRequest(requestId: string) {
    if (!localStorage.getItem('token')) {
      this.isOpenOverlayScreen = true;
      return;
    }
    this.spinnerService.show();
    this.dataRequestService.onDeleteRequestCall(this.id, requestId).subscribe({
      next: (res: any) => {
        this.spinnerService.hide();
        this.responseResult = res.result;
        this.isDownloadPopupVisible = false;
        this.isOpenOverlayScreen = false;
        this.flagError = false;
        //this.tilesCalled();
        this.onInitialDataLoad();
        setTimeout(() => {
          this.responseResult = null;
        }, 3000);
      },
      error: (err) => {
        this.flagError = true;
      },
    });
  }

  onClosePopup() {
    this.isDownloadPopupVisible = false;
    this.storeRequestId = '';
  }

  onEdit(type: string, requestId: any) {
    const rId = requestId;
    const dynamicDraftId = rId.split('-')[1];      
    for (let i = 0; i <= this.getCapabilitiesModulesForLink.length; i++) {
      if (type === this.getCapabilitiesModulesForLink[i].moduleName) {
        const token = localStorage.getItem('token');
        const url = this.getCapabilitiesModulesForLink[i].moduleLink;
        // const url="http://localhost:60523";
        let dynamicPathValue = 'jobrequest';                                    
        window.open(`${url}/${dynamicPathValue}/${dynamicDraftId}?token=${token}`, '_blank');
      }
    }
  }
  
  onRerun(item: any, requestId: any){  
       this.dataRequestService.reRunRequest(requestId).subscribe({  
        next: (res: any) => {
          this.spinnerService.hide();
          if (res.code !== 200) {
            const errorResult = res.result;
            this.errMsgText = errorResult.message;     
            this.onInitialDataLoad();     
            setTimeout(() => {
              this.errMsgText = null;              
            }, 3000);
          } else {
            const response = res;
            this.responseResult = response.result.message; 
             this.utilService.goToTop(); 
             this.p=1;  
             this.onInitialDataLoad();  
            setTimeout(() => {
              this.responseResult = null;                                     
            }, 3000);
          }
        },
        error: (err) => {                                                                       
          this.spinnerService.hide();
        },                                                      
       });
  }


  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.kebab_menu')) {
      this.selectedRowId = null;
    }
  }
  checkCapabiltyDateStatus(item: any) {
    if (item.productType == 'Data Quest') {
      if (moment(this.fixedDate).diff(moment(item.requestCreatedDate).format('MM/DD/YYYY'), 'days') > 0) {
        return true;
      } else {
        if (item.statusName == 'FAILED' || item.statusName == 'COMPLETED' || item.statusName == 'RE-RUN') {
          return false;
        } else {
          return true
        }
      }
    } else {
      return true;
    }
  }
}
