import { Component, OnInit } from '@angular/core';
import { CapabilitiesService } from './capabilities.service';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/helper/util.service';

@Component({
  selector: 'app-capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.scss'],
})
export class CapabilitiesComponent implements OnInit {
  capabilitiesModule: any;
  public capabilitiesData: any;
  capabilitiesModuleRecords: any = [];
  token: any;
  getCapabilitiesModules: any = [];
  isViewMoreVisible: boolean = false;
  getModuleDatabyId: any;

  viewMode = 'tab1';

  showErrorMessage: any;
  showErrorMessageWithPopup: any = false;
  errMsg: any;
  userId: any;
  subscriptionMsg =
    'Please contact DMINT Administrator to get subscription of the same';
  closeSign: string;
  moduleByteMap: any;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private homePageService: HomePageService,
    private spinnerService: NgxSpinnerService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.utilService.goToTop();

    this.utilService.moduleBytes.subscribe((mod) => {
      this.moduleByteMap = mod as Object;
    });

    this.utilService.getUserId.subscribe((userId: any) => {
      this.userId = userId;

      // form header api call
      this.homePageService.onHeader(this.userId).subscribe((res) => {
        this.spinnerService.hide();
        this.capabilitiesModule = res;
        
        if (this.capabilitiesModule.message === 'OK') {
          this.capabilitiesData = this.capabilitiesModule.result;
          this.capabilitiesModuleRecords = [...this.capabilitiesData];
          for (let modulerecord of this.capabilitiesModuleRecords) {
            if (modulerecord.headerName == 'Capabilities') {
              this.getCapabilitiesModules = modulerecord.modules;              
            }
          }
        }
      });
    });

    //getting errors block
    this.utilService.SharingDataPopUpMessage.subscribe((res: any) => {
      this.spinnerService.hide();
      this.errMsg = res;
    });
    this.utilService.getPopupStatus.subscribe((res: any) => {
      this.spinnerService.hide();

      if (this.errMsg !== null) {
        this.showErrorMessageWithPopup = res;
      }
    });
  }

  viewMore(moduleId: any) {
    for (let module of this.getCapabilitiesModules) {
      if (moduleId === module.id) {
        this.getModuleDatabyId = module;
        this.isViewMoreVisible = true;
      }
    }
  }

  onViewMoreClose() {
    this.isViewMoreVisible = false;
  }

  backToAllCapabilities() {
    this.viewMode = 'tab1';
  }

  onPairingApicall(moduleLink: any) {
    this.spinnerService.hide();
    const token = localStorage.getItem('token');

    /*let url;
    if (moduleLink.includes('pairing')) url = 'http://localhost:60522';
    else if (moduleLink.includes('quest')) url = 'http://localhost:60523';

    window.open(`${url}?token=${token}`, '_blank'); */

    window.open(`${moduleLink}?token=${token}`, '_blank');
  }

  onSubscribe() {
    this.errMsg = this.subscriptionMsg;
    this.closeSign = 'OK';
    this.showErrorMessageWithPopup = true;
  }

  onCloseHandler() {
    this.errMsg = null;
    this.showErrorMessageWithPopup = false;
  }

  onSignIn() {
    this.utilService.onSignIn();
  }
}
