import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { ApiUrlService } from 'src/app/shared/services/url.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
  activeUserGroup = new BehaviorSubject<any>([])
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) { }
  apiUrl = this.apiUrlService.getApiUrl();
  validateEmail(payload: any) {
    return this.http.post(`${this.apiUrl}/v1/checkUserEmail`, payload);
  }
  getSubscriptionDetails(payload: any) {
    return this.http.post(`${this.apiUrl}/v1/subscriptionDetails`, payload);
  }
  fetchModulesAndGroups(userId: any) {
    const headers = new HttpHeaders({
      userId: userId.toString(),
    })
    return this.http.post(`${environment.apiUrl}/v1/headers`, null, {
      headers: headers,
    });
  }
  createUser(payload: any) {
    return this.http.post(`${this.apiUrl}/v1/createUser`, payload);
  }
  checkEmailExists(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/checkUserEmail`, {
      "email": email
    }).pipe(
      map((response: any) => {
        if (response.result.emailExists) {
          return { emailExists: true };
        }
        return null;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
