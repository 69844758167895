<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<ng-container *ngIf="userType=='Subscription Admin'" >
  <div class="mainCardSub">
    <div class="pageNameANDCTA">
        <div class="pageName">
            <img src="../../../../assets/images/manageSubs/hand-holding-dollar-solid.svg"
                alt="manage-subscriptions-icon">
            <h1>Manage subscription</h1>
        </div>
    </div>
    <div class="subsDetails">
        <p class="label">
            Customer Name
        </p>
        <p class="value">
            {{filterData?.[0]?.customerName}}
        </p>
        <p class="label">
            Contact Name
        </p>
        <p class="value">
          {{filterData?.[0]?.contactName}}
        </p>
    </div>
    <form [formGroup]="subscriptionForm">
      <div class="filtersCont">
        <div class="sectionName">
          <img src="../../../../assets/images/manageSubs/Filters.svg" alt="filters-icon">
          Filter
        </div>
        <div class="cstm-tggl-cont" style="margin-right: -1rem">
          <p class="label" style="opacity: 0">Type</p>
          <label class="fxSwitch">
            <span class="toggle filters" id="tglType">
              <ng-container *ngFor="let dateFilterOption of dateFilterOptions; let i = index">
                <span class="tgl-btn" [class.active]="selectedDateButtonIndex === i" id="starts"
                  (click)="onDateOptionSelect(i)" onKeyPress="">
                  {{ dateFilterOption | uppercase }}</span>
              </ng-container>
            </span>
          </label>
        </div>
        <div class="dropdown_div">
          <div class="title">Period</div>
          <div class="dropdown">
            <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="periodFilterDropdownSettings"
              [(ngModel)]="selectedFilterPeriod" formControlName="period" [data]="periods"
              (onSelect)="onPeriodSelect($event)" (onDeSelect)="onPeriodDeselect($event)" [disabled]="isPeriodDisabled">
              {{ periods }}
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="dropdown_div">
          <div class="title">Status</div>
          <div class="dropdown">
            <ng-multiselect-dropdown 
            [data]="status" 
            [placeholder]="'Select'" 
            [settings]="statusFilterDropdownSettings" 
            [(ngModel)]="selectedStatusType" 
            [ngModelOptions]="{ standalone: true }" 
            (onSelect)="onStatusSelect($event)"
            (onDeSelect)="onStatusDeselect($event)" 
            (onSelectAll)="onSelectAllStatus($event)" 
            (onDeSelectAll)="onDeSelectAllStatus()"
            >
              {{ status }}
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="reset_div">
          <button type="reset" (click)="onReset()">Reset</button>
        </div>
      </div>
    </form>
      <div class="appliedFilters" *ngIf="activeStatus.length>0 || activePeriod.length>0 ">
        <h5>
          Applied Filters:
        </h5>
        <div class="activeFilters">
          <div class="filterSet" *ngFor="let item of activePeriod;let i=index">
            Period:
            <p>{{item.period_text}}</p>
            <img src="../../../../assets/images/manageSubs/Close.svg" alt="" (click)="removePeriod(i,item)">
          </div>
          <div class="filterSet" *ngFor="let item of activeStatus;let i=index">
            Status:
            <p>{{item.statusLabel}}</p>
            <img src="../../../../assets/images/manageSubs/Close.svg" alt="" (click)="removeStatus(i,item)">
          </div>
        </div>
      </div>
      <form [formGroup]="seacrchForm">
      <div class="tableCont">
        <div class="tableItemsCounter">
          <ng-container *ngIf="totalItems; else noResult">
            <div class="list_view">
              Showing {{ (p - 1) * itemsPerPage + 1 }} -
              {{ p * itemsPerPage < totalItems ? p * itemsPerPage : totalItems }} of {{ totalItems }} results </div>
          </ng-container>
    
          <ng-template #noResult class="list_view">Showing 0 of 0 results</ng-template>
          
            <div class="srchinTable">
              <img src="../../../../assets/images/search_icon.svg" alt="search-icon" />
              <input type="text" placeholder="Search by Subscription" name="search" formControlName="searchText"
                (change)="getSearchText($event)" [(ngModel)]="searchValue" [readOnly]="false" class="filters_search_input"
                [ngClass]="bgColorStatus ? 'bgColorSet' : ''" />
            </div>
          
        </div>
        <div class="subsTable">
          <div class="thead">
            <div class="th">
              Subscription
            </div>
            <div class="th sort1">
              Starts on
              <span class="down-arrow-icon" (click)="onSort('start_date')" onKeyPress=""
                [class.rotate]="isAscendingCreated === false"><img src="../../../../assets/images//chevron-down.svg"
                  alt="chevron_icon" /></span>
            </div>
            <div class="th sort1">
              Ends on
              <span class="down-arrow-icon" (click)="onSort('end_date')" onKeyPress=""
                [class.rotate]="isAscendingCompleted === false"><img src="../../../../assets/images//chevron-down.svg"
                  alt="chevron_icon" /></span>
            </div>
            <div class="th">
              Status
            </div>
            <div class="th">
              Actions
            </div>
          </div>
          <ng-container *ngIf="totalItems; else noData">
            <div class="tbody">
              <div class="trow" *ngFor="
                                let i = index;
                                let item;
                                of: filterData
                                  | paginate
                                    : {
                                        id: 'listing_pagination',
                                        itemsPerPage: itemsPerPage,
                                        currentPage: p,
                                        totalItems: totalItems
                                      }
                              ">
                <div class="tr">{{ item.subscriptionName }}</div>
                <div class="tr">{{ item.startsOn }}</div>
                <div class="tr">{{ item.endsOn }}</div>
                <div class="tr">
                  <div class="chip" [ngClass]="
                                    item.statusName === 'ACTIVE'
                                      ? 'active'
                                      : '' || item.statusName === 'INACTIVE'
                                      ? 'inactive'
                                      : '' || item.statusName === 'DRAFT'
                                      ? 'draft'
                                      : ''
                                  ">
                    {{ item.statusName | titlecase }}
                  </div>
                </div>
                <div class="tr">
                  <img *ngIf="item.statusName=='DELETED' || item.statusName=='INACTIVE'" src="../../../../assets/images/manageSubs/edit.svg" alt="" class="kebab_menu disable">
                  <img *ngIf="item.statusName=='ACTIVE'" (click)="onEdit(item.subscriptionId)"
                    src="../../../../assets/images/manageSubs/edit.svg" alt="" class="kebab_menu">
                </div>
              </div>
              <div class="row pagination-block">
                <pagination-controls id="listing_pagination" (pageChange)="getPage($event)" previousLabel="Prev"
                  nextLabel="Next">
                </pagination-controls>
              </div>
            </div>
          </ng-container>
    
          <ng-template #noData>
            <div class="errorBlock">
              <img src="../../../../assets/images/artboard-3.png" alt="artboard_icon" />
              <p>{{ errorMessage }}</p>
            </div>
          </ng-template>
        </div>
      </div>
</form>
</div>
</ng-container>
<ng-container *ngIf="userType=='DMINT Admin'">
<div class="mainCard">
  <div class="pageNameANDCTA">
    <div class="pageName">
      <img
        src="../../../../assets/images/hand-holding-dollar-solid.svg"
        alt="manage-subscriptions-icon"
      />
      <h1>Manage Subscriptions</h1>
    </div>
    <button
      type="button"
      class="primary_green_btn"
      [disabled]="false"
      *ngIf="userType == 'DMINT Admin'"
      (click)="onCreateNew()"
    >
      Create New
    </button>
  </div>

  <form [formGroup]="subscriptionForm">
    <div class="filtersCont">
      <div class="sectionName">
        <img src="../../../../assets/images/Filters.svg" alt="filters-icon" />
        Filter
      </div>

      <div class="customer_div dropdown_div">
        <div class="title">Customer Name</div>
        <div class="dropdown">
          <ng-multiselect-dropdown
            [placeholder]="'Select'"
            [settings]="customerFilterDropdownSettings"
            [(ngModel)]="selectedCustomerType"
            formArrayName="customer"
            [ngModelOptions]="{ standalone: true }"
            [data]="customers"
            (onSelect)="onCustomerSelect($event)"
            (onDeSelect)="onCustomerDeselect($event)"
            (onSelectAll)="onSelectAllCustomers($event)"
            (onDeSelectAll)="onDeSelectAllCustomers()"
          >
            {{ customers }}
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="cstm-tggl-cont" style="margin-right: -1rem">
        <p class="label" style="opacity: 0">Type</p>
        <label class="fxSwitch">
          <span class="toggle filters" id="tglType">
            <ng-container
              *ngFor="let dateFilterOption of dateFilterOptions; let i = index"
            >
              <span
                class="tgl-btn"
                [class.active]="selectedDateButtonIndex === i"
                id="starts"
                (click)="onDateOptionSelect(i)"
                onKeyPress=""
              >
                {{ dateFilterOption | uppercase }}</span
              >
            </ng-container>
          </span>
        </label>
      </div>

      <div class="type_div dropdown_div">
        <div class="title">Period</div>
        <div class="dropdown">
          <ng-multiselect-dropdown
            [placeholder]="'Select'"
            [settings]="periodFilterDropdownSettings"
            [(ngModel)]="selectedFilterPeriod"
            formControlName="period"
            [data]="periods"
            (onSelect)="onPeriodSelect($event)"
            (onDeSelect)="onPeriodDeselect($event)"
            [disabled]="isPeriodDisabled"
          >
            {{ periods }}
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="customer_div dropdown_div">
        <div class="title">Status</div>
        <div class="dropdown">
          <ng-multiselect-dropdown
            [placeholder]="'Select'"
            [settings]="statusFilterDropdownSettings"
            [(ngModel)]="selectedStatusType"
            formArrayName="status"
            [ngModelOptions]="{ standalone: true }"
            [data]="status"
            (onSelect)="onStatusSelect($event)"
            (onDeSelect)="onStatusDeselect($event)"
            (onSelectAll)="onSelectAllStatus($event)"
            (onDeSelectAll)="onDeSelectAllStatus()"
          >
            {{ status }}
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="reset_div">
        <button type="reset" (click)="onReset()">Reset</button>
      </div>
    </div>

    <div class="tableItemsCounter">
      <ng-container *ngIf="totalItems; else noResult">
        <div class="list_view">
          Showing {{ (p - 1) * itemsPerPage + 1 }} -
          {{ p * itemsPerPage < totalItems ? p * itemsPerPage : totalItems }} of
          {{ totalItems }} results
        </div>
      </ng-container>

      <ng-template #noResult class="list_view"
        >Showing 0 of 0 results</ng-template
      >

      <div class="srchinTable">
        <img
          src="../../../../assets/images/search_icon.svg"
          alt="search-icon"
        />
        <input
          type="text"
          placeholder="Search by Subscription & Contact Name"
          name="search"
          formControlName="searchText"
          (change)="getSearchText($event)"
          [(ngModel)]="searchValue"
          [readOnly]="false"
          class="filters_search_input"
          [ngClass]="bgColorStatus ? 'bgColorSet' : ''"
        />
      </div>
    </div>
  </form>

  <div class="tableCont">
    <div class="subsTable">
      <div class="thead">
        <div class="th">Subscription</div>
        <div class="th">Contact Name</div>
        <div class="th">Customer</div>
        <div class="th sort1">
          Starts on
          <span
            class="down-arrow-icon"
            (click)="onSort('start_date')"
            onKeyPress=""
            [class.rotate]="isAscendingCreated === false"
            ><img
              src="../../../../assets/images//chevron-down.svg"
              alt="chevron_icon"
          /></span>
        </div>
        <div class="th sort1">
          Ends on
          <span
            class="down-arrow-icon"
            (click)="onSort('end_date')"
            onKeyPress=""
            [class.rotate]="isAscendingCompleted === false"
            ><img
              src="../../../../assets/images//chevron-down.svg"
              alt="chevron_icon"
          /></span>
        </div>
        <div class="th">Status</div>
        <div class="th">Actions</div>
      </div>
      <ng-container *ngIf="totalItems; else noData">
        <div class="tbody">
          <div
            class="trow"
            *ngFor="
              let i = index;
              let item;
              of: filterData
                | paginate
                  : {
                      id: 'listing_pagination',
                      itemsPerPage: itemsPerPage,
                      currentPage: p,
                      totalItems: totalItems
                    }
            "
          >
            <div class="tr">{{ item.subscriptionName }}</div>
            <div class="tr custom-name-block" title="{{ item.contactName }}">
              {{ item.contactName }}
            </div>
            <div class="tr">{{ item.customerName }}</div>
            <div class="tr">{{ item.startsOn }}</div>
            <div class="tr">{{ item.endsOn }}</div>
            <div class="tr">
              <div
                class="chip"
                [ngClass]="
                  item.statusName === 'ACTIVE'
                    ? 'active'
                    : '' || item.statusName === 'INACTIVE'
                    ? 'inactive'
                    : '' || item.statusName === 'DRAFT'
                    ? 'draft'
                    : ''
                "
              >
                {{ item.statusName | titlecase }}
              </div>
            </div>
            <div class="tr">
              <img
                src="../../../../assets/images/kebab_default.svg"
                alt="kebab_menu_icon"
                (click)="onKebabMenuClick(i)"
                onKeyPress=""
                class="kebab_menu"
              />
              <div class="kebab_options" *ngIf="i === selectedRowId">
                <button
                  class="optionTile"
                  (click)="onEdit(item.subscriptionId)"
                  [disabled]="item.statusName === 'DELETED' || item.statusName === 'INACTIVE'"
                >
                  <img
                    src="../../../../assets/images/Edit.svg"
                    alt="edit_icon"/>
                  View/Edit
                </button>
                <button
                  class="optionTile"
                  *ngIf="
                    userType === 'DMINT Admin' && item.statusName === 'ACTIVE'
                  "
                  [disabled]="
                    item.statusName === 'INACTIVE' ||
                    item.statusName === 'DELETED'
                  "
                  (click)="
                    onDeactivate(
                      item.subscriptionName,
                      item.subscriptionId,
                      item.statusName
                    )
                  "
                  onKeyPress=""
                >
                  <img
                    src="../../../../assets/images/deactivate.svg"
                    alt="deactivate_icon"
                  />
                  Deactivate
                </button>
                <button
                  class="optionTile colorActivate"
                  *ngIf="
                    userType === 'DMINT Admin' && item.statusName === 'INACTIVE'
                  "
                  (click)="
                    onDeactivate(
                      item.subscriptionName,
                      item.subscriptionId,
                      item.statusName
                    )
                  "
                  onKeyPress=""
                >
                  <img
                    src="../../../../assets/images/activate.svg"
                    alt="activate_icon"
                  />
                  Activate
                </button>
                <button
                  class="optionTile delete"
                  *ngIf="userType === 'DMINT Admin'"
                  (click)="onDelete(item.subscriptionName, item.subscriptionId)"
                  [disabled]="item.statusName === 'DELETED'"
                >
                  <img
                    src="../../../../assets/images/delete.svg"
                    alt="delete_icon"
                  />
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="row pagination-block">
            <pagination-controls
              id="listing_pagination"
              (pageChange)="getPage($event)"
              previousLabel="Prev"
              nextLabel="Next"
            >
            </pagination-controls>
          </div>
        </div>
      </ng-container>

      <ng-template #noData>
        <div class="errorBlock">
          <img
            src="../../../../assets/images/artboard-3.png"
            alt="artboard_icon"
          />
          <p>{{ errorMessage }}</p>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- Delete Modal window container starts -->
  <div class="modal modal-backdrop" *ngIf="isDeletePopupVisible">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Are you sure?</div>
          <div (click)="onCloseDelete()" onKeyPress="">
            <img
              src="../../../../assets/images/close_icon.svg"
              class="close_icon"
              width="16px"
              height="16px"
              alt="close_icon"
            />
          </div>
        </div>
        <div class="modal-body">
          <p>
            You are about to delete <strong>{{ recSubscriptionName }}</strong
            >. Once deleted, it cannot be recovered. <br /><br />
            Do you want to proceed?
          </p>
        </div>
        <div class="modal-footer two_btn">
          <button
            type="button"
            class="secondary-filled-btn"
            (click)="onCloseDelete()"
          >
            No
          </button>
          <button
            type="button"
            class="primary_green_btn red"
            (click)="onDeleteConfirm()"
            [disabled]="deleteText === 'Deleting...'"
          >
            {{ deleteText }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Modal window container ends -->
  <!-- Delete Modal window container starts -->
  <div class="modal modal-backdrop" *ngIf="isDeactivatePopupVisible">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Are you sure?</div>
          <div (click)="onClose()" onKeyPress="">
            <img
              src="../../../../assets/images/close_icon.svg"
              class="close_icon"
              width="16px"
              height="16px"
              alt="icon"
            />
          </div>
        </div>
        <div class="modal-body">
          <p>
            You are about to
            {{ storeStatus === 'active' ? 'activate' : 'deactivate' }}
            <strong>{{ storeSubscriptionName }}</strong
            >. <br /><br />
            Do you want to proceed?
          </p>
        </div>
        <div class="modal-footer two_btn">
          <button
            type="button"
            class="secondary-filled-btn"
            (click)="onClose()"
          >
            No
          </button>
          <button
            type="button"
            class="primary_green_btn red"
            (click)="onDeactivateConfirm()"
          >
            {{ storeStatus === 'active' ? 'Activate' : 'Deactivate' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Modal window container ends -->
</div>
</ng-container>
<app-success-toast
  [message]="responseResult"
  *ngIf="responseResult"
></app-success-toast>
