import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from 'src/app/shared/services/url.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) { }
  apiUrl = this.apiUrlService.getApiUrl();
  getCustomersByUser(id: any) {
    return this.http.post(`${this.apiUrl}/v1/getCustomersByUser`, { id });
  }
  getAllStatuses(statusTypeId: any) {
    return this.http.post(`${this.apiUrl}/v1/getAllStatuses`, { statusTypeId });
  }
  getUserTypes() {
    return this.http.get(`${this.apiUrl}/v1/getUserTypes`);
  }
  getAllUsers(payload: any, limit = 10, page = 1) {
    return this.http.post(
      `${this.apiUrl}/v1/fetchUsersList?limit=${limit}&page=${page}`,
      payload
    );
  }
  activateUser(payload: any) {
    return this.http.post(
      `${this.apiUrl}/v1/activateDeactivateUser`,
      payload
    );
  }
  deleteUser(payload: any) {
    return this.http.post(
      `${this.apiUrl}/v1/deleteUser`,
      payload
    );
  }

}
