<div class="background_radial1"></div>
<div class="background_radial2"></div>
<div class="main_container">
  <div class="container card">
    <div class="hero_section">
      <div class="welcome_section">
        <div class="title_div">
          <div class="title">Welcome To</div>
          <div class="title_lg">Dmint Platform</div>
        </div>
        <div class="subtext_div">
          <div>
            Introducing our cutting-edge Data Market Intelligence (DMINT)
            Platform, a meticulously crafted solution designed to elevate your
            understanding of the tire industry and drive your business decisions
            with unparalleled precision.
          </div>
          <div>
            At the heart of our platform lies an expansive array of offerings.
            Immerse yourself in our Data Products, a treasure trove of
            meticulously curated information that spans the entire tire
            ecosystem. From production statistics to consumer preferences, our
            data is a powerful asset that forms the bedrock of informed
            decision-making.
          </div>
        </div>
      </div>
    </div>
    <div class="solutions_div">
      <div class="title">Our solutions</div>
      <div class="solution_inner_div">
        <ng-container>
          <div class="row" *ngFor="let homePageItem of homepageRecords">
            <div class="col-5">              
              <img
              [src]="moduleByteMap[homePageItem.headerName+'_'+homePageItem.headerImagePath] | base64: 'image/png'"
                width="350px"
                height="400px"
                alt="category"
                class="graphics"
              />
            </div>
            <div class="col-7 solution_category">
              <div class="title">
                <span
                  ><img    
                    [src]="'data:image/svg+xml;base64,' + moduleByteMap[homePageItem.headerName+'_'+homePageItem.menuImagePath]"                                                                                                                                                                                                                                                                
                    width="30px"
                    height="30px"
                    alt="icon" /></span
                ><span>{{ homePageItem.headerLabel }}</span>
              </div>
              <div class="subtext">
                <div>
                  {{ homePageItem.headerShortDescription }}
                </div>
                <div>
                  {{ homePageItem.headerLongDescription }}
                </div>
              </div>
              <a
                class="border_green_btn"
                [routerLink]="homePageItem.headerLinkPath"
              >
                <span>go to {{ homePageItem.headerLabel }}</span
                ><span
                  ><img
                    src="../../../../assets/images/green_right_arrow.svg"
                    width="14px"
                    height="14px"
                    alt="icon"
                /></span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="background_radial3"></div>
<div class="background_radial4"></div>

<app-alert
  [message]="errMsg"
  *ngIf="showErrorMessageWithPopup"
  (close)="onCloseHandler()"
></app-alert>
