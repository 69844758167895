<ng-container>
    <div class="mainCont">
        <div class="content" [ngClass]="{'dynamic-after': showToastMessage || showSuccessToast, 'no-after': !showToastMessage || !showSuccessToast}">
            <div class="leftCol">
                <h1>
                    <img src="../../../../assets/images/dmint_color_logo.png" alt="logo"><br>
                    <span>Welcome to</span><br>
                    DMINT Platform
                </h1>
            </div>

            <div class="rightCol">
                <form [formGroup]="emailForm" (ngSubmit)="sendVerification()">
                    <div class="cardCont dontShow" *ngIf="!showVerificationSection">
                        <div class="pageName">
                            <h2>Confirm Email</h2>
                            <p class="subHeader">
                                Enter your email address to get a verification code.
                            </p>
                        </div>
                        <div class="formControl">
                            <p class="label required">
                                Email
                            </p>
                            
                                <input type="email" formControlName="email" placeholder="Enter your email address">
                            
                            <img src="../../../../assets/images/onboardingImages/Email.svg" alt="">
                        </div>

                        <button type="submit" class="primary-filled-btn" 
                            [disabled]="this.emailForm.status=='INVALID'">SEND RECOVERY CODE</button>
                    </div>
                </form>
                <form [formGroup]="verificationForm">
                    <div class="cardCont dontShow" *ngIf="showVerificationSection">
                        <div class="pageName">
                            <h2>Verify Email</h2>
                            <p class="subHeader">
                                Please enter the verification code sent to your email
                            </p>
                        </div>
                        <div class="formControl">
                            <p class="label required">
                                Verification Code 
                            </p>

                            <input type="text" formControlName="code" placeholder="Type here">
                            <p class="resendLink">{{codeExpText}}  
                                <span *ngIf="isShowTimer" class="resendLink" >{{timer}}</span>
                                <button *ngIf="!isShowTimer" class="dmintLink" (click)="resendCode()">Resend Code</button>
                            </p>
                        </div>
                        <button class="primary-filled-btn" (click)="verifyCode()"
                            [disabled]="this.verificationForm.status=='INVALID'">CONFIRM</button>
                    </div>
                </form>
                <div class="copyright"> © DMINT Copyright 2024. All Rights Reserved. </div>
            </div>
            <div class="verified toastMessage" *ngIf="showToastMessage" >
                <p class="message">
                    One step closer! Your email has been successfully verified.
                </p>
            </div>
            <div class="verified toastMessage" *ngIf="showSuccessToast">
                <p class="message">
                   {{successMsg}}
                </p>
            </div>
            <app-error-toast [message]="error" *ngIf="error && showErrorToast"></app-error-toast>
        </div>
    </div>
</ng-container>