import { Component, OnInit } from '@angular/core';
import { HomePageService } from '../../../shared/services/homepage.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  homepageRecords: any = [];
  showErrorMessageWithPopup: any = false;
  errMsg: any;
  userId: string;
  clearSubscription: Subscription;
  sasValue: string;
  moduleByteMap: any;
  allHeadersTab: any;
  sessionExpiry: any = 'Session Expired, Please click ok Button to re-login';

  constructor(
    private homePageService: HomePageService,
    private utilService: UtilService,
    private spinnerService: NgxSpinnerService,
    private auth: AuthService
  ) {
    this.utilService.goToTop();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.utilService.moduleBytes.subscribe(mod => {
      this.moduleByteMap = mod as Object;
    });

    this.utilService.getUserId.subscribe((userId: any) => {
      this.userId = userId;
    });


    this.utilService.allHeaders.subscribe((allHeaders) => {      
      this.allHeadersTab = [...allHeaders];

      if (allHeaders == null || allHeaders?.length == 0) {
        this.homePageService.onHeader(this.userId).subscribe((res: any) => {
          const homePageResult = res.result;
          this.allHeadersTab = [...homePageResult];
          this.homepageRecords = [];
          
          this.allHeadersTab.forEach((header: any) => {
            if (header.homePageItem) {
              this.homepageRecords.push(header);
            }
          });

          this.spinnerService.hide();
        });
      } else if (allHeaders?.length > 0) {
        this.homepageRecords = [];
        this.allHeadersTab.forEach((header: any) => {
          if (header.homePageItem) {
            this.homepageRecords.push(header);
          }
        });
      }

    });



    //getting errors block
    this.clearSubscription = this.utilService.SharingDataPopUpMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();        
        this.errMsg = res;

        this.clearSubscription = this.utilService.getPopupStatus.subscribe(
          (res1: any) => {    
            if (this.errMsg !== null) {
              this.showErrorMessageWithPopup = res1;
              this.errMsg = this.sessionExpiry;
            }
          }
        );
      }
    );

    this.clearSubscription = this.utilService.getPopupStatus.subscribe(
      (res: any) => {
        this.spinnerService.hide();

        if (this.errMsg !== null) {
          this.showErrorMessageWithPopup = res;
          this.errMsg = this.sessionExpiry;
        }
      }
    );
  }

  onCloseHandler() {
    if (this.errMsg === this.sessionExpiry
        || this.errMsg?.includes('Error')) {
      localStorage.clear();
      this.auth.logout();
    }
    this.errMsg = null;
    this.showErrorMessageWithPopup = false;
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }
}
