<ng-container *ngIf="isSubscriptionAdmin">
  <form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
    <div>
      <h1>User Management</h1>
      <div class="editUser contentCard">
        <div class="pageHead">
          <h3 class="card-title">Edit User</h3>
          <p class="pgNote">Please fill in the details below. Fields marked with <sup>*</sup> are mandatory.</p>
        </div>
        <div class="userDetailsCont">
          <h4>User Details</h4>
          <div class="formControl">
            <label for="firstName">
              First Name
            </label>
            <input readonly type="text" formControlName="firstname" id="firstName" class="formInput" placeholder="Type here..">
            <div *ngIf="firstname?.hasError('required') && firstname?.touched" class="errorText">First name is required
            </div>
            <div *ngIf="firstname?.hasError('minlength') && firstname?.touched" class="errorText">First name should be
              atleast 2
              characters long</div>
            <div *ngIf="firstname?.hasError('maxlength') && firstname?.touched" class="errorText">First name should not
              be more
              than
              50 characters</div>
            <div *ngIf="firstname?.hasError('pattern') && firstname?.touched" class="errorText">First name should only
              contain
              alphabetic characters</div>
          </div>
          <div class="formControl">
            <label for="lastName">
              Last Name
            </label>
            <input readonly type="text" formControlName="lastname" id="lastName" class="formInput" placeholder="Type here..">
            <div *ngIf="lastname?.hasError('required') && lastname?.touched" class="errorText">Last name is required
            </div>
            <div *ngIf="lastname?.hasError('minlength') && lastname?.touched" class="errorText">Last name should be
              atleast 2
              characters long</div>
            <div *ngIf="lastname?.hasError('maxlength') && lastname?.touched" class="errorText">Last name should not be
              more than
              50 characters</div>
            <div *ngIf="lastname?.hasError('pattern') && lastname?.touched" class="errorText">Last name should only
              contain
              alphabetic characters</div>
          </div>
          <div class="formControl">
            <label for="selectModules">
              Email
            </label>
            <input type="email" formControlName="email" id="selectModules" class="formInput" placeholder="Type here.." readonly>
            <div *ngIf="email?.hasError('required') && email?.touched" class="errorText">Email is required</div>
            <div *ngIf="email?.hasError('pattern') && email?.touched" class="errorText">Invalid email format</div>
            <div *ngIf="email?.hasError('emailExists') && email?.touched" class="errorText">This email is already in use
            </div>
            <!-- disabled -->
          </div>
          <div></div>
          <div class="formControl readOnly">
            <label for="user_customer">Customer</label>
            <input class="dontShow" type="text" placeholder="Type here">
            <p>{{userForm && userForm.get('cutomer')?.value}}</p>
          </div>
          <div class="formControl readOnly">
            <label for="user_type">User Type</label>
            <input class="dontShow" type="text" placeholder="Type here">
            <p>{{userForm && userForm.get('userType')?.value}}</p>
          </div>
        </div>
        <div class="subsDetailsCont">
          <div class="sectionHead">
            <h4>Subscription Details</h4>
            <span class="others dropdown-container">
              <button type="button" class="tertiaryBtn withDropdown" [disabled]="allActiveSubscriptions?.length==0"
                (click)="toggleSubscriptions=!toggleSubscriptions;this.moduleDropdownActiveId = null;">Add
                Subscription(s)
                <img [src]="greenChevronSouth" alt="chevron" class="chevron">
              </button>
              <div class="othersList" *ngIf="toggleSubscriptions && allActiveSubscriptions?.length>0">
                <div *ngFor="let subscription of allActiveSubscriptions;let i=index"
                  (click)="addSubscription(subscription)">
                  <p>{{subscription?.subscriptionName}}</p>
                </div>
              </div>
            </span>
          </div>
          <!-- {{subscription?.value[i].subscriptionUserId}} -->
          <ng-container>
            <div class="cardsCont" formArrayName="subscription">
              <ng-container *ngFor="let subscriptionItem of subscription.controls; let i = index">
                <ng-container *ngIf="subscription?.value[i].subscriptionUserId==userId">
                  <div class="card userxsubscriptionCard" [ngClass]="i>0?'m-15':null" [formGroupName]="i">
                    <div class="subsCardHead">
                      <div class="subscriptionName">
                        <h4>{{subscription?.value[i]?.subscriptionName}}</h4>
          
                        <img [src]="editIconBtn" alt="edit" class="editIconBtn dontShow">
                        <div class="subsMetaData">
                          <div class="status active"
                            [ngClass]="subscription?.value[i]?.statusName=='ACTIVE'?'status_active':null">
                            {{subscription?.value[i]?.statusName}}
                          </div>
                          <div class="textGroup">
                            <div class="textBlock">
                              <p class="label">Start Date:</p>
                              <p class="label black">{{subscription.value[i].startsOn}}</p>
                            </div>
                            <div class="textBlock">
                              <p class="label">End Date:</p>
                              <p class="label black">{{subscription.value[i].endsOn}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
          
                      <button type="button" class="deleteBtn" (click)="deleteSubscription(i,subscriptionItem)">
                        <img [src]="deleteIconBtn" alt="delete" class="deleteIconBtn">
                      </button>
                    </div>
                    <div class="subsCardBody">
                      <div class="modulesCont">
                        <p class="label">Selected Module(s)</p>
                        <div class="selectedModules">
                          <div class="selections">
                            <ng-container *ngIf="subscriptionItem?.value?.modules?.length>0">
                              <div class="pill" *ngFor="let moduleItem of getUserModules(subscriptionItem.value.modules);let indx=index"
                                [ngClass]="subscriptionItem?.value?.selectedModule?.id==moduleItem?.id?'selected':null"
                                (click)="selectModule(moduleItem,i,indx)">
                                {{moduleItem.moduleName}}
                                <div class="counter">
                                  {{userGroupCount(moduleItem.userGroups)}}
                                </div>
                                <ng-container>
                                  <img [src]="removeIconBtn" alt="remove" class="removeIconBtn"
                                    (click)="removeModule(moduleItem,i,indx)">
                                </ng-container>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="subscriptionItem?.value?.modules?.length==0">
                              <small>
                                No modules currenlty assigned for this subscription
                              </small>
                            </ng-container>
          
                          </div>
                          <span class="others dropdown-container">
                            <button type="button" class="tertiaryBtn withDropdown"
                              [disabled]="subscriptionItem?.value?.moduleList?.length==0 "
                              (click)="showModuleDropdown(subscriptionItem)">Add
                              Module(s)
                              <img [src]="greenChevronSouth" alt="chevron" class="chevron">
                            </button>
                            <div [ngClass]="subscriptionItem.value.moduleList.length>0?'othersList':null"
                              *ngIf="toggleModules && moduleDropdownActiveId==subscriptionItem.value.subscriptionId">
                              <div *ngFor="let module of subscriptionItem.value.moduleList;let idx=index"
                                (click)="addModules(module,i,idx)">
                                <p>{{module?.moduleName}}</p>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="UGCont">
                        <p class="label">Selected Users group(s)</p>
                        <div class="selectedUGs">
                          <div class="selections">
                            <small
                              *ngIf="subscription.value[i]?.selectedModule?.userGroups?.length==0 || subscription.value[i]?.selectedModule?.userGroups==null">
                              Select a module to assign user groups.
                            </small>
                            <ng-container *ngIf="subscription.value[i]?.selectedModule?.userGroups?.length>0">
                              <div class="pill" *ngFor="let group of getUserGroup(i)">
                                {{group.userGroupName}}
                                <img [src]="removeIconBtn" alt="remove" class="removeIconBtn" (click)="removeGroup(group,subscription.value[i].subscriptionId)" *ngIf="checkedGroupsArray?.[0]?.['userGroupName']!='API-UG'">
                              </div>
                            </ng-container>
                          </div>
                          <button type="button" class="tertiaryBtn withDropdown"
                            [disabled]="!subscriptionItem?.value?.selectedModule" (click)="showUGPopup(i)">Add User
                            Group(s)</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="subscription?.value[i].subscriptionUserId!=userId">
                  <div class="card userxsubscriptionCard" [ngClass]="i>0?'m-15':null" [formGroupName]="i">
                    <div class="subsCardHead">
                      <div class="subscriptionName">
                        <h4>{{subscription?.value[i]?.subscriptionName}}</h4>
          
                        <img [src]="editIconBtn" alt="edit" class="editIconBtn dontShow">
                        <div class="subsMetaData">
                          <div class="status active"
                            [ngClass]="subscription?.value[i]?.statusName=='ACTIVE'?'status_active':null">
                            {{subscription?.value[i]?.statusName}}
                          </div>
                          <div class="textGroup">
                            <div class="textBlock">
                              <p class="label">Start Date:</p>
                              <p class="label black">{{subscription.value[i].startsOn}}</p>
                            </div>
                            <div class="textBlock">
                              <p class="label">End Date:</p>
                              <p class="label black">{{subscription.value[i].endsOn}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="subsCardBody">
                      <div class="modulesCont">
                        <p class="label">Selected Module(s)</p>
                        <div class="selectedModules">
                          <div class="selections">
                            <ng-container *ngIf="subscriptionItem?.value?.modules?.length>0">
                              <div class="pill" *ngFor="let moduleItem of subscriptionItem.value.modules;let indx=index"
                                [ngClass]="subscriptionItem?.value?.selectedModule?.id==moduleItem?.id?'selected':null"
                                (click)="selectModule(moduleItem,i,indx)">
                                {{moduleItem.moduleName}}
                                <div class="counter">
                                  {{moduleItem.userGroups?.length>0?moduleItem.userGroups?.length:0}}
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="subscriptionItem?.value?.modules?.length==0">
                              <small>
                                <!-- No modules currenlty assigned for this subscription -->
                              </small>
                            </ng-container>
          
                          </div>
                          <span class="others dropdown-container">

                          </span>
                        </div>
                      </div>
                      <div class="UGCont">
                        <p class="label">Selected Users group(s)</p>
                        <div class="selectedUGs">
                          <div class="selections">
                            <small
                              *ngIf="subscription.value[i]?.selectedModule?.userGroups?.length==0 || subscription.value[i]?.selectedModule?.userGroups==null">
                              Select a module to view user groups.
                            </small>
                            <ng-container *ngIf="subscription.value[i]?.selectedModule?.userGroups?.length>0">
                              <div class="pill" *ngFor="let group of getUserGroup(i)">
                                {{group.userGroupName}}
                              </div>
                            </ng-container>
                          </div>
                         <span>
                         </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
          
              </ng-container>
            </div>
          </ng-container>

        </div>
        <div class="ctaGroup">
          <button type="button" class="secondaryBtn" (click)="goback()" *ngIf="!userForm.dirty && !userForm.touched">
            BACK
          </button>
          <button type="button" class="secondaryBtn" (click)="resetForm()" *ngIf="userForm.dirty" >
            CANCEL
          </button>
          <button type="submit" class="primaryBtn" (click)="saveUser()" [disabled]="
            userForm?.status=='INVALID' ||
            userForm.invalid ||
            checkModules() ||
            checkUserGroup()">Save Changes</button>
        </div>
      </div>
      <!-- Delete Modal window container starts -->
      <div class="modal modal-backdrop dontShow" tabindex="-1" role="dialog" id="isRemoving">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title">Are you sure?</div>
              <div>
                <img src="{{closeIconBtn}}" class="close_icon" width="16px" height="16px" alt="icon" />
              </div>
            </div>
            <div class="modal-body">
              <p>
                You are about to delete this request. Once deleted, it cannot be recovered.
                <br><br>
                Do you want to proceed?
              </p>
            </div>
            <div class="modal-footer two_btn">
              <button type="button" class="secondaryBtn">
                No
              </button>
              <button type="button" class="primaryBtn red">
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal modal-backdrop blockMe assignUGPopUp" tabindex="-1" role="dialog" *ngIf="modulePopupFlag">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Assign User Groups</div>
          <div>
            <img src="../../../../assets/images/close_icon.svg" class="close_icon" width="16px" height="16px" alt="icon"
              (click)="closeUGPopup()" />
          </div>
        </div>
        <div class="modal-body">
          <p class="ugCount">
            {{totalGroupAssignedCount}} Groups Assigned
          </p>
          <div class="tabCont">
            <ul class="tabGroup">
              <li>
                <button (click)="prev()" [disabled]="startIndex === 0" class="btn">
                  <img src="../../../../assets/images/East.svg" alt="">
                </button>
              </li>

              <li [ngClass]="chip.selected?'active':''" *ngFor="let chip of visibleChips;let i=index" class="btn"
                (click)="switchTab(chip.id,null)">
                <div class="chip-content">
                  {{chip.moduleName.length>25?chip.moduleName.slice(0, 25)+'...':chip.moduleName}}
                </div>
              </li>

              <li>
                <button (click)="next()" [disabled]="startIndex + itemsPerSlide >= chips.length" class="btn">
                  <img src="../../../../assets/images/East.svg" alt="" style="transform: rotate(180deg);">
                </button>
              </li>
            </ul>
            <div class="content">
              <div class="listOfItems mt-4 formControls" style="min-height: 0;padding-top: 4px;">
                <div class="formControls" >
                  <label for="roleChk" >                    
                    <input id="roleChk" (change)="onCheckUserAccessRole($event)" type="checkbox" [checked]="getRole()=='ADMIN'">
                    <span class="styleCheckBox"></span>
                    {{'Admin'}}
                  </label>
                </div>
              </div>
              <div class="seachIcon_container">
                <img src="../../../../assets/images/search_icon.svg" alt="seach_icon" *ngIf="!checkSearchIcon()"
                  class="searchIcon" />
                <img src="../../../../assets/images/close_icon.svg" alt="clear_icon" *ngIf="checkSearchIcon()"
                  class="searchIcon" (click)="clearText()">
              </div>
              <input type="text" class="searchInput" placeholder="Search" [(ngModel)]="searchText"
                (keyup)="filterUserGroups()">
              <p class="selected dropdown-container" *ngIf="checkedGroupsArray?.[0]?.['userGroupName']!='API-UG'">
                <span
                  *ngFor="let checkedGroup of checkedGroupsArray?.slice(0, groupLimit)">{{checkedGroup.userGroupName}}
                  <img src="../../../../assets/images/close_icon.svg" alt=""
                    (click)="uncheckGroup(checkedGroup.id)"></span>
                <span class="others" (click)="showOtherSelectedGroups()"
                  *ngIf="checkedGroupsArray?.length>groupLimit">+{{(checkedGroupsArray?.slice(groupLimit))?.length}}
                  others
                  <div class="othersList " *ngIf="showOtherSelectedGroupsFlag">
                    <div *ngFor="let checkedGrp of checkedGroupsArray?.slice(groupLimit)">
                      <p>{{checkedGrp.userGroupName}}</p> <img src="../../../../assets/images/close_icon.svg" alt=""
                        (click)="uncheckGroup(checkedGrp.id)">
                    </div>
                  </div>
                </span>
                <button class="removeAll" (click)="removeAllSelectedGropus()"
                  *ngIf="checkedGroupsArray?.length>0">Unassign
                  All</button>
              </p>
              <p class="selected dropdown-container"  *ngIf="checkedGroupsArray?.[0]?.['userGroupName']=='API-UG'">

                <span
                  *ngFor="let checkedGroup of checkedGroupsArray?.slice(0, groupLimit)">{{checkedGroup.userGroupName}}
                  <img src="../../../../assets/images/close_icon.svg" alt=""
                    ></span>
                <span class="others"
                  *ngIf="checkedGroupsArray?.length>groupLimit">+{{(checkedGroupsArray?.slice(groupLimit))?.length}}
                  others
                  <div class="othersList " *ngIf="showOtherSelectedGroupsFlag">
                    <div *ngFor="let checkedGrp of checkedGroupsArray?.slice(groupLimit)">
                      <p>{{checkedGrp.userGroupName}}</p> 
                    </div>
                  </div>
                </span>
                <button class="removeAll"
                  *ngIf="checkedGroupsArray?.length>0" [disabled]="true">Unassign
                  All</button>
              </p>
              <div class="listOfItems" *ngIf="checkedGroupsArray?.[0]?.['userGroupName']!='API-UG'">
                <div class="formControls" *ngFor="let group of activeTabUserGroups">
                  <label for="agb" (click)="onChangeGroup(group)">
                    <input type="checkbox" [checked]="group.isAssigned">
                    <span class="styleCheckBox"></span>
                    {{group.userGroupName}}
                  </label>
                </div>
              </div>
              <div class="listOfItems" *ngIf="checkedGroupsArray?.[0]?.['userGroupName']=='API-UG'">
                <div class="formControls" *ngFor="let group of activeTabUserGroups">
                  <label for="agb" >
                    <input type="checkbox" [checked]="true" [disabled]="true">
                    <span class="styleCheckBox"></span>
                    {{group.userGroupName}}
                  </label>
                </div>
              </div>
            </div>
            <p class="resultCount">Showing {{activeTabUserGroups?.length}} groups</p>
          </div>
        </div>
        <div class="modal-footer two_btn">
          <div></div>
          <button type="button" class="primary_green_btn" (click)="assignGroups()">
            Assign
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="wrapper"></div>
<app-success-toast [message]="responseResult" *ngIf="responseResult"></app-success-toast>
<app-error-toast [message]="errMsgText" *ngIf="errMsgText && flagError"></app-error-toast>