import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './core/components/homepage/homepage.component';
import { DataRequestManagerComponent } from './core/components/data-request-manager/data-request-manager.component';
import { CapabilitiesComponent } from './core/components/capabilities/capabilities.component';
import { InsightsComponent } from './core/components/insights/insights.component';
import { ApplicationComponent } from './core/components/application/application.component';
import { SubscriptionComponent } from './core/components/subscription/subscription.component';
import { EditSubscriptionComponent } from './core/components/subscription/edit-subscription/edit-subs.component';
import { ConfirmEmailComponent } from './core/components/confirm-email/confirm-email.component';
import { CreatePasswordComponent } from './core/components/create-password/create-password.component';
import { CreateUserComponent } from './core/components/manage-users/create-user/create-user.component';
import { ManageUsersComponent } from './core/components/manage-users/manage-users/manage-users.component';
import { EditUserComponent } from './core/components/manage-users/edit-user/edit-user.component';
import { AnalyticsComponent } from './core/components/analytics/analytics.component';
import { LoginComponent } from './core/components/login/login.component';
import { AccoutSettingsComponent } from './core/components/accout-settings/accout-settings.component';
import { EditProfileComponent } from './core/components/accout-settings/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './core/components/accout-settings/change-password/change-password.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomepageComponent },
  { path: 'data-request-manager', component: DataRequestManagerComponent },
  { path: 'capabilities', component: CapabilitiesComponent },
  { path: 'insights', component: AnalyticsComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'app', component: ApplicationComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'create-subscription', component: EditSubscriptionComponent },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'forgot-password', component: LoginComponent },
  { path: 'account-settings', component: AccoutSettingsComponent },
  { path: 'profile', component: EditProfileComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'edit-subscription/:subscriptionId',
    component: EditSubscriptionComponent,
  },
  { path: 'manage-users', component: ManageUsersComponent },
  { path: 'create-users', component: CreateUserComponent },
  { path: 'edit-user/:id', component: EditUserComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
