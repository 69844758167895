import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  OnInit,  
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/helper/util.service';
import { AuthenticateService } from 'src/app/shared/services/authenticate.service';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerTabs: any = [];
  isHeaderTabVisible: boolean = false;
  userName: any;
  userId: string;
  private lastVisitedUrl: string = '';
  userType: string;
  isProfileCardOpen: boolean = false;
  backupLastVisitedUrl: string;
  sideHeaders: any = [];
  moduleByteMap: any;
  iconHeaders: any = [];
  allHeadersTab: any = [];
  accountSettingSvg="../assets/images/settings-black.svg"
  errMsg: string = '';
  showErrorMessageWithPopup: boolean = false;


  constructor(
    public auth: AuthService,
    private homePageService: HomePageService,
    private utilService: UtilService,
    private spinnerService: NgxSpinnerService,
    private router: Router,    
    private http: HttpClient,
    private eRef: ElementRef
  ) {
    this.auth.getAccessTokenSilently().subscribe((res: any) => {
      localStorage.setItem('token', res);
    });
  }

  ngOnInit(): void {
    this.spinnerService.show();   
    this.allHeadersTab = [];      
         
    this.utilService.moduleBytes.subscribe((mod: any) => {
      this.moduleByteMap = mod as Object;
    });
    
    this.utilService.getUserId.subscribe((userId: any) => {      
      if(userId != 'undefined')
         this.userId = userId;
    });
                

    this.spinnerService.show();
    this.auth.user$.subscribe((user) => {
      localStorage.setItem('id', user?.sub);
      this.userName = user?.name;

      if(localStorage.getItem('id')!= 'undefined'){
        this.homePageService.getUserByAuthId().subscribe(
          (res: any) => {
            this.errMsg = '';
            const response = res;
            const result = response.result;
            const id = result?.id;
                     
            localStorage.setItem('userId', id);
            this.utilService.userId(id);
            this.spinnerService.hide();
            if (localStorage.getItem('userId') != 'undefined') {
              this.callHeader();
              this.userType = localStorage.getItem('userType');
              this.lastVisitedUrl = localStorage.getItem('lastVisitedUrl');                           
              this.router.navigateByUrl(this.lastVisitedUrl);
            }          
          },
          (err: any) => {
            console.log('Error :' + err);
          }
        );
      } 

       if (localStorage.length == 0 || localStorage.getItem('userId') == 'undefined') {
        
        this.utilService.allHeaders.subscribe((allHeaders: any) => {          
          this.allHeadersTab = [...allHeaders];        
        
        if(this.allHeadersTab == null || this.allHeadersTab.length == 0){
        const headers = new HttpHeaders({
          userId: 0,
        });
        this.http
          .post<any>(`${environment.apiUrl}/v1/allHeaders`, null, {
            headers: headers,
          })
          .subscribe((res: any) => {
            this.spinnerService.hide();
            const headerResult = res.result;
            this.allHeadersTab = headerResult; 
            this.utilService.setAllHeaders(this.allHeadersTab);                                 
                    
            this.differentiateHeaders(this.allHeadersTab);
            
            this.lastVisitedUrl = localStorage.getItem('lastVisitedUrl');
          });
        }else
           this.differentiateHeaders(this.allHeadersTab);
        });
      }
    });
    this.utilService.userName.subscribe((response: any) => {          
      let isUserNameEdited = response;  
      if(isUserNameEdited){
        this.getUserDetails();
      }
    });
  }
    
  getUserDetails(){
    this.auth.user$.subscribe((user) => {
    this.userName = user?.name;

      if(localStorage.getItem('id')!= 'undefined'){
        this.homePageService.getUserByAuthId().subscribe(
          (res: any) => {
            const response = res;
            const result = response.result;
            this.userName = result?.firstName+ ' ' +result.lastName;
         },
          (err: any) => {
            console.log('Error :' + err);
          }
        );
      } 
   });
  }
  ngDoCheck() {
    if (localStorage.getItem('userId')) {
      this.userType = localStorage.getItem('userType');
    }
  }

  callHeader() {
    this.spinnerService.show();
    this.userId = localStorage.getItem('userId');
    this.homePageService.onHeader(this.userId).subscribe((res: any) => {
      const headerResult = res.result;
      this.allHeadersTab = [...headerResult];                  
      
      this.differentiateHeaders(this.allHeadersTab);
      
      this.spinnerService.hide();
      if (localStorage.getItem('userId')) {
        this.lastVisitedUrl = localStorage.getItem('lastVisitedUrl');
        this.router.navigateByUrl(this.lastVisitedUrl);
      }
      this.spinnerService.hide();
    });
  }

  onLogout() {
    localStorage.removeItem('token');
    this.isHeaderTabVisible = false;
  }

  logout() {
    this.spinnerService.hide();
    localStorage.clear();
    this.auth.logout();
  }

  onLoginCall() {
    this.auth.loginWithRedirect({appState: { target: localStorage.getItem('lastVisitedUrl') }});
  }

  onClickSideHeader(sideHeader: any) {
    const sideHeaderPath = sideHeader.headerLinkPath;
    this.router.navigate([sideHeaderPath]);
  }

  openProfileCard() {
    this.isProfileCardOpen = !this.isProfileCardOpen;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isProfileCardOpen = false;
    }
  }

  differentiateHeaders(allHeadersTab: any) { 
    this.headerTabs = [];
    this.sideHeaders = [];
    this.iconHeaders = [];

    allHeadersTab.forEach((header: any) => {    
    if(header.homePageItem){ 
      if(header.menuImagePath.includes('_icon.'))     
        this.iconHeaders.push(header); 
      else       
        this.headerTabs.push(header);
    } else
       this.sideHeaders.push(header);
  
    });
  }
  goToAccountSettings(){
    this.router.navigate(['/account-settings'])
  }
}

