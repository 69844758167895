<form class="mainCard">
  <div class="pageName">
    <ng-container *ngIf="subscriptionId">
      <h1>{{ getSubscriptionDetails.subscriptionName }}</h1>
      <div class="subtitle">
        Add or remove users or manage their modules from below
      </div>
    </ng-container>
    <ng-container *ngIf="!subscriptionId">
      <h1>Create New Subscription</h1>
      <div class="subtitle">
        Please fill in the details below. Fields marked with
        <span class="mandatory_star">*</span> are mandatory.
      </div>
    </ng-container>
  </div>
  <form [formGroup]="subscriptionForm">
    <div class="newSubsForm">
      <div class="formGroupANDTitle">
        <div class="readOnlyInfo" *ngIf="subscriptionId && userType !== 'DMINT Admin'">
          <p class="sectionTitle">Subscription Details</p>
          <div class="subsDetails">
            <p class="label">Subscription Name</p>
            <p class="value">
              {{ getSubscriptionDetails.subscriptionName }}
            </p>
            <p class="label">Start / End Date</p>
            <p class="value">
              {{ getSubscriptionDetails.startDate }} -
              {{ getSubscriptionDetails.endDate }}
            </p>
          </div>
          <div class="subsDetails">
            <p class="label">Customer Name</p>
            <p class="value">{{ getSubscriptionDetails.customerName }}</p>
            <p class="label">Subscription Price</p>
            <p class="value">$000.00</p>
          </div>
          <div class="subsDetails">
            <p class="label">Modules</p>
            <p class="value" *ngFor="let item of getSubscriptionDetails.subscriptionModules">
              {{ item.moduleName }}
            </p>
          </div>
        </div>
        <div class="formGroup dontShow" *ngIf="!subscriptionId || userType === 'DMINT Admin'">
          <p class="sectionTitle">Subscription Details</p>
          <div class="subsName_input">
            <div class="label required">Subscription Name</div>
            <input type="text" class="inputElement" formControlName="subscriptionName" required placeholder="Type here"
              (input)="trackValueChange('subscriptionName')" />
          </div>
          <div class="subsName_input">
            <div class="label required">Customer Name</div>
            <input type="text" matInput class="inputElement" formControlName="customerName" [matAutocomplete]="auto"
              [readonly]="subscriptionId > 0" [ngClass]="subscriptionId > 0?'disableField':''" placeholder="Type here">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
              (optionSelected)="onSelectCustomer($event.option.value)">
              <mat-option [value]="option" *ngFor="let option of filterCustomers | async">{{option}}</mat-option>
            </mat-autocomplete>
          </div>

          <div class="subsDate_input">
            <div class="created_on dropdown_div">
              <div style="display: flex;justify-content: space-between;">
                <div class="title_label label required">Start/End Date </div>
                <div><input type="checkbox" (change)="checkNeverendSub($event)" title="Never End Subscription"
                    [readonly]="!neverEndSubFlag" formControlName="neverEndSubscription" /></div>
              </div>
              <mat-form-field appearance="fill">
                <mat-date-range-input [rangePicker]="picker" [min]="subscriptionForm.get('startDate').value?.length==0?minDate:null" [max]="subscriptionForm.get('startDate').value?.length==0?maxDate:null">
                  <input matStartDate placeholder="DD MM YYYY" formControlName="startDate" name="startDate"
                    (dateChange)="startDate($event)" required readonly />
                  <input matEndDate placeholder="DD MM YYYY" formControlName="endDate" name="endDate"
                    (dateChange)="endDate($event)" required readonly />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker [disabled]="neverEndSubFlag"></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="subsMod_select">
            <div class="module_div">
              <div class="title_label label required">Modules</div>
              <div class="dropdown">
                <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="moduleFilterDropdownSettings"
                  [(ngModel)]="selectedFilterModule" formArrayName="subscriptionModules"
                  [ngModelOptions]="{ standalone: true }" [data]="modules" (onSelect)="onModuleSelect($event)"
                  (onDeSelect)="onModuleDeselect($event)">
                  {{ modules }}
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div class="subsPlan">
            <div class="label">Plan</div>
            <div class="cstmToggle">
              <button class="cstmToggle_btn active">Free</button>
              <button class="cstmToggle_btn" [disabled]="true">Paid</button>
            </div>
          </div>
          <div class="subsPrice">
            <p class="price">$0.00</p>
          </div>
        </div>
      </div>
      <div class="formGroupANDTitle usersTable">
        <p class="sectionTitle">User Details</p>
        <div class="table">
          <div class="thead">
            <div class="td">Admin</div>
            <div class="td">First Name<span class="required">*</span></div>
            <div class="td">Last Name<span class="required">*</span></div>
            <div class="td">Email<span class="required">*</span></div>
            <div class="td">Modules<span class="required">*</span></div>
            <div class="td"></div>
          </div>
          <div class="tbody">
            <div class="tr" formArrayName="users">
              <ng-container *ngFor="let customControl of getControls(); let i = index" [formGroupName]="i">

                <label class="td">
                  <input type="checkbox" formControlName="isAdmin" (change)="onCheckBoxChange(i)"
                    (click)="checkReadOnly()" />
                  <span class="styleCheckBox"></span>
                </label>
                <input type="hidden" formControlName="id" [value]="id" />
                <div class="td">
                  <input type="text" matInput class="inputElement" formControlName="firstName"
                    [matAutocomplete]="autofn" (input)="onChangeUserSearch(firstName.value, 'firstName')"
                    placeholder="Type here" [readonly]="users.controls[i].get('id').value>0 && subscriptionId > 0"
                    [ngClass]="users.controls[i].get('id').value>0 && subscriptionId > 0?'disableField':''" #firstName>
                  <mat-autocomplete #autofn="matAutocomplete" [displayWith]="displayFNFn"
                    (optionSelected)="selectedUser($event.option.value ,i)">
                    <mat-option [value]="option"
                      *ngFor="let option of filterUserDetails">{{option.firstName}}</mat-option>
                  </mat-autocomplete>
                </div>

                <div class="td">
                  <input type="text" matInput class="inputElement" formControlName="lastName" [matAutocomplete]="autoln"
                    #lastName [readonly]="users.controls[i].get('id').value>0 && subscriptionId > 0"
                    [ngClass]="users.controls[i].get('id').value>0 && subscriptionId > 0?'disableField':''"
                    (input)="onChangeUserSearch(lastName.value, 'lastName')" placeholder="Type here">
                  <mat-autocomplete #autoln="matAutocomplete" [displayWith]="displayLNFn"
                    (optionSelected)="selectedUser($event.option.value ,i)">
                    <mat-option [value]="option"
                      *ngFor="let option of filterUserDetails">{{option.lastName}}</mat-option>
                  </mat-autocomplete>
                </div>

                <div class="td">
                  <input type="email" class="inputElement" [readonly]="users.controls[i].get('id').value>0 "
                    [ngClass]="users.controls[i].get('id').value>0 && subscriptionId > 0?'disableField':''"
                    formControlName="email" required placeholder="Type here" />
                </div>
                <div class="td">
                  <div class="module_div">
                    
                    <div class="dropdown" [formGroupName]="i" formArrayName="userModules">
                      <ng-multiselect-dropdown [placeholder]="'Select'" *ngIf="subModule"
                        [settings]="subModuleFilterDropdownSettings" 
                        [(ngModel)]="selectedFilterSubModule[i]" 
                        [ngModelOptions]="{ standalone: true }" [data]="subModule"
                        (onSelect)="onSubModuleSelect($event, i)" (onDeSelect)="onSubModuleDeselect($event, i)">
                        {{ subModule }}
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </div>
                <div class="td assUG">
                  <button (click)="showUGPopup(i)"  [disabled]="disableUserGroupButton(i)">
                    <img src="../../../../assets/images/Not assigned.svg" alt="">
                  </button>
                  <div class="ugCounter" *ngIf="checkUserGroupCount(i)">{{getGroupAssignedCount(i)}}</div>
                </div>
                <div class="td custom-padding">
                  <button class="delete_btn" [disabled]="checkDeleteButtonCriteria(i)" [ngClass]="
                      users.length === 1 ? 'setGreyBorder' : 'setRedBorder'
                    " type="button" (click)="onDeleteRow(i)">
                    <img src="../../../../assets/images/delete.svg" alt="delete_icon" />
                  </button>
                </div>
              </ng-container>
            </div>
            <button class="addRowBtn" type="button" (click)="onAddNew()">
              + Add New
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="ctaGroup">
      <button class="secondary-filled-btn" (click)="goback()" *ngIf="!isFormEdited()">
        BACK
      </button>
      <button class="secondary-filled-btn" (click)="onCancel()" *ngIf="isFormEdited()">
        CANCEL
      </button>
      <button *ngIf="!subscriptionId" class="primary-filled-btn" type="button" (click)="onSubmit()" [disabled]="
          (isSubmitEnabled() || !this.subscriptionForm.valid) ||
          !this.subscriptionForm.get('subscriptionName').valid ||
          !this.subscriptionForm.get('customerName').valid ||
          !this.subscriptionForm.get('startDate').valid ||
          !this.subscriptionForm.get('endDate').valid ||
          !this.subscriptionForm.get('subscriptionModules').valid ||
          !isSubscriptionValid() || isUserScubscriptionValid() || noAdminPresent || checkMinimumGroupCount()
        ">
        SUBMIT
      </button>

      <button *ngIf="subscriptionId" class="primary-filled-btn" type="button" (click)="onEditSave()" [disabled]="
      (isSubmitEnabled() && isSubscriptionModified && !this.subscriptionForm.valid) || !isSubscriptionValid() || isUserScubscriptionValid()
        ||  !this.subscriptionForm.get('subscriptionName').valid || noAdminPresent || checkEmailValidation() || isDuplicateEmail
        || !isSubscriptionValidForUser() || !isModuleChanged || checkMinimumGroupCount()">
        SAVE CHANGES
      </button>
    </div>
  </form>


  <div class="modal modal-backdrop" *ngIf="isCancelActive">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Are you sure?</div>
          <div>
            <img src="../../../../assets/images/close_icon.svg" class="close_icon" width="16px" height="16px"
              alt="close_icon" (click)="onClose()" onKeyPress="" />
          </div>
        </div>
        <div class="modal-body">
          <p *ngIf="!subscriptionId">
            You are about to cancel new subscription. Once cancelled, all
            details will be deleted.
            <br /><br />
            Do you want to proceed?
          </p>
          <p *ngIf="subscriptionId">
            You are about to cancel edited subscription. Once cancelled, all
            edited details will be deleted.
            <br /><br />
            Do you want to proceed?
          </p>
        </div>
        <div class="modal-footer two_btn">
          <button type="button" class="secondary-filled-btn" (click)="onClose()">
            No
          </button>
          <button type="button" class="primary_green_btn red" (click)="onResetForm()">
            Yes, continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal modal-backdrop" *ngIf="isPopupVisible">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Are you sure?</div>
          <div>
            <img src="../../../../assets/images/close_icon.svg" class="close_icon" width="16px" height="16px"
              alt="icon" />
          </div>
        </div>
        <div class="modal-body">
          <p>
            You are about to deactivate DMINT_CEAT2024.
            <br /><br />
            Do you want to proceed?
          </p>
        </div>
        <div class="modal-footer two_btn">
          <button type="button" class="secondary-filled-btn">No</button>
          <button type="button" class="primary_green_btn red">
            Deactivate
          </button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal modal-backdrop blockMe assignUGPopUp" tabindex="-1" role="dialog" *ngIf="modulePopupFlag">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Assign User Groups</div>
          <div>
            <img src="../../../../assets/images/close_icon.svg" class="close_icon" width="16px" height="16px" alt="icon"
              (click)="closePopup()" />
          </div>
        </div>
        <div class="modal-body">
          <p class="ugCount">
            {{totalGroupAssignedCount}} Groups Assigned
          </p>
          <div class="tabCont">
            <ul class="tabGroup">
              <li>
                <button (click)="prev()" [disabled]="startIndex === 0" class="btn">
                  <img src="../../../../assets/images/East.svg" alt="">
                </button>
              </li>
              <li [ngClass]="chip.selected?'active':''" *ngFor="let chip of visibleChips;let i=index" class="btn"
                (click)="switchTab(chip.id)">
                {{chip.moduleName.length>25?chip.moduleName.slice(0, 25)+'...':chip.moduleName}}
              </li>

              <li>
                <button (click)="next()" [disabled]="startIndex + itemsPerSlide >= chips.length" class="btn">
                  <img src="../../../../assets/images/East.svg" alt="" style="transform: rotate(180deg);">
                </button>
              </li>
            </ul>
            <div class="content">
              <div class="seachIcon_container">
                <img src="../../../../assets/images/search_icon.svg" alt="seach_icon" *ngIf="!checkSearchIcon()" class="searchIcon" />
                <img src="../../../../assets/images/close_icon.svg" alt="clear_icon" *ngIf="checkSearchIcon()" class="searchIcon" (click)="clearText()">
              </div>
              <input type="text" class="searchInput" placeholder="Search" [(ngModel)]="searchText"
                (keyup)="filterUserGroups()" >
               
              <p class="selected">
                
                <span *ngFor="let checkedGroup of checkedGroupsArray.slice(0, groupLimit)">{{checkedGroup.userGroupName}} <img src="../../../../assets/images/close_icon.svg" alt="" (click)="uncheckGroup(checkedGroup.id)"></span>
                <span class="others" (click)="showOtherSelectedGroups()" *ngIf="checkedGroupsArray.length>groupLimit">+{{(checkedGroupsArray.slice(groupLimit))?.length}} others
                  <div class="othersList" *ngIf="showOtherSelectedGroupsFlag">
                    <div *ngFor="let checkedGrp of checkedGroupsArray.slice(groupLimit)">
                      <p>{{checkedGrp.userGroupName}}</p> <img src="../../../../assets/images/close_icon.svg" alt="" (click)="uncheckGroup(checkedGrp.id)">
                    </div>
                  </div>
                </span>
                <button class="removeAll" (click)="removeAllSelectedGropus()" *ngIf="checkedGroupsArray.length>0" >Unassign All</button>
              </p>
              <div class="listOfItems mt-4">
                <div class="formControls" *ngFor="let group of activeTabUserGroups">
                  <label for="agb" (click)="onChangeGroup(group)">
                    <input type="checkbox" 
                    [checked]="group.isAssigned"
                    >
                    <span class="styleCheckBox"></span>
                    {{group.userGroupName}}
                  </label>
                </div>
              </div>
            </div>
            <p class="resultCount">Showing {{activeTabUserGroups?.length}} groups</p>
            <ul>
              <li *ngFor="let groupName of checkedUserGroups">{{ groupName }}</li>
            </ul>
          </div>
        </div>
        <div class="modal-footer two_btn">
          <div></div>
          <button type="button" class="primary_green_btn" (click)="assignGroups()">
            Assign
          </button>
        </div>
      </div>
    </div>
  </div>
</form>


<app-success-toast [message]="successMessage" *ngIf="successMessage"></app-success-toast>

<app-error-toast [message]="errorMessage" *ngIf="errorMessage"></app-error-toast>