import { AfterViewInit, Component, computed, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
//import { gsap } from 'gsap';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/shared/helper/util.service';
import { ConfirmEmailService } from '../confirm-email/confirm-email.service';
import { emailCheckAsyncValidator } from '../../Validators/checkEmailExists.validator';
import { passwordValidator } from '../../Validators/checkPassword.validator';
import { matchPasswordValidator } from '../../Validators/checkBothPasswords.validator';
import { AuthService } from '@auth0/auth0-angular';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  isForgetPassword = true;
  emailError = "Please Enter valid email"
  showToastMessage = false
  currentDate = ''
  countryName = ''
  operatingSystem = ''
  browserName = ''
  showErrorToast = false;
  error = ''
  successMsg = ''
  showSuccessToast = false
  globalEmail: any = '';
  errMsgText: string = null;
  existingUserStatus: any;
  emailForm: any;
  capitalLetterFlag = false;
  uniqueCharacterFlag = false;
  stringLengthGt12Flag = false;
  stringNumFlag = false;
  emailNotExistsErr: string = '';
  timer:string = '03:00'; // Initial timer value for 3 minutes;
  private countdownInterval: any;
  isShowTimer: boolean =false;
  codeExpText = "Code Expires in"
  resetForm: FormGroup<{ verificationCode: FormControl<string>; newPassword: FormControl<string>; confirmPassword: FormControl<string>; }>;
  isConfirmPasswordHidden: boolean=true;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
   private fb: FormBuilder,
   private cdr: ChangeDetectorRef,
  private activatedRoute: ActivatedRoute,
  private router: Router,
  private confirmEmail: ConfirmEmailService,
  private utilService: UtilService,
  public auth: AuthService,) {
     this.setFormControls()
     this.activatedRoute.paramMap.subscribe((res: any) => {
      let { id } = res?.params;
    })
  }
  
  ngOnInit() {
    this.setFormControls()
    this.currentDate = this.formatDate();
    this.countryName='India' // temporarly set country for testing
    this.operatingSystem = this.getOS();
    this.browserName = this.getBrowserName();
    this.confirmEmail.email$.subscribe(res => {
      this.globalEmail = res;
    })
   }
   setFormControls(){
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$')] ,[emailCheckAsyncValidator(this.confirmEmail)]]
    })
    this.resetForm = this.fb.group({
      verificationCode: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]], 
      newPassword: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required]], 
    }, { validators: matchPasswordValidator('newPassword', 'confirmPassword') }); 
    
   }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    //  this.animateColorBalls();
      this.resetForm.get('newPassword')?.valueChanges.subscribe((password) => {
        this.updatePasswordValidation(password);
        if (this.isResetPassword()) {
          this.updatePasswordValidation(password);
        }
      });
      this.resetForm.get('confirmPassword')?.valueChanges.subscribe((password) => {
        this.updatePasswordValidation(password);
        if (this.isResetPassword()) {
          this.updatePasswordValidation(password);
        }
      });
      this.cdr.detectChanges();
    }
  }
  formatDate() {
    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const date = new Date();
    const formattedDate = formatter.format(date);
    // Add the timezone information
    return `${formattedDate} (Asia/Kolkata)`;
  }
  getOS() {
    const userAgent = navigator.userAgent;

    let os = "Unknown OS";

    if (userAgent.includes("Windows")) {
      os = "Windows";
    } else if (userAgent.includes("Macintosh")) {
      os = "macOS";
    } else if (userAgent.includes("Linux")) {
      os = "Linux";
    } else if (userAgent.includes("Android")) {
      os = "Android";
    } else if (userAgent.includes("like Mac")) {
      os = "iOS";
    }

    return os;
  }
  getBrowserName() {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown Browser";

    if (userAgent.includes("Chrome")) {
      browserName = "Google Chrome";
    } else if (userAgent.includes("Firefox")) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.includes("Safari")) {
      browserName = "Apple Safari";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.includes("Edge")) {
      browserName = "Microsoft Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browserName = "Opera";
    }

    return browserName;
  }
  // Password Show/Hide starts

  isPasswordHidden = true;

  showHidePass(): void {
    this.isPasswordHidden =! this.isPasswordHidden;
  }
  showHideConfirmPass(): void {
    this.isConfirmPasswordHidden =! this.isConfirmPasswordHidden;
  }
  // Password Show/Hide ends

  // Switch forms starts

  isLoginForm = signal(false);
  isForgotPassword = signal(true);
  isResetPassword = signal(false);

  getNavigation(): () => void {
    if (this.isLoginForm()) {
      return this.showForgotPassword.bind(this);
    } else if (this.isForgotPassword()) {
      return this.showResetPassword.bind(this);
    } else if (this.isResetPassword()) {
      return this.showLogin.bind(this);
    } else {
      return this.showLogin.bind(this);
    }
  }
  
  showLogin(): void {
    this.isLoginForm.set(true);
    this.isForgotPassword.set(false);
    this.isResetPassword.set(false);
    this.cdr.detectChanges()
  }

  showForgotPassword(): void {
    this.isLoginForm.set(false);
    this.isForgotPassword.set(true);
    this.isResetPassword.set(false);
    this.cdr.detectChanges()
  }

  showResetPassword(): void {
    this.isLoginForm.set(false);
    this.isForgotPassword.set(false);
    this.isResetPassword.set(true);
    this.cdr.detectChanges()
  }

 
  isPasswordValid = signal({
    hasSpecialCharacter: false,
    hasCapitalLetter: false,
    isLongEnough: false,
    hasNumber:false
  });

  private updatePasswordValidation(password: string): void {
    this.isPasswordValid.set({
      hasSpecialCharacter: /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/.test(password),
      hasCapitalLetter: /[A-Z]/.test(password)&&/[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      isLongEnough: password.length >=8,
    });
  }

 
  loginForm: FormGroup;
  isSubmitted = false;
 
  
  sendVerification() {
    let payload = {
      "email": this.loginForm.get('email').value?.toLowerCase()?.trim(),
      "date": this.currentDate,
      "os": this.operatingSystem,
      "browser": this.browserName,
      "isForgotPassword":'true',
      "location": this.countryName,
      
    }
    
    this.confirmEmail.setEmail(this.loginForm.get('email').value?.trim());
    this.confirmEmail.sendEmailVerificationCode(payload).subscribe({
      next:(res: any) => {
        if (res.code == 500) {
          this.error = res.result.error;
          this.showErrorToast = true;
          setTimeout(() => {
            this.showErrorToast = false;
            this.error = ''
          }, 10000)
        }
        else if (res.code == 401) {
          this.error = res.result.error;
          this.showErrorToast = true;
          setTimeout(() => {
            this.showErrorToast = false;
            this.error = ''
          }, 10000)
        }
        else if (res.code == 200) {
          this.isSubmitted = false;
          this.isForgetPassword = false;
          this.startCountdown();
        }
      },
      error:(error)=>{
      //  this.spinnerService.hide()
        this.utilService.SharingDataTextMessage.subscribe((res: any) => {
          this.error = res
        })
        this.showErrorToast = true;        
        setTimeout(() => { this.showErrorToast = false; this.error = '';this.utilService.SharingDataTextMessage.next('') }, 5000)
      }
    }
  )
  }
   
  // GSAP Animation starts

  private animateColorBalls(): void {
    // var tl = gsap.timeline ()
    //   .to('.ball',{
    //     x: "random(-80, 80, 50)", 
    //     y: "random(-80, 80, 30)",
    //     scale: "random(0.4, 1.6, 0.2)", 
    //     duration: 6,
    //     ease: "none",
    //     repeat: -1,
    //     repeatRefresh: true
    //   }
    // )
  }

  // GSAP Animation ends

  onResetPassword(){
    this.verifyCode()
  }
 

  verifyCode() {
    let payload = {
      "email": this.globalEmail.toLowerCase()?.trim(),
      "verificationCode": this.resetForm.get('verificationCode').value
    }
    this.confirmEmail.verifyEmailCode(payload).subscribe((res: any) => {
      if (res.code == 200) {
        this.isShowTimer = false;
        this.createPassword();
       } else if (res.code == 500) {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
      }, error => {
        this.utilService.SharingDataTextMessage.subscribe((res: any) => {
          this.error = res
        })
        if(this.error=='Error Verification code has Expired'){
          this.codeExpText = 'Code Expired?'
          this.isShowTimer = false;
          this.resetForm.get('verificationCode')?.setErrors({
            expiredCode: true,
            errorMessage: 'Verification code is expired. Please resend to generate new verification code.'
          });
        }else if(this.error=='Error Verification Code entered is invalid!'){
          this.resetForm.get('verificationCode')?.setErrors({
            expiredCode: true,
            errorMessage: 'Verification Code is Invalid'
          });
        }else{
          this.showErrorToast = true;        
          setTimeout(() => { this.showErrorToast = false; this.error = '';this.utilService.SharingDataTextMessage.next('') }, 5000)
        }
      })
  }

 
  resendCode(): void {
    this.sendVerification()
  }
   
  onSubmit(): void {
    this.isSubmitted = true;
    if(this.isForgetPassword){
      this.sendVerification();
    }else {
      this.verifyCode();
    }
   }
   createPassword() {
    let payload = {
      "emailId": this.globalEmail.toLowerCase()?.trim(),
      "password": this.resetForm.get('newPassword').value
    }
    this.confirmEmail.createPassword(payload).subscribe((res: any) => {      
      if (res.code == 200) {
        this.isShowTimer = false;
        if(this.isShowTimer == false)this.codeExpText = 'Code Expired?';
        this.showSuccessToast = true;
        this.successMsg = 'Your password has been successfully updated.'
        setTimeout(() => {
        //  this.router.navigate(['/home'])
          this.backToLogin();
          this.showSuccessToast = false;
        }, 3000);
      } else if (res.code == 500) {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
    })
  }
  onLoginCall() {
    this.auth.loginWithRedirect({appState: { target: '/' }});
  }
  startCountdown(): void {
    this.isShowTimer = true;
    if(this.isShowTimer)this.codeExpText = 'Code Expires in';
    let minutes = 3; // Set initial minutes to 3
    let seconds = 0;

    this.countdownInterval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.countdownInterval);
          this.timer = '03:00'; // Reset timer display to 3 minutes
          this.isShowTimer = false;
          if(this.isShowTimer == false)this.codeExpText = 'Code Expired?';
        } else {
          minutes--;
          seconds = 59;
        }
      } else {
        seconds--;
      }

      // Format the timer to always show two digits
      this.timer = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    }, 1000);
  }

  formatTime(time: number): string {
    return time < 10 ? `0${time}` : time.toString();
  }
  handlePageVisibility() {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        // Optional: Pause or stop the timer when hidden
        // If you want to pause the timer when the page is hidden:
        // clearInterval(this.countdownInterval);
      } else {
        // Restart the timer or handle the visibility change if needed
        // If you want to continue without interruption, do nothing here
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.countdownInterval);
  }
  //Validations
  isLoginFormValid(){
    if(this.loginForm.valid){
      return false;
    }else{
      return true;
    }
  }

  isResetFormValid(){
    if(this.resetForm.valid){
      return false;
    }else{
      return true;
    }
  }

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get verificationCode() {
    return this.resetForm.get('verificationCode') as FormControl;
  }
  get newPassword() {
    return this.resetForm.get('newPassword') as FormControl;
  }
  get confirmPassword() {
    return this.resetForm.get('confirmPassword') as FormControl;
  }

  backToLogin(){
    localStorage.setItem('lastVisitedUrl','/')
    this.auth.loginWithRedirect();
  }
}
