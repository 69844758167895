import { ChangeDetectorRef, Component, computed, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { ConfirmEmailService } from '../../confirm-email/confirm-email.service';
import { UtilService } from 'src/app/shared/helper/util.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit {

  isProfileEditable = false;
  userForm: any;
  userDetails: any;
  emailId: any;
  isPasswordHidden = true;
  showSuccessToast: boolean;
  successMsg: string;
  showErrorToast: boolean;
  error: any;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    private homePageService: HomePageService,
    private confirmEmail: ConfirmEmailService,
  private utilService: UtilService,) {

    // this.changePass = this.fb.group({
    //   passForChange: ['', [Validators.required, Validators.email]],
    //   oldPass: ['', Validators.required],
    // });

  }
  showHidePass(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  // isPasswordValid = signal({
  //   hasSpecialCharacter: false,
  //   hasCapitalLetter: false,
  //   isLongEnough: false,
  // });

  // private updatePasswordValidation(password: string): void {
  //   this.isPasswordValid.set({
  //     hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  //     hasCapitalLetter: /[A-Z]/.test(password),
  //     isLongEnough: password.length > 12,
  //   });
  // }

 
  ismyProfile = signal(true);
  isChangePassword = signal(false);

  // showMyProfile(): void {
  //   this.ismyProfile.set(true);
  //   this.isChangePassword.set(false);
  //   this.cdr.detectChanges()
  // }

  // showChangePassword(): void {
  //   this.ismyProfile.set(false);
  //   this.isChangePassword.set(true);
  //   this.cdr.detectChanges()
  // }

  // Switch forms ends

  // onSubmit behaviour starts

  // changePass: FormGroup;
 // isSubmitted = false;

  // isSubmitEnabled = computed(() => {
  //   const emailControl = this.changePass.get('emailForLogin');
  //   return emailControl?.valid || false;
  // });

  ngOnInit() {
    this.initForm();
    this.getUserDetails();
    this.isFormEditable();
  }
  initForm() {
    this.userForm = this.fb.group({
      firstname: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$'),
        Validators.minLength(2),
        Validators.maxLength(50),
      ]
      ],
      lastname: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$'),
        Validators.minLength(2),
        Validators.maxLength(50),
      ]
      ],
    })
  }

  editMyProfile(): void {
    this.isProfileEditable = !this.isProfileEditable;
    this.isFormEditable();
  }
  isFormEditable() {
    if (this.isProfileEditable) {
      this.userForm.get('firstname').enable();
      this.userForm.get('lastname').enable();
    } else {
      this.userForm.get('firstname').disable();
      this.userForm.get('lastname').disable();
    }
  }

  getUserDetails = () => {
    this.homePageService.getUserByAuthId().subscribe((res: any) => {
      if (res?.result) {
        this.userDetails = res?.result;
        this.emailId = this.userDetails?.email;
        this.setValuesToForm()
      }
    })
  }
  setValuesToForm() {
    this.userForm.get('firstname').setValue(this.userDetails?.firstName);
    this.userForm.get('lastname').setValue(this.userDetails?.lastName);
  }
  onSubmit(): void {
    if (this.userForm.valid) {
      this.saveUserDetails();
      this.editMyProfile();
    }
  }



  saveUserDetails() {
    let payload = {
      "authId": localStorage.getItem('id'),
      "firstName": this.userForm.get('firstname').value,
      "lastName": this.userForm.get('lastname').value
    }
    this.confirmEmail.saveUserDetails(payload).subscribe((res: any) => {
      if (res.code == 200) {
        this.showSuccessToast = true;
        this.successMsg = res.result;
        this.utilService.setUserName(true);
        setTimeout(() => {
          this.showSuccessToast = false;
        }, 3000);
      
      } else if (res.code == 500) {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
    })
  }
  onCancel() {
    this.setValuesToForm();
    this.editMyProfile();
  }

  get firstname() {
    return this.userForm.get('firstname') as FormControl;
  }
  get lastname() {
    return this.userForm.get('lastname') as FormControl;
  }

}
