import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrlService } from 'src/app/shared/services/url.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmEmailService {
  private email: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public email$: Observable<string> = this.email.asObservable()
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) { }
  sendEmailVerificationCode(payload: any) {
    return this.http.post(`${environment.apiUrl}/v1/sendEmailVerificationCode`, payload);
  }
  verifyEmailCode(payload: any) {
    return this.http.post(`${environment.apiUrl}/v1/validateVerificationCode`, payload);
  }
  createPassword(payload: any) {
    return this.http.post(`${environment.apiUrl}/v1/updatePassword`, payload);
  }
  setEmail(email: any) {
    this.email.next(email);
  }

}
