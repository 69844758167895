<ng-container>
    <div class="mainCont">
        <div class="content" [ngClass]="{'dynamic-after': showSuccessToast, 'no-after': !showSuccessToast}">
            <div class="leftCol">
                <h1>
                    <img src="../../../../assets/images/dmint_color_logo.png" alt="logo"><br>
                    <span>Welcome to</span><br>
                    DMINT Platform
                </h1>
            </div>
            <div class="rightCol">
                <form [formGroup]="passwordForm" (ngSubmit)="submitForm()">
                    <div class="cardCont">
                        <div class="pageName">
                            <h2>Create Password</h2>
                            <p class="subHeader">
                                Please create your password and confirm
                            </p>
                        </div>
                        <div class="formControl">
                            <p class="label required">
                                New Password
                            </p>
                            <input type="password" formControlName="password" name="verifyEmail" id="v_email"
                                placeholder="Type here" (keyup)="checkPasswordRules()">
                        </div>
                        <div class="formControl">
                            <p class="label required">
                                Confirm Password
                            </p>
                            <input type="password" formControlName="confirmPassword" name="verifyEmail" id="v_email"
                                placeholder="Type here">
                            <div *ngIf="passwordForm.invalid && passwordForm.get('confirmPassword')?.dirty">
                                <small class="error" *ngIf="passwordForm?.errors?.['passwordsMismatch'] ">Passwords do
                                    not match.</small>
                            </div>
                        </div>
                        <div class="passwordTips">
                            <p>Please make sure that the password contains:</p>
                            <ul>
                                <li [ngClass]="
                                passwordForm.get('password').dirty?
                               ( uniqueCharacterFlag?'done':'fails'):null">
                                    Password should contain only following special characters:
                                    !&#64;#$%^&()_+=[]&#123;&#125;;':"\\&#124;,.
                                    &gt;&lt;\/?*
                                </li>
                                <li [ngClass]="
                                passwordForm.get('password').dirty?
                               ( capitalLetterFlag?'done':'fails'):null">
                                    Password should contain at least one Uppercase character
                                </li>

                                <li [ngClass]="
                                passwordForm.get('password').dirty?
                               ( stringLengthGt12Flag?'done':'fails'):null">
                                    New Password should have minimum length of 8 characters
                                </li>
                                <li [ngClass]="
                                passwordForm.get('password').dirty?
                               ( stringNumFlag?'done':'fails'):null">
                                    Password should contain at least one Lowercase character and one number
                                </li>
                            </ul>
                        </div>
                        <button type="submit" class="primary-filled-btn"
                            [disabled]="passwordForm?.status=='INVALID'">SUBMIT</button>
                    </div>
                </form>
                <div class="copyright"> © DMINT Copyright 2024. All Rights Reserved. </div>
            </div>
            <div class="success toastMessage dontShow" *ngIf="showSuccessToast">
                <p class="message">
                    Your password has been successfully created. Please log in again.
                </p>
            </div>
            <app-error-toast [message]="error" *ngIf="error && showErrorToast"></app-error-toast>
        </div>
    </div>
</ng-container>