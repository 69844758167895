import { Component, HostListener } from '@angular/core';
import { ManageUsersService } from '../manage-users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/helper/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import { map, startWith } from 'rxjs';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiUrlService } from 'src/app/shared/services/url.service';
import {  Router } from '@angular/router';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrl: './manage-users.component.scss'
})
export class ManageUsersComponent {
  hideAxs = true;
  allCustomers: any = [];
  allCustomersCopy: any = [];
  allStatuses: any = [];
  allUserTypes: any = [];
  allUsers: any = [];
  id: any;
  userType: any = '';
  isSubscriptionAdmin: any = false;
  isAdminUser: any = false;
  selectedRowId: any;
  filterForm: FormGroup;
  p: number = 1;
  currentPage: any;
  itemsPerPage = 10;
  totalItems: any;
  typeFilterDropdownSettings: IDropdownSettings = {};
  selectedFilterUserType: any = [];
  types: any = [];
  fillErrorText: any;
  errorFlag = false;
  showErrorMessage: any = false;
  errMsgText: string = null;
  dateSort = false;
  totalUsers = 0;
  errMsgPopup: any;
  responseResult: string = null;
  errorMessage: string = null;
  flagError = false;
  selectedUserType: any = [];
  usetypeFilterDropdownSettings: IDropdownSettings = {};
  statusFilterDropdownSettings: IDropdownSettings = {};
  kababMenuFlag = false
  companyName: any;
  resetPage: boolean = true;

  constructor(
    private manageUserService: ManageUsersService,
    private utilService: UtilService,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private router: Router,
    public dialog: MatDialog,
  
  ) { 

  
  }
  ngOnInit(): void {
    this.spinnerService.show();
    this.initForm()
    this.setUserDetails()
    this.filterForm.get('customerId').valueChanges.subscribe((res: any) => {
      const filterValue = res.toLowerCase();
      if (filterValue?.length > 0) {
        this.allCustomers = this.allCustomers?.filter((option: any) => option?.companyName?.toLowerCase().includes(filterValue));
      } else {
        this.allCustomers = _.cloneDeep(this.allCustomersCopy)
      }
    })
    this.usetypeFilterDropdownSettings = {
      singleSelection: false,
      idField: 'userTypeId',
      textField: 'userType',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };
    this.statusFilterDropdownSettings = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };
  }

  initForm() {
    this.filterForm = this.fb.group({
      "userId": [this.id],
      "customerId": [null],
      "userType": [null],
      "lastModifiedStart": [null],
      "lastModifiedEnd": [null],
      "status": [null],
      "searchtext": [null],
      "sortBy": ["updated_date"],
      "sortType": ["desc"],
    })
  }
  viewOptions() { this.hideAxs = false }
  setUserDetails() {
    this.utilService.getUserId.subscribe((id: any) => {
      this.id = id as string;
    });
    if (localStorage.getItem('userId')) {
      this.id = localStorage.getItem('userId');
      this.userType = localStorage.getItem('userType');
      this.utilService.companyName.subscribe(res => this.companyName = res);
      this.loadInitialData()
      if (this.userType == 'Subscription Admin') {
        this.isSubscriptionAdmin = true;
        this.isAdminUser = true;
      }else if (this.userType == 'DMINT Admin') {
        this.isAdminUser = true;
      }
      else {
        this.isSubscriptionAdmin = false;
      }
    }
  } 

  loadInitialData() {
    let userType=localStorage.getItem('userType')
    if (userType == 'User') {
      this.errorFlag = true;
      this.showErrorMessage = true;
      this.errMsgText='Error This User has no access for User Management Dashboard'
      this.flagError = true;
      setTimeout(() => { this.flagError = false; this.errMsgText = '';this.router.navigate(['/home']) }, 5000)
    } else {
      this.getAllCustomers()
      this.getAllStatuses()
      this.getAllUserTypes()
      this.getAllUsers(!this.resetPage)
    }
  }
  getPage(event: any) {
    this.spinnerService.show();
    this.p = event;
    this.currentPage = this.p - 1;
    const userId: any = this.id.toString();
    let headers = new HttpHeaders().append('userId', userId);
    this.getAllUsers(!this.resetPage);
  }
  getAllCustomers = () => this.manageUserService.getCustomersByUser(this.id).subscribe({
    next:(res: any) => { res && (this.allCustomers = res.result); res && (this.allCustomersCopy = res.result) },
    error:(error)=>{
      this.spinnerService.hide();
    }
  })
  getAllStatuses = () => this.manageUserService.getAllStatuses(4).subscribe((res: any) => { res && (this.allStatuses = res.result) })
  getAllUserTypes = () => this.manageUserService.getUserTypes().subscribe((res: any) => { res && (this.allUserTypes = res.result) })
  getAllUsers(resetPage: any) {
    this.spinnerService.show();
    let usertype: any = null
    usertype = this.filterForm.get('userType').value?.map((item: any) => item.userTypeId)
    let status: any = null
    status = this.filterForm.get('status').value?.map((item: any) => item.statusId)
    let formattedStartDate = null
    if (this.filterForm.value['lastModifiedStart']) {
      const date = new Date(this.filterForm.value['lastModifiedStart']);
      formattedStartDate = moment(date).toISOString();
    } else {
      formattedStartDate = null
    }
    let formattedEndDate = null
    if (this.filterForm.value['lastModifiedEnd']) {
      const date = new Date(this.filterForm.value['lastModifiedEnd']);
      date.setDate(date.getDate() + 1);      
      formattedEndDate = moment(date).toISOString();      
    } else {
      formattedEndDate = null
    }

    let payload: any = {
      "userId": this.id,
      "customerId": this.filterForm.value['customerId'] ? [this.filterForm.value['customerId']['companyId']] : null,
      "userType": usertype,
      "lastModifiedStart": formattedStartDate,
      "lastModifiedEnd": formattedEndDate,
      "status": status,
      "searchtext": this.filterForm.value['searchtext']?.trim(),
      "sortBy": "updated_date",
      "sortType": this.filterForm.value['sortType']
    }
    
    if(resetPage)
        this.p = 1;
    
    this.manageUserService.getAllUsers(payload, this.itemsPerPage, this.p).subscribe(
      {
        next: (res: any) => {
          res && (this.allUsers = res.result)
          res && (this.totalUsers = res.totalCount)
          this.errorFlag = false;
          this.showErrorMessage = false;  
          this.spinnerService.hide();
          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.fillErrorText = res
          })
        },
        error: (error: any) => {          
          this.spinnerService.hide();
          this.errorFlag = true;
          this.showErrorMessage = true;
          this.allUsers = []
          this.totalUsers = 0;

          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.errMsgText = res
          })
          this.flagError = true;
          setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
        }
      }
    )
  }
  toggleKababMenu = (rowId: any) => {
    this.kababMenuFlag = true
    if (this.selectedRowId == rowId) {
      this.selectedRowId = null;
    } else {
      this.selectedRowId = +rowId;
    }
  }
  hideMenu() {
    this.kababMenuFlag = false
  }
  resetFilterForm = () => { this.filterForm.reset(); this.p = 1; this.getAllUsers(!this.resetPage); this.allCustomers = []; this.allCustomers = _.cloneDeep(this.allCustomersCopy); this.selectedRowId = null }
  displayFn = (customer: any): string => customer ? customer.companyName : '';
  filterTableData = () => this.getAllUsers(this.resetPage)
  onSelectCustomer = () => this.getAllUsers(!this.resetPage)
  userTypeChanged = () => this.getAllUsers(!this.resetPage)
  statusChange = () => this.getAllUsers(!this.resetPage)
  lastUpdatedDateChange = () => this.getAllUsers(this.resetPage)
  lastUpdatedDateSorting = () => {
    this.dateSort = !this.dateSort
    if (this.dateSort) {
      this.filterForm.get('sortType').setValue('asc')
    } else {
      this.filterForm.get('sortType').setValue('desc')
    }
    this.getAllUsers(!this.resetPage)
  }
  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.kababMenuFlag = false;
    }
  }
  activateDeactivateUser(user: any, index: any) {
    let userOb = null;
    if (user.status == 'ACTIVE') {
      userOb = this.allStatuses.find((item: any) => item.statusName == 'INACTIVE');
    } else if (user.status == 'INACTIVE') {
      userOb = this.allStatuses.find((item: any) => item.statusName == 'ACTIVE');
    }
    let payload = {
      "userId": user.userId,
      "loggedInUserId": this.id,
      "statusId": userOb.statusId
    }
    this.manageUserService.activateUser(payload).subscribe({
      next: (res: any) => {
        if (res?.code == 200) {
          this.responseResult = res.message;
          this.flagError = false;
          this.selectedRowId = null;
          this.getAllUsers(this.resetPage)
          setTimeout(() => { this.responseResult = '' }, 5000)
        }

      },
      error: (err) => {
        this.utilService.SharingDataTextMessage.subscribe((res: any) => {
          this.errMsgText = res
        })
        this.flagError = true;
        setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
      }
    })
  }

  checkViewEditStatus(user: any) {
    if (user.userType == 'User') {
      if (user.status == "ACTIVE") {
        return false;
      }
      else if (user.status == "INACTIVE") {
        return true;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }
    }
    else if (user.userType == 'Subscription Admin') {
      if (user.status == "ACTIVE") {
        return false;
      }
      else if (user.status == "INACTIVE") {
        return true;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    else if (user.userType == 'DMINT Admin') {
      if (user.status == "ACTIVE") {
        return false;
      }
      else if (user.status == "INACTIVE") {
        return true;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    return true;
  }
  checkActivateDeactivateStatus(user: any) {
    if (user.userType == 'User') {
      if (user.status == "ACTIVE") {
        return false;
      }
      else if (user.status == "INACTIVE") {
        return false;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    else if (user.userType == 'Subscription Admin') {
      if (user.status == "ACTIVE") {
        return true;
      }
      else if (user.status == "INACTIVE") {
        return false;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    else if (user.userType == 'DMINT Admin') {
      if (user.status == "ACTIVE") {
        return true;
      }
      else if (user.status == "INACTIVE") {
        return false;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    return true;
  }
  checkDeleteStatus(user: any) {
    if (user.userType == 'User') {
      if (user.status == "ACTIVE") {
        return false;
      }
      else if (user.status == "INACTIVE") {
        return false;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    else if (user.userType == 'Subscription Admin') {
      if (user.status == "ACTIVE") {
        return true;
      }
      else if (user.status == "INACTIVE") {
        return true;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    else if (user.userType == 'DMINT Admin') {
      if (user.status == "ACTIVE") {
        return true;
      }
      else if (user.status == "INACTIVE") {
        return true;
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return true;
      }

    }
    return true;
  }

  toggleActivateDeactivate(user: any) {
    if (user.userType == 'User') {
      if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return false;
      }
    }
    else if (user.userType == 'Subscription Admin') {
      if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return false;
      }
    }
    else if (user.userType == 'DMINT Admin') {
      if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return false;
      }
    }
    return true;
  }
  checkActivateDeactivateText(user: any) {
    if (user.userType == 'User') {
      if (user.status == "ACTIVE") {
        return 'Deactivate';
      }
      else if (user.status == "INACTIVE") {
        return 'Activate';
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return 'Activate/Deactivate';
      }

    }
    else if (user.userType == 'Subscription Admin') {
      if (user.status == "ACTIVE") {
        return 'Deactivate';
      }
      else if (user.status == "INACTIVE") {
        return 'Activate';
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return 'Activate/Deactivate';
      }

    }
    else if (user.userType == 'DMINT Admin') {
      if (user.status == "ACTIVE") {
        return 'Deactivate';
      }
      else if (user.status == "INACTIVE") {
        return 'Activate';
      }
      else if (user.status == "DELETED" || user.status == "UNVALIDATED") {
        return 'Activate/Deactivate';
      }

    }
    return "";
  }
  checkStatusClass(user: any) {
    if (user.status == 'ACTIVE') {
      return 'active';
    } else if (user.status == 'UNVALIDATED') {
      return 'unvalidated';
    } else if (user.status == 'INACTIVE') {
      return 'inactive';
    } else if (user.status == 'DELETED') {
      return 'deleted';
    }
    return '';
  }
  hideKababMenu() {
    this.selectedRowId = null
  }
  onUserTypeSelect(event: any) {
    let index = this.filterForm.get('userType').value.findIndex((item: any) => item.userTypeId == event.userTypeId);
    if (index == -1) {
      this.filterForm.get('userType').value.setValue([...this.filterForm.get('userType').value, event.userTypeId])
    }
    
    this.getAllUsers(this.resetPage)
  }
  onUserTypeDeselect(event: any) {
    let index = this.filterForm.get('userType').value.findIndex((item: any) => item.userTypeId == event.userTypeId);
    if (index > -1) {
      this.filterForm.get('userType').value.splice(index, 1)
    }
    this.getAllUsers(this.resetPage)
  }
  onSelectAllUserType(event: any) {
    this.filterForm.get('userType').setValue(this.allUserTypes);
    this.getAllUsers(this.resetPage)
  }
  onDeSelectAllUserType() {
    this.filterForm.get('userType').setValue([]);
    this.getAllUsers(this.resetPage)
  }
  onStatusSelect(event: any) {
    let index = this.filterForm.get('status').value.findIndex((item: any) => item.statusId == event.statusId);
    if (index == -1) {
      this.filterForm.get('status').value.setValue([...this.filterForm.get('status').value, event.statusId])
    }
    this.getAllUsers(this.resetPage)
  }
  onStatusDeselect(event: any) {
    let index = this.filterForm.get('status').value.findIndex((item: any) => item.statusId == event.statusId);
    if (index > -1) {
      this.filterForm.get('status').value.splice(index, 1)
    }
    this.getAllUsers(this.resetPage)
  }
  onSelectAllStatus(event: any) {
    this.filterForm.get('status').setValue(this.allStatuses);
    this.getAllUsers(!this.resetPage)
  }
  onDeSelectAllStatus() {
    this.filterForm.get('status').setValue([]);
    this.getAllUsers(this.resetPage)
  }
  deleteUser = (user: any) => {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Are you sure?',
        message: 'You are about to delete <b>' + user.userName + '</b>. Once deleted, it cannot be recovered.<br><br>Do you want to proceed?'
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let payload = {
          "userId": user.userId,
          "loggedInUserId": this.id
        }
        this.manageUserService.deleteUser(payload).subscribe({
          next: (res: any) => {
            if (res) {
              this.selectedRowId = null
              this.getAllUsers(this.resetPage)

              this.responseResult = res?.message;
              setTimeout(() => { this.responseResult = '' }, 5000)
            }
          },
          error: (err: any) => {
            this.utilService.SharingDataTextMessage.subscribe((res: any) => {
              this.errMsgText = res
            })
            this.flagError = true;
            setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
          }
        }
        )
      } else {
        console.log('Delete action canceled');
      }
    });


  }
  openCreateUserPage() {
    this.router.navigate(['/create-users'])
  }
  editUser(user:any){
    this.router.navigate(['edit-user/'+user.userId])
  }
}
