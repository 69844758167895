<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div class="container">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a routerLink="/home" class="navbar-brand">
        <img
          src="../../../../assets/images/dmint_color_logo.png"
          alt="CoolBrand"
          class="dmint_logo"
        />
      </a>
      <button
        type="button"
        class="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav menu_row" *ngFor="let iconHeader of iconHeaders">
          <a
            [routerLink]="iconHeader.headerLinkPath"
            routerLinkActive="active"
            class="nav-item nav-link"
            *ngIf="moduleByteMap"
          >         
          <img
          [src]="'data:image/svg+xml;base64,' + moduleByteMap[iconHeader.headerName+'_'+iconHeader.menuImagePath]"
          alt="subscription_icon"
          />
            <span>{{ iconHeader.headerLabel }}</span>
          </a>
        </div>
        <a href="https://dmintcopilot.azurewebsites.net/chat" target="_blank" *ngIf="iconHeaders?.length > 0" class="copilot-link">
          <img src="../../../../assets/images/copilotIcon-white.svg" alt="copilot"/>
          <small style="margin-left: 5px;">Copilot</small>          
        </a>
        <div
          class="navbar-nav right_row ms-auto"
          (click)="openProfileCard()"
          onKeyPress=""
        >
          <ng-template #loggedOut
            ><button
              class="btn btn-primary custom-button-login"
              (click)="onLoginCall()"
            >
              Login
            </button></ng-template
          >
          <a
            *ngIf="auth.isAuthenticated$ | async; else loggedOut"
            class="nav-item nav-link dropdown-toggle"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><img
              src="../../../../assets/images/default_user_icon.svg"
              alt="icon"
              class="navbar_icon"
            />
            <ul class="dropdown-menu ms-auto" *ngIf="isProfileCardOpen">
              <li class="profile_section">
                <img
                  src="../../../../assets/images/default_user_icon.svg"
                  alt="icon"
                  class="profile_picture"
                />
                <ul class="profile_details">
                  <li class="name">{{ userName }}</li>
                </ul>
              </li>
              <hr />   
              <li
                class="logout margin-bottom"
                (click)="onClickSideHeader(sideHeader)"
                onKeyPress=""                                                            
               *ngFor="let sideHeader of sideHeaders">
                <a class="dropdown-item"
                *ngIf=" userType === 'DMINT Admin' ||
                        userType === 'Subscription Admin'||
                        sideHeader?.headerLabel === 'Account Settings'">                       
                        <img
                    [src]="'data:image/svg+xml;base64,' + moduleByteMap[sideHeader.headerName+'_'+sideHeader.menuImagePath]"
                    alt="subscription_icon"
                  />
                  {{sideHeader.headerLabel}}</a>
               </li>            
              <li class="logout" (click)="logout()" onKeyPress="">
                <a class="dropdown-item logout-txt-color"
                  ><img
                    src="../../../../assets/images/logout_red_icon.svg"
                    alt="logout_icon"
                  />
                  Logout</a
                >
              </li>
            </ul>
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>
