import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, computed, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { matchPasswordValidator } from 'src/app/core/Validators/checkBothPasswords.validator';
import { passwordValidator } from 'src/app/core/Validators/checkPassword.validator';
import { ConfirmEmailService } from '../../confirm-email/confirm-email.service';
import { AuthService } from '@auth0/auth0-angular';
import { passwordSameValidator } from 'src/app/core/Validators/checkOldPasswordNewPasswordNotSame';
import { UtilService } from 'src/app/shared/helper/util.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit, AfterViewInit  {
  // Password Show/Hide starts

  isPasswordHidden = true;
  resetForm: any;
  showSuccessToast: boolean;
  successMsg: string;
  error: any;
  showErrorToast: boolean= true;
  isOldPasswordHidden: boolean= true;
  isNewPasswordHidden: boolean= true;
  isConfirmPasswordHidden: boolean= true;
  isCancelDisabled: boolean=true;
 
  
  showHideOldPass(): void {
    this.isOldPasswordHidden =! this.isOldPasswordHidden;
  }
  showHideNewPass(): void {
    this.isNewPasswordHidden =! this.isNewPasswordHidden;
  }
  showHideConfirmPass(): void {
    this.isConfirmPasswordHidden =! this.isConfirmPasswordHidden;
  }
  // Password Show/Hide ends

  // Password hints starts

  isPasswordValid = signal({
    hasSpecialCharacter: false,
    hasCapitalLetter: false,
    isLongEnough: false,
    hasNumber:false
  });

  private updatePasswordValidation(password: string): void {
    this.isPasswordValid.set({
      hasSpecialCharacter: /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/.test(password),
      hasCapitalLetter: /[A-Z]/.test(password)&&/[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      isLongEnough: password.length >=8,
    });
  }

  // Password hints ends

  // Switch forms starts

  ismyProfile = signal(true);
  isChangePassword = signal(false);

  showMyProfile(): void {
    this.ismyProfile.set(true);
    this.isChangePassword.set(false);
    this.cdr.detectChanges()
  }

  showChangePassword(): void {
    this.ismyProfile.set(false);
    this.isChangePassword.set(true);
    this.cdr.detectChanges()
  }

  // Switch forms ends

  // onSubmit behaviour starts

  changePass: FormGroup;
  isSubmitted = false;

  isSubmitEnabled = computed(() => {
      const emailControl = this.changePass.get('emailForLogin');
      return emailControl?.valid || false;
  });

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
   private fb: FormBuilder, private cdr: ChangeDetectorRef,
  private confirmEmail: ConfirmEmailService, private auth: AuthService,
 private utilService: UtilService,) {
  
    
    this.changePass = this.fb.group({
      newPassword: ['', [Validators.required, Validators.email]],
      oldPass: ['', Validators.required], 
    });
    
  }
  ngOnInit(){
    this.resetForm = this.fb.group({
      oldPassword: ['', [Validators.required]], 
      newPassword: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required]], 
    }, { validators: [
      matchPasswordValidator('newPassword', 'confirmPassword'),
      passwordSameValidator('oldPassword', 'newPassword') ]}
    ,); 
    
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    //  this.animateColorBalls();
      this.resetForm.get('newPassword')?.valueChanges.subscribe((password:any) => {
        this.updatePasswordValidation(password);
      });
      this.resetForm.get('confirmPassword')?.valueChanges.subscribe((password:any) => {
        this.updatePasswordValidation(password);
      });
      this.cdr.detectChanges();
    }
    this.resetForm.valueChanges.subscribe(() => {
      this.checkFormFields();
    });
  }
  onSubmit(): void {
    if (this.resetForm.valid) {
      this.createPassword();
    }
  }
  createPassword() {
    let payload = {
      "authId": localStorage.getItem('id'),
      "oldPassword": this.resetForm.get('oldPassword').value,
      "newPassword": this.resetForm.get('newPassword').value
 }
    this.confirmEmail.changePassword(payload).subscribe((res: any) => {
      if (res.code == 200) {
        this.showSuccessToast = true;
        this.successMsg = 'Your password has been successfully updated. Please login again.';
        setTimeout(() => {
          this.goToLogin();
          this.showSuccessToast = false;
        }, 3000);
      } else {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
    },error=>{
      this.utilService.SharingDataTextMessage.subscribe((res: any) => {
        this.error = res;
      })
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
    })
  }
  // onSubmit behaviour ends
  isResetFormValid(){
    if(this.resetForm.valid){
      return false;
    }else{
      return true;
    }
  }
  resetFormData(){
    this.resetForm.reset();
    this.isCancelDisabled = true;
  }

  goToLogin(){
    this.logout();
    localStorage.setItem('lastVisitedUrl','/')
    this.auth.loginWithRedirect();
  }

  logout() {
    localStorage.clear();
    this.auth.logout();
  }
  checkFormFields() {
    const hasValue = Object.values(this.resetForm.value).some((value:any) => value.trim() !== '');
    this.isCancelDisabled = !hasValue;
  }
}
