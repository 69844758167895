import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from './shared/helper/util.service';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { HomePageService } from './shared/services/homepage.service';
import { AuthenticateService } from './shared/services/authenticate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLocalStorage: boolean = false;
  userName: any;
  private lastVisitedUrl: string = '';
  response: any;
  image: any;
  moduleBytesMap: any;
  errMsg: string = '';
  showErrorMessageWithPopup: boolean;

  constructor(
    public auth: AuthService,
    private homePageService: HomePageService,
    public router: Router,
    private utilService: UtilService,
    private http: HttpClient,
    private authenticateService: AuthenticateService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.auth.getAccessTokenSilently().subscribe((res: any) => {
      localStorage.setItem('token', res);
    });

  }

  ngOnInit(): void {    
    this.spinnerService.show();
    this.showErrorMessageWithPopup = false;
    this.errMsg = '';
    
    this.auth.user$.subscribe((user) => {
      localStorage.setItem('id', user?.sub);
      this.userName = user?.name;
     if(localStorage.getItem('id') == 'undefined')
        localStorage.setItem('userId', 'undefined'); 

     if(localStorage.getItem('id')!='undefined'){
      this.homePageService.getUserByAuthId().subscribe(
        (res: any) => {         
          const response = res;
          const result = response.result;
          const id = result?.id;
          const userType = result?.userType;
          const userStatus = result?.statusName;
          if(userStatus == 'UNVALIDATED')
            this.errMsg = 'User not validated and yet to complete the Onboarding';
          else if(userStatus == 'DELETED' || userStatus == 'INACTIVE')
            this.errMsg = 'User is not Registered on the Platform'; 

          if(this.errMsg != ''){
            this.showErrorMessageWithPopup =  true;
          }

        if(this.errMsg == ''){
          localStorage.setItem('userId', id);
          localStorage.setItem('userType', userType);
          this.utilService.setUserCompanyName(result?.companyName);
          this.spinnerService.hide();
          this.utilService.userId(id);
          if (localStorage.getItem('userId') != 'undefined') {
            this.lastVisitedUrl = localStorage.getItem('lastVisitedUrl'); 
            this.router.navigateByUrl(this.lastVisitedUrl);
          }
        }
        },
        (err: any) => {
          console.log('Error :' + err);
        }
      );
     }      
    });

    this.http
      .get<Blob>(`${environment.apiUrl}/v1/downloadAllImages`)
      .subscribe((res) => {
        this.response = res;
        this.moduleBytesMap = this.response.result as Object;
        this.utilService.setModuleByte(this.moduleBytesMap);
      });
  }

  onCloseHandler(){
    this.errMsg = '';
    this.showErrorMessageWithPopup = false;
    localStorage.clear();
    sessionStorage.clear();
    this.auth.logout();
  }

  ngOnDestroy(): void {}
}
