import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  private useFirstApi: boolean = false;
  constructor() {}

  getApiUrl(): string {
    return this.useFirstApi ? environment.apiUrl : environment.apiUrl2;
  }

  toggleApiUrl(): void {
    this.useFirstApi = !this.useFirstApi;
  }
}
