import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    if (!password) {
      return null; // Don't validate if the password is empty
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isValidLength = password.length >= 8;
    const valid = hasUpperCase && hasSpecialChar && isValidLength && hasLowerCase && hasNumber;
    return valid ? null : { passwordInvalid: true };
  };
}
