import { Component, EventEmitter, HostListener, Output, signal } from '@angular/core';
// import { AppHeaderComponent } from 
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { AuthService } from '@auth0/auth0-angular';
import { shareReplay, Subscription } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as _ from 'lodash';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})

export class AnalyticsComponent {
  loadedItemsCount = 8
  analyticsProducts:any = [];
  isDropdownOpen = false
  selectedItems: any[] = [];
  moduleByteMap: any;
  userId: string;
  insightsModule: any;
  public insightsData: any;
  insightsModuleRecords: any = [];
  getinsightsModules: any = [];
  id: string = '';
  clearSubscription: Subscription;
  errMsg: any;
  showErrorMessageWithPopup: any = false;
//  errMsg: any;
  allCategories: any = []
  allTags: any = []
  isViewMoreVisible = false
  @Output() selectionChange = new EventEmitter<string[]>();
  typeOptions: any = []
  typeDropdownSettings: IDropdownSettings = {};
  filterForm: FormGroup;
  showCategory = false;
  showTags = false;
  allInsightsModulesHeaders:any=[]
  allInsightsModules:any=[]
  selectedFilterType: any = [];
  currentPage=1;
  loadMoreRecord=true;
  readonly headerId=4;
  page: number = 1;           
  limit: number = 10;         
  loading: boolean = false;
  getinsightsModulesHeaders:any=[]
  pageLoadFlag=true
  loadMoreProdcuts:any=true;
  allProdcuts:any=[]
  // pageLimit=10;
  totalResultCount=0
  sortingList = [
    {
      "id": 1,
      "sortBy": 'module_name',
      "sortType": 'asc',
      "label": "Module Name (A - Z)"
    },
    {
      "id": 2,
      "sortBy": 'module_name',
      "sortType": 'desc',
      "label": "Module Name (Z - A)"
    },
    // {
    //   "id": 3,
    //   "sortBy": 'created_date',
    //   "sortType": 'asc',
    //   "label": "Created Date (Ascending)"
    // },
    // {
    //   "id": 4,
    //   "sortBy": 'created_date',
    //   "sortType": 'desc',
    //   "label": "Created Date (Descending)"
    // },
    {
      "id": 3,
      "sortBy": 'updated_date',
      "sortType": 'asc',
      "label": "Updated Date (Ascending)"
    },
    {
      "id": 4,
      "sortBy": 'updated_date',
      "sortType": 'desc',
      "label": "Updated Date (Descending)"
    }

  ]
  selectedSort: string = null
  searchTextFlag: any = false;
  subscriptionMsg =
    'Please contact DMINT Administrator to get subscription of the same';
  closeSign: string;
  constructor(
    private spinnerService: NgxSpinnerService,
    private homePageService: HomePageService,
    private utilService: UtilService,
    private auth: AuthService,
    private analyticService: AnalyticsService,
    private fb: FormBuilder
  ) { }
  ngOnInit(): void {
    
    this.spinnerService.show();
    this.utilService.goToTop();
    this.utilService.moduleBytes.subscribe((mod) => {
      this.moduleByteMap = mod as Object;
    });
    this.utilService.getUserId.subscribe((userId: any) => {
      this.userId = userId;
      this.homePageService.onHeader(this.userId).subscribe((res) => {
        this.spinnerService.hide();
        this.insightsModule = res;
        if (this.insightsModule.message === 'OK') {
          this.insightsData = this.insightsModule.result;
          this.insightsModuleRecords = [...this.insightsData];
          for (let modulerecord of this.insightsModuleRecords) {
            if (modulerecord.id == this.headerId ) {
              this.getinsightsModulesHeaders = modulerecord.modules;
              this.getinsightsModulesHeaders.forEach((item:any)=>{
                item['moduleTypeName']=item.moduleType.moduleTypeName;
              })
              this.allInsightsModulesHeaders=_.cloneDeep(modulerecord.modules);
              this.getAllAnalyticsModules(true)
              
            }
          }
        }
      });
    });
    if (localStorage.getItem('userId')) {
      this.id = localStorage.getItem('userId');
    }
    this.clearSubscription = this.utilService.SharingDataPopUpMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errMsg = res;
      }
    );
    this.clearSubscription = this.utilService.getPopupStatus.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.showErrorMessageWithPopup = res;
      }
    );
    this.utilService.SharingDataPopUpMessage.subscribe((res: any) => {
      this.spinnerService.hide();
      this.errMsg = res;
    });
    this.utilService.getPopupStatus.subscribe((res: any) => {
      this.spinnerService.hide();

      if (this.errMsg !== null) {
        this.showErrorMessageWithPopup = res;
      }
    });
    this.getAllTags()
    this.getAllModules()
    this.getAllCategories()
    this.typeDropdownSettings = {
      singleSelection: false,
      idField: 'moduleTypeId',
      textField: 'moduleTypeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };
    this.initForm()
  }
  onTypeSelect(item: any) {
    this.typeList.push(this.fb.control(item.moduleTypeId));
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  onTypeDeselect(item: any) {
    let index = this.typeList?.value?.findIndex((ele: any) => ele == item?.moduleTypeId)
    if (index > -1) {
      this.typeList.removeAt(index)
    }
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  onSelectAllTypes(items: any) {
    this.typeList.clear();
    items.forEach((ele: any) => {
      this.typeList.push(this.fb.control(ele.moduleTypeId))
    })
    // this.setCurrentPage()
    // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  // setCurrentPage(){
  //   this.currentPage=1;
  // }
  onDeSelectAllTypes() {
    this.typeList.clear();
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  initForm() {
    this.filterForm = this.fb.group({
      search: [''],
      typeList: this.fb.array([]),
      categoryList: this.fb.array([]),
      tags: this.fb.array([]),
      sortBy: ['updated_date'],
      sortType: ['desc'],
    })
  }
  get typeList() {
    return this.filterForm.get('typeList') as FormArray;
  }
  get categoryList() {
    return this.filterForm.get('categoryList') as FormArray;
  }
  get tags() {
    return this.filterForm.get('tags') as FormArray;
  }
  filterAnalytics() {    
    if(this.filterForm.get('search').value!=''){
      this.searchTextFlag=true
    }else{
      this.searchTextFlag=false
    }
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  isCategorySelected(categoryName: string): boolean {
    return this.categoryList.controls.some((control: any) => control.value === categoryName);
  }
  isTagSelected(tagName: string): boolean {
    return this.tags.controls.some((control: any) => control.value === tagName);
  }
  toggleCategory() {
    this.showCategory = !this.showCategory;
    this.showTags = false;
  }
  toggleTags() {
    this.showTags = !this.showTags;
    this.showCategory = false;
  }
  onCheckboxChange(event: any, category: any, flag = false) {
    if (flag) {
      let index = this.allCategories?.findIndex((item: any) => item?.categoryId == category?.categoryId);
      if (index > -1) {
        this.allCategories[index]['selected'] = !this.allCategories[index]['selected']
        if (this.allCategories[index]['selected']) {
          this.categoryList.push(this.fb.control(category.categoryId));
        } else {
          const index = this.categoryList.controls.findIndex(x => x.value === category.categoryId);
          if (index >= 0) {
            this.categoryList.removeAt(index);
          }
        }
      }
    } else {
      if (event.target.checked) {
        this.categoryList.push(this.fb.control(category.categoryId));
      } else {
        const index = this.categoryList.controls.findIndex(x => x.value === category.categoryId);
        if (index >= 0) {
          this.categoryList.removeAt(index);
        }
      }
    }
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  onTagCheckboxChange(event: any, tag: any, flag = false) {
    this.showCategory = false;
    if (flag) {
      let index = this.allTags?.findIndex((item: any) => item?.tagId == tag?.tagId);
      if (index > -1) {
        this.allTags[index]['selected'] = !this.allTags[index]['selected']
        if (this.allTags[index]['selected']) {
          this.tags.push(this.fb.control(tag.tagId));
        } else {
          const index = this.tags.controls.findIndex(x => x.value === tag.tagId);
          if (index >= 0) {
            this.tags.removeAt(index);
          }
        }
      }
    } else {
      if (event.target.checked) {
        this.tags.push(this.fb.control(tag.tagId));
      } else {
        const index = this.tags.controls.findIndex(x => x.value === tag.tagId);
        if (index >= 0) {
          this.tags.removeAt(index);
        }
      }
    }
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  checkFiltersApplied() {
    this.pageLoadFlag=false;
    if (
      this.filterForm.get('search')?.value != '' ||
      this.filterForm.get('typeList')?.value?.length > 0 ||
      this.filterForm.get('categoryList')?.value?.length > 0 ||
      this.filterForm.get('tags')?.value?.length > 0
    ) {
      this.allProdcuts=[]
    }
  }
  getAllTags() {
    this.analyticService.getAllTags().subscribe((res: any) => {      
      this.allTags = res.result
      if (this.allTags?.length > 0) {
        this.allTags.forEach((ele: any) => {
          ele['selected'] = false;
        });
      }
    })
  }
  sortAnalytics(item: any) {
    this.selectedSort = item.label;
    this.isSortingOpen = false;
    this.filterForm.get('sortBy').setValue(item.sortBy)
    this.filterForm.get('sortType').setValue(item.sortType)
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  toggleTagTab(tag: any) {
    let index = this.allTags?.findIndex((item: any) => item?.tagId == tag?.tagId);
    if (index > -1) {
      this.allTags[index]['selected'] = !this.allTags[index]['selected']
    }   
  }
  toggleCategoryTab(category: any) {
    let index = this.allCategories?.findIndex((item: any) => item?.categoryId == category?.categoryId);
    if (index > -1) {
      this.allCategories[index]['selected'] = !this.allCategories[index]['selected']
    }
  }
  getAllModules() {
    this.analyticService.getAllModules().subscribe((res: any) => {
      this.typeOptions = res.result      
    })
  }
  getAllCategories() {
    this.analyticService.getAllCategories().subscribe((res: any) => {      
      this.allCategories = res.result
      if (this.allCategories?.length > 0) {
        this.allCategories.forEach((ele: any) => {
          ele['selected'] = false;
        });
      }
    })
  }
  getAllAnalyticsModules(flag=false) {
    this.fetchProducts(flag) 
  }
  fetchProducts(flag:any){
    let payload: any = {
      "userId":'',
      "headerId": 4,
      "searchText": this.filterForm?.get('search').value ? this.filterForm?.get('search').value : null,
      "moduleTypeIds": this.filterForm?.get('typeList').value?.length > 0 ? this.filterForm?.get('typeList').value : null,
      "categoryIds": this.filterForm?.get('categoryList').value?.length > 0 ? this.filterForm?.get('categoryList').value : null,
      "tagIds": this.filterForm?.get('tags').value?.length > 0 ? this.filterForm?.get('tags').value : null,
      "sortBy": this.filterForm?.get('sortBy').value,
      "sortType": this.filterForm?.get('sortType').value,
      "limit": 500,
      "page": this.currentPage
    }
    this.analyticService.getAllAnalyticsModules(payload).subscribe(
      {
        next: (res: any) => {
          this.spinnerService.hide();
          this.loadMoreRecord = false
          this.getinsightsModules = res.result
          this.totalResultCount=res.totalCount;
          // if(res.result?.length>0 && this.loadMoreProdcuts){
          //   this.utilService.goToTop();
          // }
          if (this.getinsightsModules?.length > 0 ) {
            this.getinsightsModules.forEach((ele: any) => {
              let index = this.allInsightsModulesHeaders?.findIndex((item: any) => item.id == ele.moduleId)
              if (index > -1) {
                ele.subscription = this.allInsightsModulesHeaders[index]['subscription'];
                ele.moduleLink = this.allInsightsModulesHeaders[index]['moduleLink'];
              }
            })
            if (flag) {
              // if(this.pageLoadFlag){this.allProdcuts = []}
              // this.checkFiltersApplied()
              this.allProdcuts = []
              this.allProdcuts = [...this.allProdcuts, ...this.getinsightsModules];
              this.getinsightsModules = _.cloneDeep(this.allProdcuts);
              
            }
          } else {
            this.loadMoreProdcuts = false
            this.getinsightsModules = _.cloneDeep(this.allProdcuts);
          }
        },
        error: (err) => {
          this.spinnerService.hide()
          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.errMsg = res
          })
          this.showErrorMessageWithPopup = true;
          setTimeout(() => { this.showErrorMessageWithPopup = false; this.errMsg = ''; }, 5000)
        }
      }
    )
  }
  onCloseHandler() {
    if (this.errMsg.includes('Session Expired')) {
      localStorage.clear();
      this.auth.logout();
    }
    this.errMsg = null;
  }
  onCloseHandlerSub() {
    if (this.errMsg.includes('Session Expired')) {
      localStorage.clear();
      this.auth.logout();
    }else{
      this.errMsg = null;
      this.errMsg = null;
      this.showErrorMessageWithPopup = false;
    }
  }

  loadMoreItems() {
    const totalItems = this.analyticsProducts.length;
    if (this.loadedItemsCount < totalItems) {
      this.loadedItemsCount += 8;
    }
  }
  showOptions() {
    this.isDropdownOpen = !this.isDropdownOpen
  }
  isSelected(optionId: any): boolean {
    return this.selectedItems.includes(optionId);
  }
  toggleSelection(optionId: any) {
    if (!this.isSelected(optionId)) {
      this.selectedItems = [optionId];
    } else {
      this.selectedItems = [];
    }
    this.selectionChange.emit(this.selectedItems);
    this.isDropdownOpen = false;
    this.loadedItemsCount = 8;
  }
  getSelectedNames(): string[] {
    return this.selectedItems.map((id: number) => {
      const selectedOption = this.typeOptions.find((option: any) => option.id === id);
      return selectedOption ? selectedOption.name : '';
    });
  }
  clearSelection() {
    this.selectedItems = [];
    this.selectionChange.emit(this.selectedItems);
    this.isDropdownOpen = !this.isDropdownOpen;
    this.loadedItemsCount = 8;
  }
  searchTerm = signal('')
  get currentSet() {
    return this.analyticsProducts;
  }
  get itemCount() {
    return this.currentSet.length;
  }
  get filteredItems() {
    const selectedOption = this.typeOptions.find((option: any) =>
      this.selectedItems.includes(option.id)
    );

    const filtered = this.analyticsProducts.filter((product:any) => {
      const matchesSearchTerm = product.name
        .toLowerCase()
        .includes(this.searchTerm().toLowerCase());

      const matchesDropdown =
        !selectedOption ||
        selectedOption.id === 0 ||
        selectedOption.isDashboard === product.isDashboard;

      return matchesSearchTerm && matchesDropdown;
    });

    return filtered.slice(0, this.loadedItemsCount);
  }
  isSortingOpen = false
  showSorting() {
    this.isSortingOpen = !this.isSortingOpen
  }
  sortByNameAZ() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a:any, b:any) => a.name.localeCompare(b.name));
  }
  sortByNameZA() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((b:any, a:any) => b.name.localeCompare(a.name));
  }
  sortByRecentlyUpdated() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a:any, b:any) => {
      const dateA = new Date(a.lastUpdated.split("-").reverse().join("-")).getTime();
      const dateB = new Date(b.lastUpdated.split("-").reverse().join("-")).getTime();
      return dateB - dateA;
    });
  }
  sortByRecentlyAdded() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a:any, b:any) => {
      const dateA = new Date(a.createdOn.split("-").reverse().join("-")).getTime();
      const dateB = new Date(b.createdOn.split("-").reverse().join("-")).getTime();
      this.isSortingOpen = !this.isSortingOpen;
      return dateB - dateA;
    });
  }
  sortByFav() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort()
  }
  onSignIn() {
    this.utilService.onSignIn();
  }


  onInsightsApicall(id: any, moduleLink: any) {
    this.spinnerService.hide();
    window.open(`${moduleLink}`, '_blank');
  }
  onSubscribe() { 
    this.errMsg = this.subscriptionMsg;
      this.closeSign = 'OK';
      this.showErrorMessageWithPopup = true;
  }
  viewMore(moduleId: any) {
    this.isViewMoreVisible = true

  }
  onViewMoreClose() {
    this.isViewMoreVisible = false
  }
  openedCard: any = false;

  openPopup(product: any): void {
    this.openedCard = product;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.showTags = false;
      this.showCategory = false;
    }
  }
  closeProductPopup() {
    this.openedCard = null;
  }
  resetCategoryType() {
    this.allCategories.forEach((item:any)=>{
      item['selected']=false;
    })
    this.typeList.clear();
    this.selectedFilterType=[]
    this.categoryList.clear();
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
   this.utilService.goToTop();
  }
  resetTab(){
    this.allTags.forEach((item:any)=>{
      item['selected']=false;
    })
    this.tags.clear();
    this.utilService.goToTop();
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
  }
  clearSearch() {
    this.filterForm.get('search').setValue('')
    this.searchTextFlag=false;
  //  this.setCurrentPage()
  // this.resetPageLimit()
   this.loadMoreProdcuts=true
   this.checkFiltersApplied()
   this.getAllAnalyticsModules()
   this.utilService.goToTop();
  }
  ngAfterViewInit(){
   setTimeout(()=>{ this.pageLoadFlag=false;this.utilService.goToTop();},1000)
  }
  // setPageLimit(){
  //   this.pageLimit=this.pageLimit+10;
  // }
  // resetPageLimit(){
  //   this.pageLimit=10;
  // }
  // onScroll() {
  //   console.log(this.loadMoreProdcuts)
  //   if(!this.pageLoadFlag && this.loadMoreProdcuts){
  //     // this.currentPage++
  //     // this.setPageLimit()
  //     this.getAllAnalyticsModules(true);
  //     }
  // }
}
