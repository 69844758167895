import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AuthenticateService } from './shared/services/authenticate.service';
import { LoginService } from './shared/login-services/login.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HeaderComponent } from './core/components/header/header.component';
import { HomepageComponent } from './core/components/homepage/homepage.component';
import { AuthInterceptorService } from './shared/auth-interceptor.service';
import { UtilService } from './shared/helper/util.service';
import { AuthenticationService } from './shared/authentication.service';
import { DataRequestManagerComponent } from './core/components/data-request-manager/data-request-manager.component';
import { CapabilitiesComponent } from './core/components/capabilities/capabilities.component';
import { CapabilitiesService } from './core/components/capabilities/capabilities.service';
import { DataRequestManagerService } from './core/components/data-request-manager/data-request-manager.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HomePageService } from './shared/services/homepage.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { FormatFileSizePipe } from './shared/services/FormatFileSizePipe.pipe';
import { AlertComponent } from './shared/alert/alert.component';
import { ToastComponent } from './shared/toast/toast.component';
import { InsightsComponent } from './core/components/insights/insights.component';
import { ApplicationComponent } from './core/components/application/application.component';
import { DummyDataRequestService } from './core/components/data-request-manager/json-request.service';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { SuccessToastComponent } from './shared/success-toast/success-toast.component';
import { ErrorToastComponent } from './shared/error-toast/error-toast.component';
import { SubscriptionComponent } from './core/components/subscription/subscription.component';
import { ManageSubscriptionService } from './core/components/subscription/manage-subscription.service';
import { FooterComponent } from './core/components/footer/footer.component';
import { Base64Pipe } from './shared/convert-base64-img.pipe';
import { EditSubscriptionComponent } from './core/components/subscription/edit-subscription/edit-subs.component';
import { CreateSubscriptionService } from './core/components/subscription/edit-subscription/create-subscription.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConfirmEmailComponent } from './core/components/confirm-email/confirm-email.component';
import { CreatePasswordComponent } from './core/components/create-password/create-password.component';

import { ManageUsersComponent } from './core/components/manage-users/manage-users/manage-users.component';
//import { environment } from 'src/environments/environment.prod';

 export const authConfig = environment.type.includes('qa')
  ? environment.indusAuth
  : environment.type.includes('uat')
  ? environment.uatAuth
  : environment.auth;
//export const authConfig =  environment.auth;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    DataRequestManagerComponent,
    CapabilitiesComponent,
    FormatFileSizePipe,
    AlertComponent,
    ToastComponent,
    InsightsComponent,
    ApplicationComponent,
    SuccessToastComponent,
    ErrorToastComponent,
    SubscriptionComponent,
    EditSubscriptionComponent,
    FooterComponent,
    Base64Pipe,
    ConfirmEmailComponent,
    CreatePasswordComponent,
    ManageUsersComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    AuthModule.forRoot(authConfig),
    AutocompleteLibModule,
    MatAutocompleteModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AuthenticateService,
    DummyDataRequestService,
    LoginService,
    UtilService,
    AuthenticationService,
    HomePageService,
    CapabilitiesService,
    DataRequestManagerService,
    ManageSubscriptionService,
    CreateSubscriptionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
