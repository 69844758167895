export const environment = {
  production: false,
  type: window.location.origin,
  //apiUrl: 'https://dmintplatformapi-dev.azurewebsites.net/dmint-platform/api',
   //apiUrl2:
   // 'https://usersubonboard-api-dev.azurewebsites.net/user-subs-management/api',
  //apiUrl: 'https://dmintplatformapi-qa.azurewebsites.net/dmint-platform/api',
  apiUrl: `${document.location.protocol}//${document.location.host}/dmint-platform/api`,
  apiUrl2: `${document.location.protocol}//${document.location.host}/user-subs-management/api`,
  auth: {
    domain: 'dev-c2oymhxblfvzum2l.eu.auth0.com',
    clientId: 'l27SujZiGUFDHZnsgDU8Wd6yrewt6Bg4',
    clientSecret:
      'HKXHrJjaDSTlyA7KYrTMcKhy7NDrbu7-Az4N36zT-mq9l70mHapCijzrZ36QJ0vA',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dmint-platform-dev.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  indusAuth: {
    domain: 'dacoval-qa.eu.auth0.com',
    clientId: 'S8fsmDPPjdOC7D5ygumLX0e3AJbdTHap',
    clientSecret:
      'eC4xP75Eg-REbrWZFJ7qJ0nfriDxfy8fHRpyS9vDNmijSJsdtWUsuD_kQ-LqtVse',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dmint-platform-qa.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
  uatAuth: {
    domain: 'dacoval-dev.eu.auth0.com',
    clientId: 'MMAkoYnKCzdiA6YJfxXOgoisU0ipMQP2',
    clientSecret:
      'oVUzL7wqrpFZ_rf87fiql5djq5au9aXQN6DrODKErxLTrSQfZVwyKBQt67BSJOoD',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dmint-platform-uat.azurewebsites.net',
      cookieDomain: '.azurewebsites.net',
      useCookiesForTransactions: true,
      SameSite: 'None',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
