import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DummyDataRequestService {
  constructor(private http: HttpClient) {}

  public getDummyDataRequestTilesRecords() {
    return this.http.get(
      '../assets/dummy-records/data-request/data-request-tiles.json'
    );
  }

  public getDummyDataTableRecords() {
    return this.http.get(
      '../assets/dummy-records/data-request/table-data.json'
    );
  }
}
