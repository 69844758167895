import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiUrlService } from 'src/app/shared/services/url.service';

@Injectable({
  providedIn: 'root',
})
export class ManageSubscriptionService {
  itemsPerPage = 10;
  p: number = 1;  
  activeUserGroup=new BehaviorSubject<any>([])
  activeUserModule=new BehaviorSubject<any>([])
  usersArray=new BehaviorSubject<any>([])
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  apiUrl = this.apiUrlService.getApiUrl();

  getCustomersByUser(id: string) {
    return this.http.post(`${this.apiUrl}/v1/getCustomersByUser`, { id });
  }

  getAllStatus(statusTypeId: number) {
    return this.http.post(`${this.apiUrl}/v1/getAllStatuses`, { statusTypeId });
  }

  fetchAllSubscriptionRecord(userId: string) {
    return this.http.post(
      `${this.apiUrl}/v1/subscriptions?limit=${this.itemsPerPage}&page=${this.p}`,
      { userId }
    );
  }

  deactivateSubscription(
    userId: string,
    subscriptionId: number,
    status: string
  ) {
    return this.http.post(`${this.apiUrl}/v1/setActivationStatusSubs`, {
      userId,
      subscriptionId,
      status,
    });
  }

  getFilterRecords(filterValues: any) {
    return this.http.post(
      `${this.apiUrl}/v1/subscriptionDetailsFilter?limit=${this.itemsPerPage}&page=${this.p}`,
      filterValues
    );
  }

  deleteSubscription(deleteParameters: any) {
    return this.http.post(
      `${this.apiUrl}/v1/deleteSubscription`,
      deleteParameters
    );
  }

  getUsersName() {
    return this.http.get(`${this.apiUrl}/v1/usersName`);
  }
}
