<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<!-- main app container -->

<div class="card m-3">
  <div class="pageName">
    <h3 class="card-header">
      <img
        src="../../../../assets/images/data_request_manager_icon.svg"
        alt="icon"
        width="18px"
        height="18px"
      />Data Manager
    </h3>
    <div *ngIf="!isNotificationTextVisible">
      <button
        class="primary_green_btn apply_btn"
        (click)="onCreateNewRequest()"
        [disabled]="noSubscriptionsFlag"
      >
        Create New Request
      </button>
    </div>
  </div>
  <p class="text-message" *ngIf="(id == '' || id == 'undefined' ) && isNotificationTextVisible">
    This is an example of how different Data Requests generated by a user can be
    viewed and managed. Please Login to see and access full functionality.
  </p>
  <p class="text-message" *ngIf="id != '' && id != 'undefined' && noSubscriptionsFlag">
    The user does not have access to any Capabilities.Please Subscribe to any Capability to start using Data Manager. 
  </p>

  <form [formGroup]="dataRequestForm">
    <div class="filterANDKPI">
      <div class="filters_div">
        <div class="filters_icon_div">
          <img
            src="../../../../assets/images/Filters.svg"
            width="22px"
            height="22px"
            icon="filters"
            alt="filter_icon"
          />
          Filters
        </div>

        <div class="type_div dropdown_div">
          <div class="title">Request Type</div>
          <div class="dropdown">
            <ng-multiselect-dropdown
              [placeholder]="'Select Type'"
              [settings]="typeFilterDropdownSettings"
              [(ngModel)]="selectedFilterType"
              formArrayName="type"
              [ngModelOptions]="{ standalone: true }"
              [data]="modulesResult"
              (onSelect)="onTypeSelect($event)"
              (onDeSelect)="onTypeDeselect($event)"
              (onSelectAll)="onSelectAllTypes($event)"
              (onDeSelectAll)="onDeSelectAllTypes()"
              [disabled]="noSubscriptionsFlag"
            >
              {{ modulesResult }}
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="selectTypeDate" *ngIf="!noSubscriptionsFlag">
          <ng-container
            *ngFor="let dateFilterOption of dateFilterOptions; let i = index"
          >
            <button
              type="button"
              formControlName="periodFor"
              [class.active]="selectedDateButtonIndex === i"
              (click)="onDateOptionSelect(i)"
            >
              {{ dateFilterOption }}
            </button>
          </ng-container>
        </div>
        <div class="selectTypeDateDisbaled" *ngIf="noSubscriptionsFlag">
          <ng-container
            *ngFor="let dateFilterOption of dateFilterOptions; let i = index"
          >
            <button
              type="button"
              formControlName="periodFor"
            >
              {{ dateFilterOption }}
            </button>
          </ng-container>
        </div>
        <div class="type_div dropdown_div">
          <div class="title">Period</div>
          <div class="dropdown">
            <ng-multiselect-dropdown
              [placeholder]="'Select Period'"
              [settings]="periodFilterDropdownSettings"
              [(ngModel)]="selectedFilterPeriod"
              formControlName="period"
              [data]="periods"
              (onSelect)="onPeriodSelect($event)"
              (onDeSelect)="onPeriodDeselect($event)"
              [disabled]="isPeriodDisabled || !id"
            >
              {{ periods }}
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="reset_div">
          <button type="reset" (click)="onReset()" *ngIf="!noSubscriptionsFlag">
            Reset Filters
            <img
              src="../../../../assets/images/reset_icon.svg"
              alt="reset-icon"
            />
          </button>
        </div>
      </div>
      <div
        class="request_status_div"
        *ngIf="!errorFlag && filterData.length > 0"
      >
        <ng-container *ngFor="let tileItems of statusTilesData; let i = index">
          <div
            class="not_started onTilesHover"
            (click)="onTileSelect(tileItems.statusId, tileItems.statusName, i)"
            [class.selected]="selectedTileIndex === i"
            onKeyPress=""
            [ngClass]="[
              tileItems.statusName === 'NOT STARTED'
                ? 'not_started'
                : '' || tileItems.statusName === 'IN PROGRESS'
                ? 'in_progress'
                : '' || tileItems.statusName === 'COMPLETED'
                ? 'completed'
                : '' || tileItems.statusName === 'FAILED'
                ? 'failed'
                : '' || tileItems.statusName === 'DRAFT'
                ? 'completed draft'
                : '' || tileItems.statusName === 'RE-RUN'
                ? 're_run'
                : ''
            ]"
          >
            <div class="status">
              <img
                [src]="[
                  tileItems.statusName === 'FAILED'
                    ? '../../../../assets/images/failed_icon.svg'
                    : '' || tileItems.statusName === 'IN PROGRESS'
                    ? '../../../../assets/images/progress.svg'
                    : '' || tileItems.statusName === 'NOT STARTED'
                    ? '../../../../assets/images/not_started.svg'
                    : '' || tileItems.statusName === 'COMPLETED'
                    ? '../../../../assets/images/completed.svg'
                    : '' || tileItems.statusName === 'DRAFT'
                    ? '../../../../assets/images/drats.svg'
                    : '' || tileItems.statusName === 'RE-RUN'
                    ? '../../../../assets/images/Path.svg'
                    : ''
                ]"
                alt="icon"
                width="24px"
                height="24px"
              />{{ tileItems.statusName | titlecase }}
            </div>
            <div class="count">{{ tileItems.statusCount }}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="tableItemsCounter">
      <div class="list_view" *ngIf="showCounting && !errorFlag">
        Showing {{ (p - 1) * itemsPerPage + 1 }} -
        {{ p * itemsPerPage < totalItems ? p * itemsPerPage : totalItems }} of
        {{ totalItems }}
      </div>
      <div class="list_view" *ngIf="errorFlag">Showing 0 of 0 Result</div>
      <div class="list_view" *ngIf="!showCounting">
        Showing 1 of {{ filterData.length }} Result
      </div>
      <ng-container *ngIf="noSubscriptionsFlag">
        <div class="srchinTable">
          <img
            src="../../../../assets/images/search_icon.svg"
            alt="search-icon"
          />
          <input
            type="text"
            placeholder="Search by ID, Type and Label"
            name="search"
            class="filters_search_input disableTxt"
            disabled
            
          />
        </div>
      </ng-container>
      <ng-container *ngIf="!noSubscriptionsFlag">
        <div class="srchinTable">
          <img
            src="../../../../assets/images/search_icon.svg"
            alt="search-icon"
          />
          <input
            type="text"
            placeholder="Search by ID, Type and Label"
            name="search"
            formControlName="searchText"
            (change)="getSearchText($event)"
            [(ngModel)]="searchValue"
            [readOnly]="false"
            class="filters_search_input"
            [disabled]="noSubscriptionsFlag"
            [ngClass]="bgColorStatus ? 'bgColorSet' : ''"
          />
        </div>
      </ng-container>
      
    </div>
  </form>

  <ng-container>
    <div class="requestTable">
      <div class="tableHead">
        <div class="th">
          <div class="td">ID</div>
          <div class="td">Type</div>
          <div class="td">Label</div>
          <div class="td">
            Created on
            <span 
              *ngIf="this.id !== undefined && this.id !== '' && !noSubscriptionsFlag"
              class="down-arrow-icon"
              (click)="onSort('request_created_date')"
              onKeyPress=""
              [class.rotate]="isAscendingCreated === false"
              ><img
                src="../../../../assets/images//chevron-down.svg"
                alt="chevron_icon"
            /></span>
            <span *ngIf="noSubscriptionsFlag"
              class="down-arrow-icon"
              [class.rotate]="isAscendingCreated === false"
              ><img
                src="../../../../assets/images//chevron-down.svg"
                alt="chevron_icon"
            /></span>
          </div>
          <div class="td">
            Modified on
            <span
              *ngIf="this.id !== undefined && this.id !== '' && !noSubscriptionsFlag"
              class="down-arrow-icon"
              (click)="onSort('request_completed_date')"
              onKeyPress=""
              [class.rotate]="isAscendingCompleted === false"
              ><img
                src="../../../../assets/images//chevron-down.svg"
                alt="chevron_icon"
            /></span>
              <span
              *ngIf="noSubscriptionsFlag"
              class="down-arrow-icon"
              [class.rotate]="isAscendingCompleted === false"
              ><img
                src="../../../../assets/images//chevron-down.svg"
                alt="chevron_icon"
            /></span>
          </div>
          <div class="td">Status</div>
        </div>
      </div>
      <ng-container
        class="card-body pad0"
        *ngIf="!errorFlag && filterData.length > 0"
      >
        <div class="tbody">
          <div
            class="tr"
            *ngFor="
              let i = index;
              let item;
              of: filterData
                | paginate
                  : {
                      id: 'listing_pagination',
                      itemsPerPage: itemsPerPage,
                      currentPage: p,
                      totalItems: totalItems
                    }
            "
          >
            <div class="td">{{ item.requestId }}</div>
            <div class="td">{{ item.productType }}</div>
            <div class="td">
              <span class="tip"
                ><span class="dashboard_label">{{ item.labelName }}</span
                ><span class="toolTip-box">{{ item.labelName }}</span></span
              >
            </div>
            <div class="td">{{ item.requestCreatedDate }}</div>
            <div class="td">{{ item.requestCompletedDate }}</div>
            <div class="td">
              <div class="statusANDactions">
                <div
                  class="statusPill"
                  [ngClass]="[
                    item.statusName === 'COMPLETED'
                      ? 'completed_chip'
                      : '' || item.statusName === 'FAILED'
                      ? 'failed_chip'
                      : '' || item.statusName === 'IN PROGRESS'
                      ? 'processing_chip'
                      : '' || item.statusName === 'NOT STARTED'
                      ? 'notStarted_chip'
                      : '' || item.statusName === 'DRAFT'
                      ? 'draft_chip'
                      : '' || item.statusName === 'RE-RUN'
                      ? 'reRun_chip'
                      : ''
                  ]"
                >
                  {{ item.statusName | titlecase }}
                </div>
                <!-- info icon  -->
                <ng-container *ngIf="item.statusName === 'FAILED'">
                  <img
                    onKeyPress=""
                    [src]="
                      item.statusName === 'FAILED'
                        ? '../../../../assets/images/Failed_request_icon.svg'
                        : ''
                    "
                    [title]="
                      item.statusName === 'FAILED' ||
                      item.statusName === 'IN PROGRESS'
                        ? item.failureReason
                        : ''
                    "
                    alt="icon"
                    class="action_icon"
                    (click)="
                      openPopup(
                        item.statusName === 'COMPLETED',
                        item.requestId,
                        item.labelName,
                        item.outputFiles,
                        item.moduleId,
                        item.requestCompletedDate
                      )
                    "
                    data-backdrop="false"
                  />
                </ng-container>
                <!-- info icon close -->

                <div class="kebab_menu">
                  <label
                    for="kebabMenu"
                    class="kebabIcon"
                    (click)="onKebabMenuClick(i, item.productType)"
                    onKeyPress=""
                  >
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </label>
                  <input
                    type="radio"
                    id="kebabMenu"
                    class="kebabMenu"
                    name="tableActions"
                  />
                  <div class="menuList" *ngIf="i === selectedRowId">
                    <ul>
                      <li
                        onKeyPress=""
                        (click)="
                          openPopup(
                            item.statusName === 'COMPLETED',
                            item.requestId,
                            item.labelName,
                            item.outputFiles,
                            item.moduleId,
                            item.requestCompletedDate
                          )
                        "
                      >
                        <button
                          type="button"
                          [disabled]="item.statusName !== 'COMPLETED'"
                        >
                          <img
                            src="../../../../assets/images/Shape.svg"
                            alt="download-icon"
                          />
                          Download
                        </button>
                      </li>
                      <li
                        (click)="onEdit(item.productType, item.requestId)"
                        onKeyPress=""
                      >
                        <button [disabled]="item.statusName !== 'DRAFT'">
                          <img
                            src="../../../../assets/images/Edit.svg"
                            alt="edit-icon"
                          />
                          Edit
                        </button>
                      </li>
                      <li (click)="onRerun(item, item.requestId)">
                        <button [disabled]="checkCapabiltyDateStatus(item)">
                          <img
                            src="../../../../assets/images/Path.svg"
                            alt="rerun-icon"
                          />
                          Re-run
                        </button>
                      </li>
                      <li
                        (click)="onDeletePopUpCall(item.requestId)"
                        onKeyPress=""
                      >
                        <button type="button">
                          <img
                            src="../../../../assets/images/Trash.svg"
                            alt="delete-icon"
                          />
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="errorFlag">
        <div class="errorBlock">
          <img
            src="../../../../assets/images/artboard-3.png"
            alt="artboard_icon"
          />
          <p>{{ fillErrorText | titlecase }}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="filterData.length <= 0 && dataIsLoading">
        <div class="errorBlock">
          <img
            src="../../../../assets/images/artboard-3.png"
            alt="artboard_icon"
          />
          <p>Result Not Found</p>
        </div>
      </ng-container>
    </div>

    <div class="row" *ngIf="!errorFlag && filterData.length > 0">
      <pagination-controls
        id="listing_pagination"
        (pageChange)="getPage($event)"
        previousLabel="Prev"
        nextLabel="Next"
      >
      </pagination-controls>
    </div>
    <!-- </ng-container> -->

    <!-- Modal window container starts -->
    <div
      class="modal modal-backdrop download-popup"
      *ngIf="isDownloadPopupVisibale"
      tabindex="-1"
      role="dialog"
      [ngStyle]="{ display: displayStyle }"
    >
      <div class="modal-dialog" role="document">
        <div class="overlay" *ngIf="isOpenOverlayScreen">
          <div
            class="close_icon_overlay"
            (click)="closeOverlayPopup()"
            onKeyPress=""
          >
            <img
              src="../../../../assets/images/close_icon.svg"
              class="close_icon"
              width="16px"
              height="16px"
              alt="icon"
            />
          </div>
          <div class="text">
            {{ loginAlertMessage }}
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Download Files</div>
            <div (click)="closePopup()" onKeyPress="">
              <img
                src="../../../../assets/images/close_icon.svg"
                class="close_icon"
                width="16px"
                height="16px"
                alt="icon"
              />
            </div>
          </div>
          <div class="modal-body">
            <div class="table_details_div">
              <div class="table_details">
                <span class="table_details_title">Request ID:</span
                ><span class="table_details_text">{{ getRequestId }}</span>
              </div>
              <div class="table_details">
                <span class="table_details_title">Label:</span
                ><span class="table_details_text">{{ downloadFileLabel }}</span>
              </div>
            </div>
            <div class="modal_table_div">
              <table class="table table-striped modal_table">
                <tbody>
                  <tr *ngFor="let fileName of downloadFileName">
                    <td>
                      <span
                        ><img
                          src="../../../../assets/images/file_icon.svg"
                          alt="file_icon"
                          class="file_icon"
                          width="24px"
                          height="24px" /></span
                      ><span>{{ fileName.displayFileName }}</span>
                    </td>
                    <td>
                      Size: {{ fileName.fileSize | formatFileSize : true }} |
                      Last updated:
                      {{ requestCompletedDate | date : 'dd MMM yyyy' }}
                    </td>
                    <td>
                      <img
                        src="../../../../assets/images/download_action.svg"
                        alt="icon"
                        class="action_icon"
                        onKeyPress=""
                        (click)="onDownload(fileName.fileName)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal window container ends -->
  </ng-container>

  <!-- if no records in table -->
  <ng-container *ngIf="totalItems === 0 && modulesResult.length>0">
    <div class="messageContainer col-12">
      <img
        src="../../../../assets/images/new_request_icon.svg"
        alt="no-recently-opened-products"
      />
      <div class="message">
        <div class="title">You do not have any requests to manage</div>
        <div class="subtitle">Start creating your first request.</div>
      </div>
      <a class="primary_green_btn" routerLink="/home">Go to Home</a>
    </div>
  </ng-container>
</div>

<app-alert
  [message]="errMsgPopup"
  *ngIf="showErrorMessageWithPopup"
  (close)="onCloseHandler()"
></app-alert>
<!-- Create Modal window container starts -->
<div class="modal modal-backdrop create" *ngIf="isShowNewRequest">
  <div class="modal-dialog create">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Create new request</div>
        <div (click)="newRequestClose()" onKeyPress="">
          <img
            src="../../../../assets/images/close_icon.svg"
            class="close_icon"
            width="16px"
            height="16px"
            alt="icon"
          />
        </div>
      </div>
      <div class="modal-body">
        <div class="allRequests">
          <div
            *ngFor="let module of modulesResult"
            class="productTile"
            (click)="goToLink(module.moduleLink)"
            onKeyPress=""
          >
            <!-- src="../../../../assets/images/pairing_AAP.png" -->
            <img
              alt="{{ module.moduleName }}"
              [src]="
                moduleByteMap[module.moduleName] ||
                  moduleByteMap['Tyre Pairing'] | base64 : 'image/png'
              "
              class="productTile__image cstm-height"
            />
            <div class="productDetailCont">
              <div class="productName">{{ module.moduleName }}</div>
              <div class="productDets">
                {{ module.moduleLongDescription }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Create Modal window container ends -->

<!-- Delete Modal window container starts -->
<div class="modal modal-backdrop" *ngIf="isDownloadPopupVisible">
  <div class="modal-dialog">
    <div class="overlay" *ngIf="isOpenOverlayScreen">
      <div
        class="close_icon_overlay"
        (click)="closeOverlayPopup()"
        onKeyPress=""
      >
        <img
          src="../../../../assets/images/close_icon.svg"
          class="close_icon"
          width="16px"
          height="16px"
          alt="icon"
        />
      </div>
      <div class="text">
        {{ loginAlertMessage }}
      </div>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Are you sure?</div>
        <div (click)="onClosePopup()" onKeyPress="">
          <img
            src="../../../../assets/images/close_icon.svg"
            class="close_icon"
            width="16px"
            height="16px"
            alt="icon"
          />
        </div>
      </div>
      <div class="modal-body">
        <p>
          This will Delete a Request ID: {{ storeRequestId }} permanently from
          the Platform. <br /><br />
          Do you want to continue?
        </p>
      </div>
      <div class="modal-footer two_btn">
        <button
          type="button"
          class="secondary-filled-btn"
          (click)="onClosePopup()"
        >
          No
        </button>
        <button
          type="button"
          class="primary_green_btn red"
          (click)="onDeleteRequest(storeRequestId)"
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Delete Modal window container ends -->

<app-success-toast
  [message]="responseResult"
  *ngIf="responseResult"
></app-success-toast>

<app-error-toast
  [message]="errMsgText"
  *ngIf="errMsgText && flagError"
></app-error-toast>
