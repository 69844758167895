import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {
  constructor(private http: HttpClient) {}

  onHeader(userId: any) {
    const headers = new HttpHeaders({
      userId: userId.toString(),
    });
    let path = '';
    if (userId) path = 'headers';
    else path = 'allHeaders';
    return this.http.post<any>(`${environment.apiUrl}/v1/${path}`, null, {
      headers: headers,
    });
  }

  getUserByAuthId(): any {
    const headers = new HttpHeaders({
      authId: localStorage.getItem('id'),
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/user`, null, {
      headers: headers,
    });
  }
}
