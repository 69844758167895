<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-fussion"
>
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>
<app-header *ngIf="router.url.split('/')[1]!='confirm-email' && router.url.split('/')[1]!='create-password'"></app-header>
<div class="container">
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="router.url.split('/')[1]!='confirm-email' && router.url.split('/')[1]!='create-password'"></app-footer>
