import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from 'src/app/shared/services/url.service';

@Injectable({
  providedIn: 'root',
})
export class CreateSubscriptionService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  apiUrl = this.apiUrlService.getApiUrl();

  createSubscription(subscriptionForm: any) {
    return this.http.post(
      `${this.apiUrl}/v1/createSubscription`,
      subscriptionForm
    );
  }

  loadSubscriptionDetails(subscriptionId: number, userId: any) {
    return this.http.post(`${this.apiUrl}/v1/subscriptionDetails`, {
      subscriptionId,
      userId,
    });
  }

  updateSubscription(subscriptionForm: any) {
    return this.http.post(
      `${this.apiUrl}/v1/updateSubscription`,
      subscriptionForm
    );
  }

  loadUsersByCustomerId(companyId: any) {
    return this.http.post(
      `${this.apiUrl}/v1/userDetails`,
      {companyId}
    );
  }
}
